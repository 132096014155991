const styles = {
  passBG: {
    height: 200,
    backgroundColor: 'primary.dark',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  }
};

export default styles;
