import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useState, useMemo, useCallback } from 'react';
import VerticalBarGraph from '../../../../Component/VerticalBarGraph';
import GraphTitle from '../GraphTitle';

// importing custom components

import VivproDatagrid from '../../../../Component/Datagrid';
import { DATA_COLUMNS } from './constants';
import { getFileName } from './utils/getFileName';
import getTableData from './utils/getTableData';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import IconButton from '@mui/material/IconButton';
import BarChartIcon from '@mui/icons-material/BarChart';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import styles from '../../styles';

const Enrollment = ({
  data,
  title,
  onAddToReport
}: {
  data: any;
  title: string;
  onAddToReport?: () => void;
}) => {
  const [openTableView, setOpenTableView] = useState(false);
  const day = new Date();
  const date = `${day.getMonth() + 1}/${day.getDate()}/${day.getFullYear()}`;
  const filename = {
    prefix: 'race',
    module: 'ethn_enrollment',
    postfix: date,
    additional: ''
  };

  const tableData = useMemo(
    () => (data ? getTableData(data) : [{ year: '', white: 0, asian: 0, black: 0, other: 0 }]),
    [data]
  );

  const handleClickOpenTable = useCallback(() => {
    setOpenTableView(prev => !prev);
  }, []);

  return (
    <Box
      height='100%'
      width='100%'
      id='vertical-graph-2'
      sx={{ backgroundColor: 'white.main', borderRadius: 5 }}
      mt={2}
      mb={2}
      pt={2}
      pb={2}>
      <Box display={'flex'} alignItems='center' pl={5} justifyContent={'center'} width={'100%'}>
        <Box flex='1'>
          <Typography fontWeight='bold'>
            Trends of Enrollment by Race and Ethnicity (Harmonized) since 2011
          </Typography>
        </Box>
        <Box flex='1' pr={4}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'end'}>
            {onAddToReport && !openTableView && (
              <Button sx={styles.actionButton} onClick={onAddToReport}>
                + Add to Report
              </Button>
            )}

            <Typography
              color='primary'
              variant='subtitle2'
              textAlign='center'
              sx={{
                cursor: 'pointer',
                ml: 1
              }}
              onClick={handleClickOpenTable}>
              {openTableView && (
                <Tooltip title='View Graph' placement='top'>
                  <IconButton color='primary' aria-label='bar-chart' component='label'>
                    <BarChartIcon />
                  </IconButton>
                </Tooltip>
              )}

              {!openTableView && (
                <Tooltip title='View Table Data' placement='top'>
                  <IconButton color='primary' aria-label='table-data' component='label'>
                    <FormatListBulletedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box width='100%' height='100%'>
        {openTableView ? (
          <Box width='100%' height='50vh' mt='50px' pl={2} pr={2}>
            <VivproDatagrid
              rows={tableData}
              columnsMapping={DATA_COLUMNS}
              rowId='year'
              csvFileName={getFileName(filename)}
              noRowMessage={'No Rows'}
              loading={false}
            />
          </Box>
        ) : (
          <VerticalBarGraph data={data} title={''} />
        )}
      </Box>
      <Box flex='6' display='flex' mt={2}>
        <GraphTitle title={title} />
      </Box>
    </Box>
  );
};

export default React.memo(Enrollment);
