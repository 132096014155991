export const FILTERS = [
  // {
  //   id: 'agency_class',
  //   label: 'Agency Class',
  //   field: 'sponsors.agency_class',
  //   fieldType: 'Array',
  //   filterType: 'checkbox'
  // },
  {
    id: 'indications_and_usage',
    label: 'Conditions',
    field: ['conditions', 'name'],
    fieldType: 'Array',
    filterType: 'checkbox'
  },
  // {
  //   id: 'active_ingredients',
  //   label: 'Interventions',
  //   field: 'interventions.name',
  //   fieldType: 'Array',
  //   filterType: 'checkbox'
  // },
  {
    id: 'sponsor_name',
    label: 'Sponsors',
    field: ['sponsors', 'name'],
    fieldType: 'Array',
    filterType: 'checkbox'
  },
  {
    id: 'overall_status',
    label: 'Overall Status',
    field: ['overall_status'],
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'study_type',
    label: 'Study Type',
    field: ['study_type'],
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'start_date',
    label: 'Start Date',
    field: [],
    fieldType: 'String',
    filterType: 'range'
  },
  {
    id: 'completion_date',
    label: 'Completion Date',
    field: [],
    fieldType: 'String',
    filterType: 'range'
  },
  {
    id: 'primary_completion_date',
    label: 'Primary Completion Date',
    field: [],
    fieldType: 'String',
    filterType: 'range'
  },
  // {
  //   id: 'study_first_submitted_date',
  //   label: 'Study First Submitted',
  //   field: 'study_first_submitted_date',
  //   fieldType: 'String',
  //   filterType: 'range'
  // },
  {
    id: 'phase',
    label: 'Phase',
    field: ['phase'],
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'gender',
    label: 'Gender',
    field: ['gender'],
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'healthy_volunteers',
    label: 'Healthy Volunteers',
    field: ['healthy_volunteers'],
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'minimum_age',
    label: 'Min Age',
    field: ['minimum_age'],
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'maximum_age',
    label: 'Max Age',
    field: ['maximum_age'],
    fieldType: 'String',
    filterType: 'checkbox'
  }
];

export const GRAPH_ENTITY_DATA_MAPPING: { [key: string]: string } = {
  title: 'Title',
  submission: 'Submission',
  actionDetails: 'Action Details',
  nctId: 'NCT ID',
  startDate: 'Start Date',
  endDate: 'End Date',
  sampleSize: 'Sample Size',
  phase: 'Phase',
  studyTitle: 'Study Title'
};

export const ELASTIC_SEARCH_ATTRIBUTES_LABELS: { [key: string]: any } = {
  us: {
    active_ingredients: 'Active Ingredients',
    formulation: 'Formulation',
    route: 'Route',
    sponsor_name: 'Sponsor Name',
    indications_and_usage: 'Indications',
    trade_name: 'Trade Name'
  },
  eu: {
    active_ingredients: 'Active Ingredients',
    atc_classification: 'ATC Classification',
    formulation: 'Formulation',
    route: 'Route',
    sponsor_name: 'Sponsor Name',
    indications_and_usage: 'Indications',
    trade_name: 'Trade Name'
  },
  'us-devices': {
    generic_name: 'Generic Name',
    sponsor_name: 'Sponsor Name',
    trade_name: 'Trade Name',
    indications_and_usage: 'Definition/AOS'
  },
  clinicaltrials: {
    active_ingredients: 'Interventions',
    primary_endpoint: 'Primary Endpoint',
    sponsor_name: 'Sponsor Name',
    indications_and_usage: 'Conditions'
  },
  ct: {
    active_ingredients: 'Interventions',
    primary_endpoint: 'Primary Endpoint',
    sponsor_name: 'Sponsor Name',
    indications_and_usage: 'Conditions'
  },
  cdp: {
    indications_and_usage: 'Conditions',
    active_ingredients: 'Interventions',
    sponsor_name: 'Sponsor',
    overall_status: 'Overall Status',
    study_type: 'Study Type',
    phase: 'Phase',
    gender: 'Gender',
    healthy_volunteers: 'Healthy Volunteers',
    minimum_age: 'Min Age',
    maximum_age: 'Max Age'
  }
};

export const RACE_VARIATIONS = [
  'white',
  'asian',
  'black_or_african_american',
  'american_indian_or_alaska_native',
  'two_or_more_races',
  'hispanic_or_latino',
  'not_hispanic_or_latino'
];

export const COLORS = [
  ['#1f77b4', '#59aae3'],
  ['#2ca02c', '#7fdc7f'],
  ['#d62728', '#e67575'],
  ['#9467bd', '#ae8ecd'],
  ['#8c564b', '#c69d95']
];

// Not a accurate one, approx calculations
export const GRAPH_LABEL_LENGTH_LIMIT = 32;
