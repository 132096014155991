import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  SwipeableDrawer,
  Tooltip,
  Typography
} from '@mui/material';
import { CommitteeMemberBioType } from '../constant';
import { Fragment, useState } from 'react';
import { Cancel } from '@mui/icons-material';

// style
// import './styles.ts';
import { isEmpty } from 'lodash';

import styles from './peopleList.styles';

interface PeopleListProps {
  pageTitle: string;
  members: CommitteeMemberBioType[];
  gridSizeList: number[];
  isCompact?: false;
}

const PeopleList = ({ pageTitle, members, gridSizeList, isCompact = false }: PeopleListProps) => {
  const [bioInfo, setBioInfo] = useState<CommitteeMemberBioType>({
    open: false,
    name: '',
    bio: '',
    img: '',
    bioImg: '',
    title: '',
    bioName: '',
    bioQualifications: '',
    company: ''
  });

  const toggleDrawer =
    (data: CommitteeMemberBioType, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      const newData = { ...data };
      newData.title = data.title.replace('<br>', ' ');
      setBioInfo({ ...newData, open: open });
    };
  return (
    <Fragment key={'bioDrawer'}>
      <Container maxWidth='desktop'>
        <Stack alignItems='center' sx={styles.container}>
          <Typography sx={styles.heading}>{pageTitle}</Typography>
          <Box
            maxWidth='desktop'
            sx={{
              mt: '40px'
            }}>
            <Grid container rowSpacing={3} justifyContent='center'>
              {members?.map((member, index) => (
                <Grid
                  item
                  alignItems={'center'}
                  mobile={12}
                  tablet={6}
                  laptop={gridSizeList[index] ?? 4}
                  desktop={gridSizeList[index] ?? 4}
                  key={member.name}
                  mt='16px'>
                  <Box onClick={toggleDrawer(member, true)} className={'grow'} sx={styles.boxContainer}>
                    <Stack justifyContent={'center'} alignItems={'center'}>
                      <img
                        src={member.img}
                        alt={member.name}
                        style={{ width: '90%', height: '90%', objectFit: 'cover', borderRadius: '50%' }}
                        loading='lazy'
                      />
                      <Box justifyContent='center' alignItems='center' mt='10px'>
                        <Typography align='center' sx={styles.name}>
                          {member.name}
                        </Typography>
                        <Tooltip title={<Box dangerouslySetInnerHTML={{ __html: member.title }} />}>
                          <Typography
                            align='center'
                            sx={styles.title}
                            dangerouslySetInnerHTML={{ __html: member.title }}></Typography>
                        </Tooltip>
                        <Typography align='center' sx={styles.companyName}>
                          {member.company}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </Container>
      <SwipeableDrawer
        anchor='right'
        open={bioInfo?.open ?? false}
        onOpen={toggleDrawer(bioInfo, true)}
        onClose={toggleDrawer(bioInfo, false)}
        PaperProps={{
          sx: {
            backgroundColor: 'white.main'
          }
        }}>
        <Box sx={{ width: { laptop: '40vw', desktop: '40vw' } }} role='presentation'>
          <div
            style={{
              backgroundImage: `url('${bioInfo.bioImg || bioInfo.img}')`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              width: '100%',
              height: '477px',
              position: 'relative',
              backgroundPositionY: 'top',
              backgroundRepeat: 'no-repeat'
            }}>
            {(!('ontouchstart' in window) || navigator.maxTouchPoints === 0) && (
              <IconButton
                size='large'
                onClick={toggleDrawer(bioInfo, false)}
                sx={{ position: 'absolute', right: 0, top: 0, margin: 2 }}>
                <Cancel sx={{ width: 40, height: 40 }} />
              </IconButton>
            )}
          </div>
          <Stack alignItems='start' paddingX={5}>
            <Typography align='left' sx={styles.bioName}>
              {bioInfo.bioName}
            </Typography>
            {!isEmpty(bioInfo.bioQualifications) && (
              <Typography align='left' sx={styles.bioQualifications}>
                {bioInfo.bioQualifications}
              </Typography>
            )}
            <Typography
              align='left'
              sx={styles.bioTitle}
              dangerouslySetInnerHTML={{ __html: bioInfo.title }}></Typography>
            <Typography align='left' sx={styles.bioCompany}>
              {bioInfo.company}
            </Typography>
            {bioInfo.bio && <Box sx={styles.divider} />}
            {bioInfo.bio && (
              <Typography
                align='left'
                sx={styles.bioInfo}
                dangerouslySetInnerHTML={{ __html: bioInfo.bio }}></Typography>
            )}
          </Stack>
        </Box>
      </SwipeableDrawer>
    </Fragment>
  );
};
export default PeopleList;
