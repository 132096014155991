import { Box, Button, IconButton, Snackbar, Stack, Typography } from '@mui/material';
import Varsity from './Sections/Varsity';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Value from './Sections/Value';
import Certifications from './Sections/Certifications';
import Testimonials from './Sections/Testimonials';
import Resources from './Sections/Resources';
import Contact from './Sections/Contact';
import Blockquote, { Joga } from './Sections/Blockquote';
import { useLocation, useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';

const Home = () => {
  const location = useLocation();
  const valueRef = useRef(null);
  const certificationRef = useRef(null);
  const testimonialsRef = useRef(null);
  const resourcesRef = useRef(null);
  const contactRef = useRef(null);
  const [alertMessage, setAlertMessage] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    localStorage.setItem('varsityRecon2024CookieMessage', 'false');
    setAlertMessage(false);
  };

  useEffect(() => {
    const localAlertMessage = localStorage.getItem('varsityRecon2024CookieMessage');
    if (localAlertMessage === 'false') {
      setAlertMessage(false);
    } else {
      setAlertMessage(true);
      localStorage.setItem('varsityRecon2024CookieMessage', 'true');
    }
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        window.scrollTo(0, 0);
        break;
      case '/value':
        if (valueRef && valueRef.current) {
          // @ts-ignore
          valueRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '/certification':
        if (certificationRef && certificationRef.current) {
          // @ts-ignore
          certificationRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '/testimonial':
        if (testimonialsRef && testimonialsRef.current) {
          // @ts-ignore
          testimonialsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '/resources':
        if (resourcesRef && resourcesRef.current) {
          // @ts-ignore
          resourcesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '/contact':
        if (contactRef && contactRef.current) {
          // @ts-ignore
          contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      default:
        window.scrollTo(0, 0);
    }
  }, [location.pathname]);
  return (
    <Stack display='flex' flexDirection='column'>
      <Varsity />
      <Joga />
      <Box ref={valueRef} />
      <Value />
      <Box ref={certificationRef} />
      <Certifications />
      <Blockquote />
      <Box ref={testimonialsRef} />
      <Testimonials />
      <Box ref={resourcesRef} />
      <Resources />
      <Box ref={contactRef} />
      <Contact />
      <Snackbar
        open={alertMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleClose}
        sx={{
          left: 'auto',
          width: 700,
          '&.MuiSnackbar-root': {
            color: '#fff '
          },
          '& .MuiSnackbarContent-action': {
            position: 'absolute !important',
            top: `10px !important`,
            right: 10
          }
        }}
        message={
          <Box style={{ textAlign: 'left' }} p={3}>
            <Typography variant='h5' sx={{ color: 'gold.head' }}>
              <b>Introducing RE-CON 2024</b>
            </Typography>
            <Typography variant='body1' mt={2}>
              Register for annual conference and hands-on workshop on Jan 22-23, 2024 at Baltimore,
              MD
            </Typography>
            <Button
              variant='text'
              sx={{
                color: 'white !important',
                textTransform: 'none',
                border: '1px solid white',
                borderRadius: '5px',
                mt: 2,
                '&:hover': {
                  color: '#289D78 !important',
                  border: '1px solid #289D78'
                }
              }}
              onClick={() => {
                navigate('/recon');
                handleClose();
              }}>
              View Details
            </Button>
          </Box>
        }
        action={
          <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
            <Close fontSize='small' />
          </IconButton>
        }
      />
    </Stack>
  );
};
export default Home;
