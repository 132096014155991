const numberMixin: { [key: string]: any } = {
  paddingTop: 0.5,
  paddingBottom: 0.5,
  paddingLeft: 2,
  paddingRight: 2,
  borderRadius: 2
};

const textMixin: { [key: string]: any } = {
  fontWeight: 'bold',
  textTransform: 'uppercase'
};

const styles: any = {
  datagrid: {
    border: 'none',
    borderRadius: '10px',

    '& .table-header': {
      width: '100%',
      color: 'primary.main',
      fontWeight: 'bold',
      bgcolor: 'primary.backgroundDark'
      // borderRadius: '10px',
    },
    '& .MuiDataGrid-columnHeaderTitle	': {
      fontWeight: 'bold'
    },
    '& 	.MuiDataGrid-row	': {
      bgcolor: 'red'
    },
    '& .MuiDataGrid-cell:hover': {
      color: 'black.main'
    }
  },
  redNumber: {
    ...numberMixin,
    bgcolor: 'red.main',
    color: 'white.main'
  },
  purpleNumber: {
    ...numberMixin,
    bgcolor: 'blue.main',
    color: 'white.main'
  },
  yellowNumber: {
    ...numberMixin,
    bgcolor: 'orange.main',
    color: 'white.main'
  },
  greenNumber: {
    ...numberMixin,
    bgcolor: 'green.accept',
    color: 'white.main'
  },
  grayNumber: {
    ...numberMixin,
    bgcolor: 'gray.main',
    color: 'white.main'
  },
  redText: {
    color: 'red.main',
    ...textMixin
  },
  yellowText: {
    color: 'orange.main',
    ...textMixin
  },
  purpleText: {
    color: 'blue.main',
    ...textMixin
  },
  greenText: {
    color: 'green.accept',
    ...textMixin
  },
  grayText: {
    color: 'gray.main',
    ...textMixin
  }
};

export default styles;
