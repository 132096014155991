export const VarsityStyles = {
  companyLogo: {
    width: 125,
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #80808021',
    background: '#8080800a'
  }
};
