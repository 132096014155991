import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import VarsityLogo from '../../assets/svg/VarsityLogo';

const Logobar = () => {
  const navigate = useNavigate();
  return (
    <AppBar component='nav' elevation={0} color={'transparent'} position={'static'}>
      <Toolbar>
        <Box
          sx={{
           display: 'flex',
           margin:'auto',
           justifyContent: 'center' 
          }}>
          <Button
            disableElevation
            disableRipple
            onClick={() => navigate('/')}
            sx={{ width: '20%', minWidth: 200, display: 'flex', margin: 'auto' }}>
            <VarsityLogo />
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(Logobar);
