import { Stack, Typography, Box, Grid, Button } from '@mui/material';
import Container from '@mui/material/Container';

import styles from './Registration.styles';
import { registerationDetails } from '../constant';

const Ticket = ({
  title,
  subtitle,
  price,
  paymentLink
}: {
  title: string;
  subtitle?: string;
  price: string;
  paymentLink: string;
}) => {
  return (
    <Grid item mobile={12} laptop={6}>
      <Stack
        direction='column'
        sx={{
          borderColor: 'primary.dark',
          borderWidth: 1,
          borderStyle: 'solid'
        }}>
        <Stack sx={styles.passBG} spacing={1}>
          <Typography
            variant='h2'
            textAlign='center'
            color='white.main'
            sx={{ fontWeight: 700, letterSpacing: 1 }}>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant='subtitle2' textAlign='center' color='white.main'>
              {subtitle}
            </Typography>
          )}
        </Stack>
        <Stack spacing={2} padding={5}>
          <Typography variant='h3' color='white.main' sx={{ fontWeight: 700, letterSpacing: 1 }}>
            Price
          </Typography>
          <Typography variant='h1' color='white.main' sx={{ fontWeight: 700, letterSpacing: 1 }}>
            {`$${price}`}
          </Typography>
          {/* <Typography color='white.main' pt={6}>
            A confirmation will be sent to the email address used upon registration.
          </Typography> */}
          <Button variant='contained' color='primary' href={paymentLink}>
            Register Now
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );
};

const Registration = () => {
  return (
    <Box pt={5} pb={5}>
      <Container maxWidth='desktop'>
        <Typography
          variant='h1'
          textAlign='center'
          color='white.main'
          sx={{ fontWeight: 700, letterSpacing: 1 }}>
          Registration
        </Typography>
        <Grid container mt={4} spacing={6} sx={{ bgcolor: 'black.dark' }}>
          {registerationDetails.map(detail => (
            <Ticket
              key={detail.title}
              title={detail.title}
              subtitle={detail?.subtitle}
              price={detail.price}
              paymentLink={detail.paymentLink}
            />
          ))}
        </Grid>
        <Typography
          sx={{ color: 'primary.main', fontSize: 14, '& a': { color: 'orange.main' } }}
          mt={3}>
          *Please contact <a href='mailto:admin@vivpro.ai'>admin@vivpro.ai</a> for any concerns
          related to registration
        </Typography>
      </Container>
    </Box>
  );
};
export default Registration;
