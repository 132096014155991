import React, { useState } from 'react';

// material ui
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
// icons
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
// styled components
import FiltersTextField from './styledComponents/FiltersTextField';

// styles
import styles from './styles/Filters.styles';

const FilterSearchField = () => {
  // states for search by keyword
  const [showTextField, setShowTextField] = useState(false);
  const [searchTerms] = useState([]);
  const [currentText, setCurrentText] = useState();

  /**
   * function to add a list of searched keywords to the filters field
   * @param {object} e event handler
   */
  const handleSearch = async e => {
    e.preventDefault();
  };

  /**
   * funtion to remove the keyword from the search terms list
   * @param {object} e event handler
   * @param {string} val keyword to be removed
   */
  const removeFromKeywords = async () => {};

  return (
    <Stack>
      <Typography variant='subtitle2'>Search keyword</Typography>
      {searchTerms &&
        searchTerms?.map(val => (
          <Stack key={val} direction='row' sx={styles.selectedOptionsContainer}>
            <CloseIcon
              fontSize='small'
              sx={styles.closeIcon}
              onClick={e => removeFromKeywords(e, val)}
            />
            <Typography variant='subtitle2'>
              {val.length > 15 ? `${val.substring(0, 15)}...` : val}
            </Typography>
          </Stack>
        ))}
      {showTextField ? (
        <>
          <form onSubmit={handleSearch}>
            <FiltersTextField
              placeholder='Search here'
              variant='outlined'
              value={currentText}
              onChange={e => setCurrentText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      // onClick={handleSearch}
                      type='submit'
                      // onMouseDown={handleMouseDownPassword}
                      edge='end'>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </form>
          <Typography
            ml={5}
            onClick={handleSearch}
            color='primary'
            sx={styles.clearText}
            variant='subtitle2'>
            Search term
          </Typography>
        </>
      ) : null}
      {!showTextField ? (
        <Button
          id='popup'
          onClick={() => setShowTextField(true)}
          variant='container'
          sx={styles.btn}>
          +Add
        </Button>
      ) : null}
    </Stack>
  );
};

export default FilterSearchField;
