const styles = {
  containerBackground: {
    backgroundColor: 'white.main',
    display: 'flex',
    justifyContent: 'center',
  },
  AboutContainer: { display: 'flex', alignItems: 'flex-start', mt: '150px', gap: '100px',  
    flexDirection: 'row-reverse',
    '@media (max-width: 768px)': {
      flexDirection: 'column-reverse',
      mt: '30px',
      gap: '24px',
    }, 
  },
  aboutImage: {
    width: '272px',
    ml: '2px',
    height: '395px',
    borderRadius: '8px',
    objectFit: 'contain',
    '@media (max-width: 768px)': {
      width: '200px',
      height: '300px',
    },
    '@media (max-width: 480px)': {
      width: '50%',
      height: '225px',
    },
  },
  heading: {
    width: 'fit-content',
    background: 'linear-gradient(to right, #5EC0FF 0%, #33D6E5 30%, #31DCC4 50%)',
    WebkitBackgroundClip: 'text',
    fontFamily: 'Plus Jakarta Sans',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    fontSize: '24px',
    lineHeight: '28.8px',
    fontWeight: 700
  },
  title: {
    mt: '8px',
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '50px',
    color: 'gray.900',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      fontSize: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }, 
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '24px',
    color: 'gray.900',
    fontWeight: 400,
    mt: '32px'
  },
  bullets: {
    fontSize: '16px',
    lineHeight: '24px',
    color: 'gray.900',
    fontWeight: 400
  },
  registerButton: {
    borderRadius: '32px',
    fontSize: '16px',
    fontWeight: 600,
    width: '156px',
    height: '48px',
    borderColor: 'linear-gradient(to right, #52BCAD, #0D917D)',
    color: 'linear-gradient(to right, #52BCAD, #0D917D)',
    textTransform: 'none',
    mt: '30px'
  },
  aboutContainer: { display: 'flex', alignItems: 'flex-start', my: '100px', gap: '100px', flexDirection: 'row-reverse',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
      my: '75px',
      gap: '24px',
    }
  },
  ulStyle: { marginTop: '32px', paddingLeft: '15px' },
  li: { fontWeight: 700 },
  videoBox: {
    borderRadius: 10,
    width: '592.416px',
    display: 'flex', flexDirection: 'row', gap: '8px',
    '@media (max-width: 768px)': {
      width: '330px',
      height: '200px'
    }
  }

};

export default styles;
