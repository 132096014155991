import { useState } from 'react';

// mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

// styles
import styles from './EventSchdule.styles';

// constant
import {  eventScheduleImages, eventSchedulePDF } from '../constant';
import { Link } from '@mui/material';

const EventSchedule = () => {
  const [currentTab, setCurrentTab] = useState('Day 1');

  const handleTab = (day: string) => {
    setCurrentTab(day);
  };

  const handleDownload = async (pdfLink: string) => {
    try {
      const response = await fetch(pdfLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf'
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'recon-schedule.pdf';
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to fetch the PDF:', error);
    }
  };

  return (
    <Container sx={styles.containerBackground}>
      <Container maxWidth='desktop'>
        <Stack alignItems='center' sx={{ height: '100%' }}>
          <Typography align='center' sx={styles.heading}>
            Agenda
          </Typography>
          {window.innerWidth >= 768 && (
            <Stack display='flex' flexDirection='row' alignContent='center'>
              <Typography align='center' sx={styles.subtitle}>
                <Link href={eventSchedulePDF} target='_blank' sx={{ color: 'inherit' }}>
                  View PDF
                </Link>
              </Typography>
              <span style={{ marginLeft: '4px', marginRight: '4px' }}>/</span>
              <Typography
                align='center'
                sx={styles.subtitle}
                onClick={() => {
                  handleDownload(eventSchedulePDF);
                }}>
                Download
              </Typography>
            </Stack>
          )}

          <Box sx={styles.agendaContainer}>
            <Box sx={styles.dateBox}>
              <Box
                sx={currentTab === 'Day 1' ? styles.selectedDayBox : styles.unSelectedDayBox}
                onClick={() => {
                  handleTab('Day 1');
                }}>
                <span style={styles.spanStyle}>
                  <b>Day 1 </b>(27 January)
                </span>
              </Box>
              <Box
                sx={currentTab === 'Day 2' ? styles.selectedDayBox : styles.unSelectedDayBox}
                onClick={() => {
                  handleTab('Day 2');
                }}>
                <span style={styles.spanStyle}>
                  <b>Day 2 </b>(28 January)
                </span>
              </Box>
            </Box>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(eventSchedulePDF, '_blank');
              }}>
              <img
                src={eventScheduleImages[currentTab]}
                alt={currentTab}
                loading='lazy'
                width='100%'
              />
            </Box>
          </Box>
        </Stack>
      </Container>
    </Container>
  );
};
export default EventSchedule;
