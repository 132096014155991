import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const FilterText: React.FC<{
  selectedFilters: { [key: string]: Array<string> };
}> = ({
  selectedFilters,
}: {
  selectedFilters: { [key: string]: Array<string> };
}) => {
  return (
    <Box pl={5} pr={5} display={"flex"} alignItems="center" textAlign="center">
      <Typography display="inline-block" variant="subtitle2" fontWeight="bold">
        Filters:
      </Typography>
      <Box display="flex" flexWrap="wrap">
        {Object.keys(selectedFilters).map((key: string) => (
          <Box
            key={key}
            display="inline-block"
            m={0.5}
            borderRadius="10px"
            p={1}
            sx={{ backgroundColor: "#dfdfdf", color: "primary.main" }}
          >
            <Box display="inline-block">
              <Typography fontWeight="600" display="inline-block" fontSize={{tablet: 14, desktop: 16}}>
                {key}:
              </Typography>
              <Typography variant="body1" display="inline-block" ml={1} fontSize={{tablet: 14, desktop: 16}}>
                {selectedFilters[key].join(", ")}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FilterText;