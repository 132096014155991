import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// mui
import Stack from '@mui/material/Stack';

// components
import Banner from './Banner';
import VideoContent from './VideoPage';
import NumericDetails from './NumericDetails';
import EventSchedule from './EventSchedule';
import VenueDetails from './VenueDetails';
import Committee from './Committee';
import FAQs from './FAQs';
import ReconForm from './Form';
import Speakers from './Speakers';

const Recon2023 = () => {
  const location = useLocation();

  const scheduleRef = useRef(null);
  const committeeRef = useRef(null);
  const venueRef = useRef(null);
  const faqRef = useRef(null);
  const reconFormRef = useRef(null);

  useEffect(() => {
    switch (location.pathname) {
      case '/recon2023':
        window.scrollTo(0, 0);
        break;
      case '/recon2023/agenda':
        if (scheduleRef && scheduleRef.current) {
          // @ts-ignore
          scheduleRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '/recon2023/committee':
        if (committeeRef && committeeRef.current) {
          // @ts-ignore
          committeeRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '/recon2023/venue':
        if (venueRef && venueRef.current) {
          // @ts-ignore
          venueRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '/recon2023/faq':
        if (faqRef && faqRef.current) {
          // @ts-ignore
          faqRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      case '/recon2023/book':
        if (reconFormRef && reconFormRef.current) {
          // @ts-ignore
          reconFormRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        break;
      default:
        window.scrollTo(0, 0);
    }
  }, [location.pathname]);
  return (
    <Stack>
      <Banner />
      <VideoContent />
      <NumericDetails />
      <Stack ref={scheduleRef} />
      <EventSchedule />
      <Stack ref={venueRef} />
      <VenueDetails />
      <Stack ref={committeeRef} />
      <Committee />
      <Speakers />
      <Stack ref={faqRef} />
      <FAQs />
      <Stack ref={reconFormRef} />
      <ReconForm />
    </Stack>
  );
};
export default Recon2023;
