import { createSvgIcon } from '@mui/material';

const CalenderIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M15.8333 3.3335H4.16667C3.24619 3.3335 2.5 4.07969 2.5 5.00016V16.6668C2.5 17.5873 3.24619 18.3335 4.16667 18.3335H15.8333C16.7538 18.3335 17.5 17.5873 17.5 16.6668V5.00016C17.5 4.07969 16.7538 3.3335 15.8333 3.3335Z" stroke="url(#paint0_linear_1647_97)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.3333 1.6665V4.99984" stroke="url(#paint1_linear_1647_97)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.66675 1.6665V4.99984" stroke="url(#paint2_linear_1647_97)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.5 8.3335H17.5" stroke="url(#paint3_linear_1647_97)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_1647_97" x1="10" y1="3.3335" x2="10" y2="18.3335" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF7300"/>
            <stop offset="1" stop-color="#DB5800"/>
            </linearGradient>
            <linearGradient id="paint1_linear_1647_97" x1="13.8333" y1="1.6665" x2="13.8333" y2="4.99984" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF7300"/>
            <stop offset="1" stop-color="#DB5800"/>
            </linearGradient>
            <linearGradient id="paint2_linear_1647_97" x1="7.16675" y1="1.6665" x2="7.16675" y2="4.99984" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF7300"/>
            <stop offset="1" stop-color="#DB5800"/>
            </linearGradient>
            <linearGradient id="paint3_linear_1647_97" x1="10" y1="8.3335" x2="10" y2="9.3335" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FF7300"/>
            <stop offset="1" stop-color="#DB5800"/>
            </linearGradient>
        </defs>
        </svg>,
  'CalenderIcon'
);

export default CalenderIcon;
