import * as React from 'react';
import { useState } from 'react';
import { Alert, Button, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { BASE_URL } from '../../../api/ct-data';

const Contact = ({ fullWidth = false}) => {
  const [message, setMessage] = useState('');
  const [messageOpen, setMessageOpen] = useState(false);
  const initialValues = {
    name: '',
    email: '',
    message: '',
    company: ''
  };
  const handleCloseSnackbar = () => {
    setMessageOpen(false);
    setMessage('');
  };
  return (
    <Stack>
      <Typography variant='h2' color='black.main'>
        Contact us
      </Typography>

      <Formik
        initialValues={{ ...initialValues }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Name is required'),
          email: Yup.string().email('Email is invalid').required('Email is required'),
          message: Yup.string(),
          company: Yup.string().required('Company is required')
        })}

        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          axios.post(`${BASE_URL}/email-service/contact`, values).then((res) => {
            console.log(res);
            setMessage('Message sent successfully');
            setMessageOpen(true);
            resetForm();
            setSubmitting(false);
          }).catch((err) => {
            console.log(err);
            setMessage('Message failed to send');
            setMessageOpen(true);
            setSubmitting(false);
          });

        }}
      >
        {({ errors, touched, values, handleChange, handleBlur, isValid, dirty, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Stack display='flex' flexDirection='column' justifyContent='space-evenly'
                   sx={{ height: 500, width: { mobile: '100%', laptop: fullWidth ? 'inherit' : '45%' } }}>
              <TextField
                fullWidth
                required
                id='name'
                name='name'
                label='Your name'
                variant='standard'
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}

              />
              <TextField
                fullWidth
                required
                id='email'
                name='email'
                label='Email'
                variant='standard'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                fullWidth
                required
                id='company'
                name='company'
                label='Your company name'
                variant='standard'
                value={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.company && Boolean(errors.company)}
                helperText={touched.company && errors.company}

              />
              <TextField
                fullWidth
                id='message'
                name='message'
                label='Comment/Question'
                variant='standard'
                multiline
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.message && Boolean(errors.message)}
                helperText={touched.message && errors.message}
                maxRows={4}

              />
              <Button variant={'contained'} type='submit' disabled={!(isValid && dirty)}>Submit</Button>
            </Stack>

          </form>


        )}


      </Formik>
      <Snackbar open={messageOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}
                sx={{ width: { mobile: '100%', laptop: '30%' } }}>
        <Alert onClose={handleCloseSnackbar} severity='success' sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );

};
export default Contact;
