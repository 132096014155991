const styles = {
  background: {
    background: '#F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pt: '76px',
    pb: '76px',
    '@media (max-width: 768px)': {
      height: '1000px'
    }
  },
  heading: {
    fontSize: '36px',
    color: 'grey.900',
    lineHeight: '24px',
    fontWeight: 700,
    fontFamily: 'Plus Jakarta Sans'
  },
  title: {
    mt: '12px',
    fontSize: '40px',
    lineHeight: '50px',
    color: 'white.main',
    fontWeight: 700,
    '@media (max-width: 768px)': {
      fontSize: '30px'
    }
  },
  ticketBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    mt: '40px',
    gap: '40px',
    '@media (max-width: 768px)': {
      flexDirection: 'column'
    }
  },
  ticket: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    background: 'white.main',
    boxShadow: '5px 5px 15px -5px rgba(0, 0, 0, 0.3)'
  },
  ticketHeader: {
    fontSize: '20px',
    lineHeight: '26px',
    color: '#0076D6',
    fontWeight: 700,
    textAlign: 'center',
    mt: '44.19px',
    '@media (max-width: 768px)': {
      mt: '60px'
    }
  },
  ticketPrice: {
    fontSize: '40px',
    lineHeight: '48px',
    color: 'gray.900',
    fontWeight: 700,
    mt: '12px'
  },
  ticketEarlyBird: {
    fontSize: '12px',
    lineHeight: '18px',
    color: '#E16500',
    fontWeight: 700,
    mt: '6px'
  },
  ticketEarlyBirdText: {
    fontSize: '14px',
    lineHeight: '22.4px',
    fontWeight: 300,
    color: 'gray.900',
    fontStyle: 'italic',
    mt: '32px'
  },
  ticketEarlyPriceRegularPrice: {
    fontSize: '14px',
    lineHeight: '22.4px',
    fontWeight: 600,
    color: 'gray.900'
  },
  registerButton: {
    fontSize: '16px',
    fontWeight: 700,
    width: '176px',
    height: '40px',
    padding: '10px 32px',
    background: '#0076D6',
    textTransform: 'none',
    borderRadius: '50px',
    mt: '32px',
    '&:hover': {
      boxShadow: '0 0 20px rgba(14, 134, 211, 0.7)' // Shadow effect on hover
    },
    '@media (max-width: 768px)': {
      mt: '30px'
    }
  },
  ticketBackground: {
    height: '198px',
    width: '300px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px 12px 0px 0px',
    backgroundColor: '#F3F9FE'
  },
  semicircle: {
    position: 'absolute',
    height: '55.824px',
    width: '53.333px',
    top: '-30px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#F5F5F5',
    borderRadius: '50%'
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white.main',
    pb: '45.86px',
    borderRadius: '0px 0px 12px 12px'
  }
};

export default styles;
