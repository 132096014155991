import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { NavLink as RouterLink } from 'react-router-dom';



const MobilePopUp = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = useCallback(() => {
      setWidth(window.innerWidth);
  }, [setWidth])

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <Dialog
      open={width <= 768}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          For the Better Experience view this Page on Desktop
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button component={RouterLink} to='/'>Back To Home</Button>
      </DialogActions>
    </Dialog>
    );

};

export default React.memo(MobilePopUp);