import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// muiv5
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridCsvGetRowsToExportParams
} from '@mui/x-data-grid';

const DatagridToolbar = ({ fileName }: any) => {
  const [allowedToDownloadAllData, setAllowedToDownloadAllData] = useState(true);

  return (
    <GridToolbarContainer sx={{ justifyContent: 'flex-end', bgcolor: 'none' }}>
      {/* @ts-ignore */}
      <GridToolbarColumnsButton />
      {/* @ts-ignore */}
      <GridToolbarDensitySelector />
      {/* @ts-ignore */}
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          allColumns: true,
          fileName: fileName,
          delimiter: ',',
          utf8WithBom: true,
          getRowsToExport: (params: GridCsvGetRowsToExportParams) => {
            // eslint-disable-next-line no-console
            const rowIds = params.apiRef.current.getAllRowIds();
            if (!allowedToDownloadAllData) {
              rowIds.length = 50;
            }

            return rowIds;
          }
        }}
      />
    </GridToolbarContainer>
  );
};

export default DatagridToolbar;
