// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Box, Stack, Typography } from '@mui/material';

const MemberCarousel = ({ memberList }) => {
  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination]}
      style={{
        background: 'transparent',
        '--swiper-pagination-color': '#289D78',
        '--swiper-navigation-color': '#289D78',
        '--swiper-pagination-bullet-inactive-color': '#333333',
        '--swiper-pagination-bullet-inactive-opacity': '1',
        '--swiper-pagination-bullet-size': '10px',
        '--swiper-pagination-bullet-horizontal-gap': '6px'
      }}
      spaceBetween={10}
      slidesPerView={2}
      autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
      loop
      navigation
      pagination={{ clickable: true, dynamicBullets: true }}>
      {memberList.map(member => (
        <SwiperSlide key={member.name}>
          <Box>
            <Stack justifyContent={'center'} alignItems={'center'}>
              <img
                src={member.img}
                alt={member.name}
                style={{ width: '50%', height: '50%', objectFit: 'cover' }}
              />
              <Stack justifyContent={'center'} alignItems={'center'} spacing={1}>
                <Typography
                  variant='h4'
                  align='center'
                  sx={{ fontWeight: 'bold', color: 'white.contrastText' }}>
                  {member.name}
                </Typography>
                <Typography
                  variant='subtitle2'
                  align='center'
                  sx={{ color: 'white.contrastText' }}
                  dangerouslySetInnerHTML={{ __html: member.title }}></Typography>
                <Typography
                  variant='subtitle2'
                  align='center'
                  sx={{ color: 'white.dark', fontSize: 14 }}>
                  {member.company}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default MemberCarousel;
