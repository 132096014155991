const styles: { [key: string]: any } = {
    containerBackground: {
        background: '#F5F5F5',
        height: '376px',
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width: 768px)': {
          height: '400px',
        }
      },
    container: {
      mt:'100px',
      mb:'100px',
      '@media (max-width: 768px)': {
          mt:'80px',
          mb:'80px'
        }
    },
    heading: {
        background: 'linear-gradient(to right, #5EC0FF 0%, #33D6E5 30%, #31DCC4 50%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        fontSize: '24px',
        fontWeight: 600
    },
    title: {
      fontSize: '36px',
      fontWeight: 700,
      fontFamily: 'Plus Jakarta Sans',
      '@media (max-width: 768px)': {
        fontSize: '29px',
      }
    },
    subtitle: {
      fontSize: '18px',
      fontWeight: 400,
      fontFamily: 'Inter'
    },
    contantUsButton: {
      borderRadius: '50px',
      padding: '10px 32px',
      fontSize: '16px',
      fontWeight: 700,
      width: '200px',
      height: '48px',
      color: 'white.main',
      backgroundColor: '#0076D6',
      textTransform: 'none',
      mt: '54px',
      '&:hover': {
        backgroundColor: 'primary.main',
        boxShadow: '0 0 20px rgba(14, 134, 211, 0.7)' // Shadow effect on hover
      }
    },
    formHeading: {
      margin: '0px 0px 10px',
      textAlign: 'center',
      fontFamily: "Plus Jakarta Sans",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "150%"
    },
    divider: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'gray.400',
      borderRadius: '2px',
      mt: '10px',
      mx: 'auto',
      mb: 0,
      width: '64px'
    },
    advanceSearchCloseIcon: {
      color: 'gray.lightVariant4',
      position: 'absolute',
      right: -10,
      top: -10,
      margin: '26px 32px 0px 0px'
    },
    textField: {
      '& .MuiOutlinedInput-root': {
          borderRadius: '8px'
      }
    },
    submitButton: {
      position: "relative",
      fontWeight: '500',
      minWidth: '64px',
      borderRadius: '32px',
      display: 'flex',
      padding: '8px 48px',
      flexDirection: 'column',
      alignItems: 'center',
      margin: '32px auto 24px',
    }
  };
  
  export default styles;
  