import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Typography, Box, Tooltip } from '@mui/material';
import { committeeMembers, speakerMembers } from '../constant';
import styles from './styles';
// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const SpeakersCarousel = () => {
  const navigate = useNavigate();
  const memberList = [...committeeMembers, ...speakerMembers];
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [stylesValue, setStyles] = useState({});

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesPerView(1);
        setStyles({
          background: 'transparent',
          '--swiper-pagination-color': '#0076D6',
          '--swiper-navigation-color': '#0076D6',
          '--swiper-pagination-bullet-inactive-color': '#0076D6',
          '--swiper-pagination-bullet-horizontal-gap': '6px',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-bullet-size': '10px',
          padding: '42px 0px 30px'
        });
      } else {
        setSlidesPerView(3);
        setStyles({
          background: 'transparent',
          '--swiper-pagination-color': '#0076D6',
          '--swiper-navigation-color': '#0076D6',
          '--swiper-pagination-bullet-inactive-color': '#0076D6',
          '--swiper-pagination-bullet-horizontal-gap': '6px',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-bullet-size': '10px',
          paddingTop: '40px',
          paddingBottom: '30px',
          paddingLeft: '50px',
          paddingRight: '50px'
        });
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container sx={styles.background}>
      <Container maxWidth='desktop'>
        <Typography align='center' sx={styles.heading}>
          Planning Committee & Speakers
        </Typography>
        <Typography align='center' sx={styles.subtitle} onClick={() => navigate('/recon/committee')}>
          View all
        </Typography>
        <Box>
          <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            style={stylesValue}
            spaceBetween={50}
            slidesPerView={slidesPerView}
            autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
            loop
            navigation
            pagination={{ clickable: true, dynamicBullets: true }}>
            {memberList.map(member => (
              <SwiperSlide key={member.name}>
                <Box sx={styles.memberContainer}>
                    <img
                      src={member.img}
                      alt={member.name}
                      style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: '50%' }}
                    />
                    <Box justifyContent='center' alignItems='center' mt='20px'>
                      <Typography
                        align='center'
                        sx={styles.name}>
                        {member.name}
                      </Typography>
                      <Tooltip title={<Box dangerouslySetInnerHTML={{ __html: member.title }} />}>
                        <Typography
                          align='center'
                          sx={styles.title}
                          dangerouslySetInnerHTML={{ __html: member.title }}></Typography>
                      </Tooltip>
                      <Typography
                        align='center'
                        sx={styles.companyName}>
                        {member.company}
                      </Typography>
                    </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    </Container>
  );
};
export default SpeakersCarousel;
