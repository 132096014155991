import { useState, useEffect } from 'react';
import { Container, Stack, Typography, Box } from '@mui/material';

// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

// constant
import { quotes } from '../constant';

// styles
import styles from './quotes.styles';


const Quotes = () => {
  const [slidesPerView, setSlidesPerView] = useState(2);
  const [stylesValue, setStyles] = useState({});
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSlidesPerView(1);
        setStyles({
          background: 'transparent',
          '--swiper-pagination-color': '#0076D6',
          '--swiper-navigation-color': '#0076D6',
          '--swiper-pagination-bullet-inactive-color': '#0076D6',
          '--swiper-pagination-bullet-horizontal-gap': '6px',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-bullet-size': '10px',
          paddingTop: '23px',
          paddingBottom: '48px',
          paddingLeft:  '42px' ,
          paddingRight: '23px', 
        });
      } else {
        setSlidesPerView(2);
        setStyles({
          background: 'transparent',
          '--swiper-pagination-color': '#0076D6',
          '--swiper-navigation-color': '#0076D6',
          '--swiper-pagination-bullet-inactive-color': '#0076D6',
          '--swiper-pagination-bullet-horizontal-gap': '6px',
          '--swiper-pagination-bullet-inactive-opacity': '1',
          '--swiper-pagination-bullet-size': '10px',
          paddingTop:  '40px',
          paddingBottom:  '80px',
          paddingLeft: '50px',
          paddingRight:  '50px'
        });
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Container sx={styles.containerBackground}>
      <Stack
        display='flex'
        alignItems='center'
        width='100%'
        justifyContent='center'
        sx={styles.stackContainer}>
        <Box
          spacing='11px'
          display='flex'
          alignItems='center'
          flexDirection='column'
          sx={styles.box}>
          <Typography align='center' sx={styles.heading}>
            Testimonials
          </Typography>
          <Typography align='center' sx={styles.title}>
            What People Say?
          </Typography>
        </Box>
        <Box
          sx={{
            width: '1136px',
            '@media (max-width: 768px)': {
              width: '355px',
            }
          }}>
          <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            style={stylesValue}
            spaceBetween={50}
            slidesPerView={slidesPerView}
            autoplay={{ delay: 3000, pauseOnMouseEnter: true }}
            loop
            navigation
            pagination={{ clickable: true, dynamicBullets: true }}>
            {quotes.map(quote => (
              <SwiperSlide key={quote.quoteTitle}>
                <Box sx={styles.quotesBox}>
                  <Box>
                    <Typography sx={styles.subtitle}>{quote.quoteText}</Typography>
                  </Box>
                  <Box>
                    <Typography sx={styles.nameStyles}>{quote.quoteTitle}</Typography>
                    <Typography sx={styles.positionStyles}>{quote.position}</Typography>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Stack>
    </Container>
  );
};
export default Quotes;
