import * as React from 'react';
import { useCallback, useState } from 'react';
import { Box, Button, CardMedia, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { TestimonialsStyles } from './styles/TestimonialsStyles';
import { testimonialPage } from '../constants';

const Testimonials = () => {
  const theme = useTheme();

  const [activeStep, setActiveStep] = useState(0);
  //@ts-ignore
  const tabletMatch = useMediaQuery(theme.breakpoints.up('tablet'));
  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleStepChange = useCallback((step: number) => {
    setActiveStep(step);
  }, []);
  return (
    <Stack display='flex' justifyContent='space-between' mt={10}
           sx={{ flexDirection: { mobile: 'column', laptop: 'row' }, mb: 10 }}>
      <Typography variant='subtitle1' color='primary.main'
                  sx={{ display: { mobile: 'block', laptop: 'none' }, textTransform: 'uppercase', mb: 5 }}>
        Testimonials
      </Typography>
      <Stack display='flex'
             sx={{ width: { mobile: '100%', laptop: '45%' }, height: '100%', maxHeight: 500, overflow: 'hidden' }}>
        <SwipeableViews
          axis={'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents={false}
          style={{ width: '100%', height: '100%' }}
        >
          {testimonialPage.map((step, index) => (<div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
                <CardMedia
                  component='img'
                  height={tabletMatch?500:300}
                  width={'100%'}
                  image={step.imgPath}
                  alt={step.title}
                />

            ) : null}
          </div>))}
        </SwipeableViews>
      </Stack>
      <Stack display='flex' sx={{ width: { mobile: '100%', laptop: '45%' } }} justifyContent={'space-between'}
             flexDirection={'column'}>
        <Box>
          <Typography variant='subtitle1' color='primary.main'
                      sx={{ textTransform: 'uppercase', display: { laptop: 'block', mobile: 'none' } }}>
            Testimonials
          </Typography>
          <Typography variant='h3' sx={{ mt: 2 }} color={'black.main'}>
            {testimonialPage[activeStep].title}
          </Typography>
          <Typography variant='body1' mt={5} color={'black.main'}>
            <b>{testimonialPage[activeStep].name}, {testimonialPage[activeStep].qualification}</b> <br />
            {testimonialPage[activeStep].designation} <br />
            {testimonialPage[activeStep].company}

          </Typography>
        </Box>
        <Box mt={5}>
          <Stack display='flex' direction='row'>
            <Button variant='outlined' color={'primary'} sx={TestimonialsStyles.button} disabled={activeStep === 0}
                    onClick={handleBack}>
              Previous
            </Button>
            <Button variant='outlined' color={'primary'} sx={TestimonialsStyles.button}
                    disabled={activeStep + 1 === testimonialPage.length}
                    onClick={handleNext}>
              Next
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};
export default Testimonials;
