// mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// constants
import styles from './styles';

const GetStartPage = () => {
  return (
    <Container sx={styles.backgroundContainer}>
      <Stack mt='100px' mb='100px' alignItems='center' height='100%' width='700.288px'>
        <Typography align='center' sx={styles.heading}>
          Get started
        </Typography>
        <Typography align='center' sx={styles.title}>
          We sincerely invite you to join us, bring your creativity and ideas to explore the future
          of drug development together!
        </Typography>
      </Stack>
    </Container>
  );
};
export default GetStartPage;
