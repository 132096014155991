// @ts-ignore
import Charlotte from '../../assets/testimonials/Charlotte Jones-Burton.png';
// @ts-ignore
import Placeholder from '../../assets/testimonials/placeholder-testimonials.jpg';
// @ts-ignore
import CT from '../../assets/resources/ct.jpg';
// @ts-ignore
import Recon from '../../assets/resources/recon.jpg';
// @ts-ignore
import Course from '../../assets/resources/course.jpg';
// @ts-ignore
import David from '../../assets/testimonials/David.jpeg';
// @ts-ignore
import Arash from '../../assets/testimonials/Arash.jpeg';

export const valueData = [
  {
    number: '01',
    title: 'Increase your brand value',
    value:
      'Knowledge and skills gained at Vivpro varsity will help you establish yourself as a leader in the field of medical product innovation.'
  },
  {
    number: '02',
    title: 'Excel in your career',
    value:
      'Tools and experiences at Vivpro varsity will expand your network and advance in your job more quickly.'
  },
  {
    number: '03',
    title: 'Open new doors',
    value:
      'Exposure and training at Vivpro varsity will empower you to bring innovative ideas to your organization'
  },
  {
    number: '04',
    title: 'Reach New Heights',
    value:
      'Immersive experience at Vivpro varsity will help you attain new heights as an innovator in product strategy creation.'
  },
  {
    number: '05',
    title: 'Differentiate yourself',
    value:
      'Extensive and comprehensive education at Vivpro varsity will enable you to distinguish yourself as an innovative leader inside the organization.'
  }
];
export const testimonialPage = [
  {
    title:
      'If you want to develop a winning R&D strategy faster, this training is for you. You will not only gain knowledge from ground-breaking examples, but also be able to implement them into your own program.  The training we received was instantly useful to our team.',
    name: 'Charlotte Jones-Burton',
    qualification: 'M.D., M.S.',
    designation: 'Senior Vice President Product Development and Strategy',
    company: 'Chinook Therapeutics',
    imgPath: Charlotte
  },
  {
    title:
      'We looked at a significant amount of intelligence that was obtained from a totally different point of view of the FDA. It prompted a variety of alternative thought processes and drove home the point that we need to look outside the box when working with regulatory strategy . It is great that so many choose to participate in this workshop and this shows our interest in accessing and utilising regulatory intelligence. The workshop was based around core science and strategy and not just a tool .',
    name: 'David Truloff',
    qualification: '',
    designation: 'Senior Director Regulatory Affairs',
    company: 'Novo Nordisk  ',
    imgPath: David
  },
  {
    title:
      'This is a great course to learn and design a winning drug development strategy. It is well recommended for drug developers who need to delve in deeper knowledge of innovative and model based drug development #MIDD. I have twice hosted this course in my group with cross functional team members and received many positive feedbacks.',
    name: 'Arash Raoufinia',
    qualification: 'MS, PharmD',
    designation: 'VP Head of Early Phase Development and Translational Medicine',
    company: 'Otsuka Pharmaceutical Companies (U.S.)',
    imgPath: Arash
  },
  {
    title:
      'A practical guide to effective drug development with relevant, in-depth examples providing a comprehensive review of regulatory landscape. The speaker is very interactive and keeps the audience engaged.',
    name: 'Zeeshan Khawaja',
    qualification: 'MD, FASN',
    designation: 'Executive Medical Director, Clinical Development',
    company: 'Chinook Therpaeutics',
    imgPath: Placeholder
  }
];

export const researchPage = [
  {
    description:
      'This FREE Vivpro resource allows you to download a personalized report to assess the state of enrollment by race and ethnicity across >400K+ clinical trials',
    title: 'Free Content',
    title2: 'Clinical trials'.toUpperCase(),
    image: CT,
    link: '/resources/ct'
  },
  {
    description:
      'This in-person event allows you to learn about innovative drug development approaches to build a winning strategy, derive inspiration with case studies, and practice with hands-on workshop',
    title: 'RE-CON Event',
    title2: 'UMB Workshop'.toUpperCase(),
    image: Recon,
    link: '/recon'
  },
  {
    description:
      'This virtual course resource allows you to complete various certification levels and claim mastery on innovative drug development approaches to build a winning strategy',
    title: 'Virtual Courses',
    title2: 'coming soon'.toUpperCase(),
    image: Course,
    link: ''
  }
];
