const styles = {
  heading: {
    background: 'linear-gradient(to right, #5EC0FF 0%, #33D6E5 30%, #31DCC4 50%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    fontSize: '24px',
    lineHeight: '28.8px',
    fontWeight: 700,
    fontFamily: 'Plus Jakarta Sans',
  },
  title: {
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '54px',
    fontFamily: "Plus Jakarta Sans",
    color: 'gray.900',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      fontSize: '36px',
    }
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '24px',
    color: 'gray.800',
    fontWeight: 600,
    mt: '32px'
  },
  accordion: {
    mt: '32px',
    p: '24px',
    width: { mobile: '100%', laptop: '100%' },
    color: 'gray.800',
    backgroundColor: 'white.main',
    borderRadius: '8px',
    borderStyle: 'solid',
    boxShadow: 'none',
    '& a': { color: 'orange.main', '&:hover': { color: 'red.light' } }
  },
  box : { display: 'flex', flexDirection: 'column', alignItems: 'center', my: '100px',     
    '@media (max-width: 768px)': {
      my: '75px'
    },  
  }
};

export default styles;
