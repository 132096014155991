import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const FiltersTextField = styled(TextField)(({ theme }) => ({
  background: 'white',
  border: '1px solid #707070',
  borderRadius: 25,
  height: 49,
  maxWidth: 213,
  width: '80%',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'transparent'
    },
    '&:hover fieldset': {
      borderColor: 'transparent'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
      borderRadius: 25
    }
  }
}));

export default FiltersTextField;
