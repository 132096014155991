import { useNavigate } from 'react-router-dom';

// mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

// stylesheet
import styles from './Banner.styles';

// assets
import DotConnector from '../../../assets/svg/Recon/DotConnectors.svg';
import DotConnector1 from '../../../assets/svg/Recon/DotConnectors1.svg';
import UniversityLogo from '../../../assets/pngs/Recon/university_logo.png';
// constants
import { bannerDetails } from '../constant';

const Banner = () => {
  const navigate = useNavigate();

  return (
    <Stack>
      <Container maxWidth='desktop'>
        <Grid spacing={1} container sx={{ height: '90vh' }}>
          <Grid item laptop={8} desktop={8} tablet={8} mobile={12}>
            <Stack spacing={6} alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
              {/* header text */}
              <Stack spacing={1} sx={{ width: '90%' }}>
                <Typography
                  textAlign='left'
                  variant='h1'
                  sx={{ letterSpacing: 1, fontWeight: '700', color: 'white.main' }}>
                  {bannerDetails.heading}
                </Typography>
                <Typography
                  textAlign='left'
                  variant='h3'
                  sx={{ letterSpacing: 1, color: 'white.main' }}>
                  {bannerDetails.subtitle}
                </Typography>
              </Stack>
              <Stack sx={styles.dateContainer}>
                {/* date and location */}
                <Stack spacing={1}>
                  <Typography
                    textAlign='left'
                    variant='h4'
                    sx={{
                      color: 'white.main',
                      fontWeight: '700',
                      textTransform: 'capitalize'
                    }}>
                    {bannerDetails.date}
                  </Typography>
                  <Typography
                    textAlign='left'
                    variant='subtitle2'
                    sx={{ color: 'white.main', textTransform: 'capitalize' }}>
                    {bannerDetails.location}
                  </Typography>
                </Stack>
                {/* book btn */}
                <Button
                  onClick={() => navigate('/recon/book')}
                  variant='contained'
                  color='primary'
                  disabled
                  sx={styles.bookBtn}>
                  Register Now
                </Button>
              </Stack>
              <Stack sx={{ width: { mobile: '90%', laptop: '90%' } }}>
                <Stack sx={{ width: { mobile: '90%', laptop: '50%' } }}>
                  <img src={UniversityLogo} alt='logo' style={{ width: '90%' }} />
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            desktop={4}
            tablet={4}
            mobile={4}
            laptop={3}
            sx={{ display: { mobile: 'none', laptop: 'flex', desktop: 'flex' } }}>
            <Stack
              justifyContent='center'
              alignItems='center'
              sx={{ width: '100%', height: '100%' }}>
              <img src={DotConnector} style={{ width: 400, height: 500 }} alt='text' />
              <img
                style={{ marginLeft: -300, marginTop: -200, width: 300, height: 200 }}
                src={DotConnector1}
                alt='text'
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Stack>
  );
};
export default Banner;
