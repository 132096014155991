import { committeeMembers } from '../constant';

import PeopleList from '../PeopleList';

const Committee = () => {
  const gridSizeList = committeeMembers.map(() => 3);

  return (
    <PeopleList
      pageTitle='Planning Committee'
      members={committeeMembers}
      gridSizeList={gridSizeList}
    />
  );
};
export default Committee;
