let element: HTMLSpanElement | undefined = undefined;

// Function to get the string size in pixels.
function getWidthOfText(txt: string, fontSize: number){
    if(!element){
        element = document.createElement('span');
        // element.style.display = "none";
        document.body.appendChild(element);
    }
    element.style.fontSize = `${fontSize}px`;
    element.innerText = txt;
    element.style.position = 'absolute';
    element.style.top = '-1000px';
    element.innerText = txt;
    return element.offsetWidth;
};

export default getWidthOfText;