// import required modules
import { committeeMembers } from '../constant';

import PeopleList from '../../../Component/PeopleList';

const Committee = () => {
  const gridSizeList = committeeMembers.map(() => 3);

  return (
    <PeopleList
      pageTitle={'Meet the planning committee'}
      members={committeeMembers}
      gridSizeList={gridSizeList}
    />
  );
};
export default Committee;
