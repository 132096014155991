
const styles: { [key: string]: any } = {
    backgroundContainer: {
        background: 'white.main',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '302px',
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width: 768px)': {
            mb: '60px',
        }
    },
    heading: {
        color: '#0076D6',
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: "Plus Jakarta Sans",
        lineHeight: '120%' 
    },
    title: {
        fontSize: '24px',
        fontWeight: 700,
        color: '#001023',
        mt: '10px',
        '@media (max-width: 768px)': {
            fontSize: '21px',
        }
    }
};

export default styles;