import React from 'react';

// mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// constants
import styles from './contact.styles';
import { Dialog } from '@mui/material';
import Contact from '../../Home/Sections/Contact';
import ContactForm from './ContactForm';



const ContactUs = () => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const handleOpen = () => {
    setOpenDialog(true);
  }

  const handleClose = () => {
    setOpenDialog(false);
  }

  return (
    <Container sx={styles.containerBackground} >
      <Container  maxWidth='desktop'>
        <Stack alignItems='center'  sx={styles.container}>
          <Typography align='center' sx={styles.title}>
            Still Have Questions?
          </Typography>
          <Typography align='center' mt='12px' sx={styles.subtitle}>
            We’d be happy to answer any questions you might have. Feel free to reach out!
          </Typography>
          <Button variant='outlined' sx={styles.contantUsButton} onClick={() => {handleOpen()}}>
            Contact Us
          </Button>
        </Stack>
      </Container>
      {openDialog && (
        <Dialog
        open={openDialog}
        onClose={() => {handleClose()}}
        aria-describedby='Contant us '
        sx={{
          '& .MuiDialog-paper': {
            maxHeight: 'calc(100% - 64px)',
            width: '600px',
            '@media (max-width: 768px)': {
              width: '100%',
            }
          }
        }}>
          <Stack display='flex' flexDirection='column' sx={{
          padding: '25px'
        }}>
            <ContactForm  handleClose={handleClose}/>
          </Stack>
          
      </Dialog>
      )}
    </Container>
  );
};
export default React.memo(ContactUs);
