import { speakerMembers } from '../constant';
import PeopleList from '../../../Component/PeopleList';

const Speakers = () => {
  const gridSizeList = speakerMembers.map(() => 3);

  return (
    <PeopleList
      pageTitle={'Meet the Speakers'}
      members={speakerMembers}
      gridSizeList={gridSizeList}
    />
  );
};
export default Speakers;
