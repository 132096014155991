import * as React from 'react';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import VarsityLogo from '../../assets/svg/VarsityLogo';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import navbarStyles from './styles/navbarStyles';
import ReconLogo from '../../assets/svg/ReconLogo';
import { Container } from '@mui/material';
import NavBar2025 from '../../Pages/Recon2025/NavBar2025';

const drawerWidth = 240;
export const navItems = [
  { name: 'Varsity', link: '/' },
  { name: 'Value', link: '/value' },
  { name: 'Certification', link: '/certification' },
  { name: 'Testimonials', link: '/testimonial' },
  { name: 'Resources', link: '/resources' },
  { name: 'Contact', link: '/contact' }
];

export const reconNavItems = [
  { name: 'Agenda', link: '/recon/agenda' },
  { name: 'Testimonials', link: '/recon/testimonials' },
  { name: 'Committee', link: '/recon/committee' },
  { name: 'Speakers', link: '/recon/speakers' },
  { name: 'Pricing', link: '/recon/register' },
  { name: 'FAQ', link: '/recon/faq' }
];

export const recon2023NavItems = [
  { name: 'About', link: '/recon2023' },
  { name: 'Schedule', link: '/recon2023/schedule' },
  { name: 'Committee', link: '/recon2023/committee' },
  { name: 'Venue', link: '/recon2023/venue' },
  { name: 'FAQ', link: '/recon2023/faq' }
];

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const isOldRecon =
    location.pathname.startsWith('/recon2024') || location.pathname.startsWith('/recon2023');

  const appBarBackground = isOldRecon
    ? 'black.dark'
    : location.pathname.startsWith('/recon')
    ? 'transparent'
    : 'white.main';

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        height: '100%',
        textAlign: 'center',
        bgcolor: location.pathname.startsWith('/recon') ? 'black.dark' : 'white.main'
      }}>
      <Button disableElevation disableRipple to={'/'} component={RouterLink}>
        {location.pathname.startsWith('/recon') ? (
          <>
            {location.pathname.startsWith('/recon2') ? (
              <ReconLogo />
            ) : (
              <img
                src={'https://vivpro-site.s3.amazonaws.com/Recon/2025/reconLogo.png'}
                alt='logo'
                style={{ maxWidth: '200px' }}
              />
            )}
          </>
        ) : (
          <VarsityLogo />
        )}
      </Button>
      <Divider />
      <List>
        {!location.pathname.startsWith('/recon') &&
          navItems.map(({ name, link }) => (
            <ListItem key={name} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} component={RouterLink} to={link}>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        {(location.pathname.startsWith('/recon/') || location.pathname.startsWith('/recon')) &&
          reconNavItems.map(({ name, link }) => (
            <ListItem key={name} disablePadding>
              <ListItemButton
                sx={{ textAlign: 'center', color: 'white.main' }}
                component={RouterLink}
                to={link}>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
        {location.pathname.startsWith('/recon2') &&
          recon2023NavItems.map(({ name, link }) => (
            <ListItem key={name} disablePadding>
              <ListItemButton
                sx={{ textAlign: 'center', color: 'white.main' }}
                component={RouterLink}
                to={link}>
                <ListItemText primary={name} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </Box>
  );

  return (
    <Container
      sx={
        location.pathname === '/recon' || location.pathname.startsWith('/recon/')
          ? { maxWidth: '100%', pl: 0, pr: 0 }
          : { maxWidth: 'desktop' }
      }>
      {location.pathname === '/recon' || location.pathname.startsWith('/recon/') ? (
        <NavBar2025 handleDrawerToggle={handleDrawerToggle} />
      ) : (
        <AppBar
          component='nav'
          elevation={0}
          sx={{ bgcolor: appBarBackground }}
          position={'sticky'}>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                display: { laptop: 'none' },
                color: location.pathname.startsWith('/recon') ? 'white.main' : 'black.main'
              }}>
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                width: { mobile: '100%', laptop: '55%' },
                display: { mobile: 'flex', laptop: 'block', justifyContent: 'center' }
              }}>
              <Button
                disableElevation
                disableRipple
                onClick={() => navigate('/')}
                sx={{ width: '20%', minWidth: 200 }}>
                {location.pathname.startsWith('/recon') ? <ReconLogo /> : <VarsityLogo />}
              </Button>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                ml: 5,
                display: { mobile: 'none', laptop: 'flex', justifyContent: 'space-between' }
              }}>
              {!location.pathname.startsWith('/recon') &&
                navItems.map(({ name, link }) => (
                  <Button
                    key={name}
                    sx={{ ...navbarStyles.button, ...{ textTransform: 'none' } }}
                    component={RouterLink}
                    end
                    to={link}
                    disableElevation
                    disableRipple>
                    {name}
                  </Button>
                ))}
              {(location.pathname === '/recon' || location.pathname.startsWith('/recon/')) &&
                reconNavItems.map(({ name, link }) => (
                  <Button
                    key={name}
                    sx={{ ...navbarStyles.reconButton, ...{ textTransform: 'none' } }}
                    component={RouterLink}
                    end
                    to={link}
                    disableElevation
                    disableRipple>
                    {name}
                  </Button>
                ))}
              {(location.pathname === '/recon2023' ||
                location.pathname.startsWith('/recon2023/')) &&
                recon2023NavItems.map(({ name, link }) => (
                  <Button
                    key={name}
                    sx={navbarStyles.reconButton}
                    component={RouterLink}
                    end
                    to={link}
                    disableElevation
                    disableRipple>
                    {name}
                  </Button>
                ))}
              {(location.pathname === '/recon' || location.pathname.startsWith('/recon/')) && (
                <Button
                  variant='outlined'
                  sx={navbarStyles.contantUsButton}
                  onClick={() => {
                    navigate('/recon/contactus');
                  }}>
                  Contact Us
                </Button>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Box component='nav'>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            display: { tablet: 'block', laptop: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}>
          {drawer}
        </Drawer>
      </Box>
    </Container>
  );
};

export default NavBar;
