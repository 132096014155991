import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DialogContent from '@mui/material/DialogContent';

import React, { useCallback, useEffect, useState } from 'react';
import HorizontalBarGraph from '../../../../Component/HorizontalBarGraph';
import useWordCloud from '../../hooks/useWordCloud';
import GraphTitle from '../GraphTitle';
import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import LaunchIcon from '@mui/icons-material/Launch';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import styles from '../../styles';

interface Option {
  label: string;
  value: string;
}

const Variations = ({
  graphData,
  title,
  onAddToReport,
  graphOptions,
  selectedGraph,
  onGraphOptionChange
}: {
  graphData: any;
  title: string;
  selectedGraph?: string;
  graphOptions?: Array<Option>;
  onAddToReport?: () => void;
  onGraphOptionChange?: (val: string) => void;
}) => {
  const { getWordCloudImage, wordCloudUrl } = useWordCloud();
  const [selectedWorldCloudImageUrl, setWorldCloudImageUrl] = useState<any>();
  const [anchorEle, setAnchor] = useState<any>();

  const handleTotalClick = useCallback(
    (race: string) => {
      getWordCloudImage(graphData);
    },
    [getWordCloudImage, graphData]
  );

  useEffect(() => {
    setWorldCloudImageUrl(wordCloudUrl);
  }, [wordCloudUrl]);

  const handleWordCloudClose = useCallback(() => setWorldCloudImageUrl(null), []);

  const handleShowDown = useCallback((event: any) => {
    setAnchor(event.target);
  }, []);

  return (
    <Box
      height='100%'
      id='vertical-graph-1'
      sx={{ backgroundColor: 'white.main', borderRadius: 5 }}
      pb={2}>
      {/* Display available graph options */}
      {graphOptions && graphOptions.length > 0 && (
        <Menu
          open={!!anchorEle}
          anchorEl={anchorEle}
          id='select'
          onClose={() => setAnchor(null)}
          sx={{ fontSize: { tablet: 14, desktop: 16 }, minWidth: 200, textAlign: 'center' }}>
          {graphOptions?.map((option: Option) => (
            <MenuItem
              value={option.value}
              key={option.value}
              selected={selectedGraph === option.value}
              onClick={() => {
                onGraphOptionChange?.(option.value);
                setAnchor(null);
              }}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      )}
      {/* Display available graph options ends */}

      {/* Graph Container and its title Container */}
      <Box width='100%' pt={2}>
        {/* Graph Titles */}
        <Box display='flex' width='100%' alignItems={'center'}>
          <Box flex='2' display='inline-block'>
            <Typography
              flex='2'
              textAlign={'start'}
              alignSelf='start'
              fontWeight='bold'
              pl={5}
              fontSize={{ tablet: 14, desktop: 16 }}>
              Variation in description of race and ethnicity
            </Typography>
          </Box>
          {/* Add to Report */}
          <Box flex='2' textAlign='end' pr={4}>
            {onAddToReport && (
              <Button sx={styles.actionButton} onClick={onAddToReport}>
                + Add to Report
              </Button>
            )}
          </Box>
          {/* Add to Report END*/}
        </Box>
        <Box width='100%'>
          {/* Race selection and Word cloud hyperlinks */}
          <Box
            pl={5}
            height='40px'
            display={'flex'}
            alignItems={'center'}
            justifyContent='start'
            sx={{ cursor: 'pointer' }}
            onClick={handleShowDown}>
            <Typography
              textAlign='center'
              color='gray.main'
              display='inline-block'
              sx={{ fontSize: { mobile: 12, tablet: 14, laptop: 14, desktop: 16 } }}>
              <Tooltip title='Select a Race'>
                <span>{graphData.name}</span>
              </Tooltip>
              <Tooltip title='Click to View Word Cloud'>
                <span
                  style={{ color: '#0000ff', marginLeft: 4 }}
                  onClick={e => {
                    e.stopPropagation();
                    handleTotalClick(graphData.race_id);
                  }}>
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    {graphData.total_variations}{' '}
                    {onAddToReport && (
                      <LaunchIcon sx={{ fontSize: { tablet: 14, desktop: 16 }, pt: 0.1 }} />
                    )}
                  </span>
                </span>
              </Tooltip>
            </Typography>
            {onAddToReport && (
              <Box display='inline-block'>
                <Tooltip title='Select a Race'>
                  <ArrowDropDownTwoToneIcon />
                </Tooltip>
              </Box>
            )}
          </Box>
          {/* Race selection and Word cloud hyperlinks end */}
        </Box>
        {/* Graph Titles end */}

        {/* Horizontal Graph */}
        <HorizontalBarGraph key={graphData.race_id} data={[graphData]} />
        {/* Horizontal Graph End*/}
      </Box>
      <Box flex='6' display='flex' mt={2}>
        <GraphTitle title={title} />
      </Box>
      <Dialog open={!!selectedWorldCloudImageUrl} onClose={handleWordCloudClose}>
        <DialogContent sx={{ minHeight: '500px' }}>
          <Box display='flex' justifyContent='end'>
            <CancelOutlinedIcon
              onClick={handleWordCloudClose}
              sx={{ color: 'primary.main', cursor: 'pointer' }}
            />
          </Box>
          <Box p={2}>
            <img src={selectedWorldCloudImageUrl} width={800} alt='world cloud' />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default React.memo(Variations);
