import uniqueId from 'lodash/uniqueId';

// mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// constant
import { videoDetails } from '../constant';

const VideoContent = () => {
  return (
    <Container maxWidth='desktop'>
      <Grid container mt={15} sx={{ height: '100%' }}>
        <Grid item laptop={6} desktop={6} tablet={6} mobile={12}>
          <Stack alignItems='center'>
            <Stack sx={{ height: '80%', width: '90%' }}>
              <video
                controls={true}
                poster={videoDetails.videoThumbnail}
                style={{ borderRadius: 10 }}
                src={videoDetails.videoLink}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item laptop={6} desktop={6} tablet={6} mobile={12}>
          <Stack
            spacing={2}
            justifyContent='space-evenly'
            sx={{
              width: '100%',
              mt: { mobile: 4, laptop: 0 }
            }}>
            <Typography
              variant='h2'
              sx={{
                fontWeight: 700,
                letterSpacing: 1,
                color: 'white.main',
                textAlign: {
                  mobile: 'center',
                  laptop: 'left'
                }
              }}>
              Why attend?
            </Typography>
            <ul>
              {videoDetails.videoDescription.map((text: string) => {
                return (
                  <li key={uniqueId()} style={{ color: '#fff', marginTop: '8px' }}>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: text }}
                      variant='subtitle2'
                      sx={{
                        color: 'gray.light',
                        textAlign: {
                          mobile: 'justify',
                          laptop: 'left'
                        }
                      }}
                    />
                  </li>
                );
              })}
            </ul>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};
export default VideoContent;
