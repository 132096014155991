import React from 'react';
import Dialog from '@mui/material/Dialog';
import Methodology from '.';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const MethodologyDialog = ({ open, onClose }: any) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ bgcolor: 'red' }}>
      <Box display='flex' justifyContent='flex-end' sx={{ backgroundColor: 'white.main' }}>
          <IconButton onClick={onClose}>
            <CancelOutlinedIcon color='primary' fontSize='small' />
          </IconButton>
        </Box>
        <Box width={{mobile: '100%', tablet: 720, desktop: 800, margin: 'auto'}}>
          <Methodology />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(MethodologyDialog);
