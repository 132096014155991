const navbarStyles = {
  button: {
    color: 'black.light',
    letterSpacing: '0.02em',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: 'transparent',
      textUnderlineOffset: '.5em',
      textDecoration: 'underline'
    },
    '&.active': {
      color: 'black.main',
      textUnderlineOffset: '.5em',
      textDecoration: 'underline'
    }
  },
  reconButton: {
    color: 'white.dark',
    letterSpacing: '0.02em',
    fontSize: '14px',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
      textUnderlineOffset: '.5em',
      textDecoration: 'underline'
    },
    '&.active': {
      color: 'white.main',
      textUnderlineOffset: '.5em',
      textDecoration: 'underline'
    }
  },
  contantUsButton: {
    borderRadius: '50px',
    fontSize: '16px',
    fontWeight: 700,
    width: '151px',
    height: '48px',
    color: 'white.main',
    borderWidth: '2.5px',
    borderColor: '#00C6A8',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    textTransform: 'none',
    '&:hover': {
      borderWidth: '2.5px',
      borderColor: '#00C6A8',
      boxShadow: '0 0 20px rgba(0, 198, 168, 0.7)',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    }
  }
};
export default navbarStyles;
