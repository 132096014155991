import Box from '@mui/material/Box';
import { Data } from 'plotly.js';
import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { GRAPH_LABEL_LENGTH_LIMIT } from '../Pages/CTData/const';
import getWidthOfText from '../utils';

interface VerticalBarGraphProps {
  data: Array<any>;
  title: string;
}

const VerticalBarGraph: React.FC<VerticalBarGraphProps> = ({
  data,
  title
}: VerticalBarGraphProps) => {
  
  const [fontSize, setFontSize] = useState(140);
  useEffect(() => {
    try {
      const xValues = [...(data?.[0]?.x || [])];
      xValues.length = Math.min(25, xValues.length);
      let maxLengthString =
        xValues.reduce(
          (maxlengthstring: string, curr: string) =>
            curr.length > maxlengthstring.length ? curr : maxlengthstring,
          ''
        ) || '';
      if (maxLengthString.length > GRAPH_LABEL_LENGTH_LIMIT) {
        const maxLengthStringArray = maxLengthString.split('');
        maxLengthStringArray.length = GRAPH_LABEL_LENGTH_LIMIT;
        maxLengthString = maxLengthStringArray.join('');
      }
      let calculatedSize = Math.ceil(getWidthOfText(maxLengthString, 14) * 0.707 + 1);
      if (calculatedSize === 0) {
        calculatedSize = 140;
      }
      calculatedSize = Math.max(40, calculatedSize);
      setFontSize(calculatedSize);
    } catch (err) {
      setFontSize(140);
    }
  }, [data]);
  return (
    <Box display='flex' width='100%' height='100%'>
      <Plot
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
        data={data}
        layout={{
          title: { text: title, font: { size: 24 } },
          xaxis: { tickangle: 45 },
          margin: { b: fontSize }
        }}
        config={{ modeBarButtonsToRemove: ['toImage'] }}
      />
    </Box>
  );
};

export default React.memo(VerticalBarGraph);
