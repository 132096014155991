const styles: { [key: string]: any } = {
    containerBackground: {
        backgroundColor: '#F5F5F5',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '639px',
        display: 'flex',
        alignItems: 'center',
        '@media (max-width: 768px)': {
            height: '800px',
        }
      },
    heading: {
        color: '#0076D6',
        fontSize: '16px',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 700
    },
    title: {
        fontSize: '36px',
        fontWeight: 700,
        color: 'black.main',
        fontFamily: 'Plus Jakarta Sans',
        '@media (max-width: 768px)': {
            fontSize: '30px',
        }
    },
    subtitle: {
        fontSize: '16px',
        color: '#424242',
        fontWeight: 500,
        fontFamily: 'Inter',
        width: '420px',
        lineHeight: '140%',
        '@media (max-width: 768px)': {
            fontSize: '14px',
            width: 'fit-content',
        }
    },
    quotesBox: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        backgroundColor: 'white.main',
        borderRadius: '8px',
        padding: '60px 40px 40px 40px',
        height: '230px',
        justifyContent: 'space-between',
        '@media (max-width: 768px)': {
            height: '380px',
            width: '190px',
            mt: '30px'
        }
    },
    nameStyles: {
        fontSize: '20px',
        color: '#0076D6',
        fontWeight: 600,
        fontFamily: 'Inter',
        width: '420px',
        '@media (max-width: 768px)': {
            fontSize: '14px',
            width: 'fit-content',
        }
    },
    positionStyles: {
        fontSize: '14px',
        color: '#424242',
        fontWeight: 600,
        fontFamily: 'Inter',
        width: '420px',
        mt: '4px',
        '@media (max-width: 768px)': {
            fontSize: '14px',
            width: 'fit-content',
        }
    },
    indexSteps: {
        fontSize: '14px',
        color: '#424242',
        fontWeight: 500,
        fontFamily: 'Inter',
    },
    stackContainer : {
        flexDirection: 'column' ,
        ml: '140px', mr:'140px',
        mt: '76px', mb: '76px',
        '@media (max-width: 768px)': {
          flexDirection: 'column' ,
          ml: '0px', mr:'0px',
        }
    },
    box: {
        '@media (max-width: 768px)': {
          alignItems: 'center' 
      }
    },
    quotesIcon : {  width: '35px', height: '28px'}
};

export default styles;