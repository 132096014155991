import { Button, Container, Stack, ThemeProvider, Typography } from '@mui/material';
import * as React from 'react';
import NavBar from '../../Component/Navbar/Navbar';
import Footer from '../../Component/Footer';
import theme from '../../theme';
import CssBaseline from '@mui/material/CssBaseline';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Vivpro | Varsity</title>
        <meta name='description'
              content='Vivpro varsity - A step closer to your dream. Vivpro Varsity provides cutting-edge virtual courses, live events, and free content to help you on your way to becoming a professional R&D strategist.' />
        <meta name='robots' content='noindex,nofollow' />
        <meta name='google' content='nositelinkssearchbox' />
      </Helmet>
      <CssBaseline />
      <Container
        component='main'>
        <NavBar />
        <Stack direction={'column'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}
               height={'70vh'}>
          {/*<Box sx={{width:{mobile:'90%',laptop:'30%'}}}>*/}
          {/*  <HomePageAsset2 />*/}
          {/*</Box>*/}
          <Typography variant={'h1'} mt={2}>Error 404</Typography>
          <Typography variant={'h4'} mt={2}>Page not found</Typography>
          <Button onClick={() => navigate('/')} variant={'outlined'} sx={{mt:2,textTransform:'initial'}}>
            Go back to home
          </Button>

        </Stack>
        <Footer />
      </Container>
    </ThemeProvider>

  );
};

export default PageNotFound;
