const getTableData = (data: any) => {
    const tableData: any = []
    data.forEach((ele: any) => {
      const obj: any = {}
      obj.year = ele.name
      obj.white = ele.x.indexOf('White') > -1 ? ele.y[ele.x.indexOf('White')] : 0
      obj.asian = ele.x.indexOf('Asian') > -1 ? ele.y[ele.x.indexOf('Asian')] : 0
      obj.black = ele.x.indexOf('Black or African American') > -1 ? ele.y[ele.x.indexOf('Black or African American')] : 0
      obj.other = ele.x.indexOf('Unknown or Not Reported') > -1 ? ele.y[ele.x.indexOf('Unknown or Not Reported')] : 0
      if (!(obj.white === 0 && obj.asian === 0 && obj.other === 0)) {
        tableData.push(obj)
      }
    })
    return tableData
  }

  export default getTableData