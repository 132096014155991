export const ResourcesStyles = {
  button: {
    borderColor: 'black.main', mr: 2, height: 40, borderRadius: 0

  }, container: {
    p: '1em 1%',
    mt: 5,
    display: 'flex',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    position: 'relative',
    scrollBehavior: 'smooth',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none', scrollbarWidth: 'none', '-ms-overflow-style': 'none'
    }
  }, cards: {
    border: '1px solid gray.cardsBackground',
    backgroundColor: `gray.cardsBackground`,
    minWidth: 350,
    mr: 5,
    whiteSpace: 'normal',
    '&> button > div': {
      p:3,
      textAlign: 'center'
    },
    textDecoration: 'none',
  }
};

