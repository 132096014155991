import React from 'react';

// mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Tooltip from '@mui/material/Tooltip';

const Speakers = () => {
  const [maxSpeakers, setMaxSpeakers] = React.useState(3);

  const speakerDetails = [
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Peter Stein.png',
      title: 'Dr. Peter Stein',
      author: `Director of CDER's Office of New Drugs (OND), FDA`
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Wasim Malik.png',
      title: 'Dr. Wasim Malik',
      author: 'Managing Partner, IASO Ventures'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Hao Zhu.png',
      title: 'Dr. Hao Zhu',
      author: 'Division Director, Division of Pharmacometrics, Office of Clinical Pharmacology, FDA'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Oliver Jung.png',
      title: 'Dr. Oliver Jung',
      author: `Global Program Head, Established Products and Rare Diseases Oncology, Novartis`
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/William Fitzimmons.png',
      title: 'Dr. William Fitzsimmons',
      author: 'Founder, Tutela Pharmaceuticals'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Wu Fan.png',
      title: 'Dr. Wu Fan',
      author: 'Director of Clinical Pharmacology and Pharmacometrics, Bristol Myers Squibb'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Peter Morcos.png',
      title: 'Dr. Peter Morcos',
      author: 'Senior Director of Clinical Pharmacology, Bayer Pharmaceuticals'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Frank Czeriwiec.png',
      title: 'Frank Czerwiec',
      author: `Chief Medical Officer, Sparrow Pharmaceuticals`
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Piet_Van_Der_Graaf.png',
      title: 'Piet Van Der Graaf',
      author: 'Senior VP, Head of Quantitative Systems Pharmacology, Certara'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Samantha Budd.png',
      title: 'Samantha Budd Haeberlein',
      author: 'Senior VP, Head Neurodegeneration Development Unit, Biogen'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Valerie Fauvelle.png',
      title: 'Valerie Fauvelle',
      author: `Senior VP, Regulatory Affairs, Chinook Therapeutics`
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Ravi_Singh.png',
      title: 'Ravi Shankar Singh',
      author: `Senior Director of Clinical Pharmacology, at Pfizer`
    }
  ];

  const changeSpeakersView = () => {
    if (maxSpeakers === speakerDetails.length) setMaxSpeakers(3);
    else setMaxSpeakers(speakerDetails.length);
  };

  return (
    <Container maxWidth='desktop'>
      <Stack mt={16} sx={{ widht: '100%' }}>
        <ImageListItem key='Subheader'>
          <Typography textAlign='center' variant='h2' sx={{ fontSize: 48, color: 'white.main' }}>
            Meet the Speakers
          </Typography>
        </ImageListItem>
        <ImageList
          gap={4}
          sx={{ width: '100%', height: { mobile: '50vh', laptop: '100%' } }}
          cols={3}>
          {speakerDetails?.slice(0, maxSpeakers).map(item => (
            <ImageListItem key={item.img}>
              <img src={item.img} alt={item.title} loading='lazy' />
              <ImageListItemBar
                title={
                  <Tooltip title={item.title}>
                    <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
                      {item.title}{' '}
                    </Typography>
                  </Tooltip>
                }
                subtitle={
                  <Tooltip title={item.author}>
                    <Typography variant='subtitle2'>{item.author} </Typography>
                  </Tooltip>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Stack>

      <Stack alignItems='center' mt={8}>
        <Button
          onClick={changeSpeakersView}
          variant='outlined'
          sx={{ width: { mobile: '100%', laptop: 200 }, borderColor: 'white.main' }}>
          <Typography variant='body1' sx={{ color: 'white.main', textTransform: 'capitalize' }}>
            {maxSpeakers === speakerDetails.length ? 'Show Less' : 'See All'}
          </Typography>
        </Button>
      </Stack>
    </Container>
  );
};
export default Speakers;
