// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import uniqueId from 'lodash/uniqueId';

// mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Tooltip from '@mui/material/Tooltip';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

// import required modules
import { Pagination, EffectCoverflow } from 'swiper/modules';

const Committee = () => {
  const committeeMembers = [
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Rajanikanth Madabushi.png',
      name: 'Rajanikanth (Raj) Madabushi',
      title: `Associate Director for Guidance and Scientific Policy`,
      company: 'Office of Clinical Pharmacology FDA'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Kevin Carl.png',
      name: 'Kevin Carl',
      title: `Global Head of Strategic Planning and Feasibility`,
      company: 'Novartis'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Akitunde Bello.png',
      name: 'Akintunde Bello',
      title: `VP, Head of Clinical Pharmacology`,
      company: 'Bristol-Myers Squibb'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Klaus Romero.png',
      name: 'Klaus Romero',
      title: `Chief Science Officer`,
      company: 'Critical Path Institute'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Joga Gobburu.png',
      name: 'Joga Gobburu',
      title: `Executive Director, Center of Translational Medicine School of Pharmacy`,
      company: 'University of Maryland, Baltimore'
    },
    {
      img: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/Pravin Jadhav.png',
      name: 'Pravin Jadhav',
      title: `CEO`,
      company: 'Vivpro Corp'
    }
  ];

  return (
    <Container maxWidth='desktop'>
      <Stack alignItems='center' spacing={4} mt={20} sx={{ height: '95vh' }}>
        <Typography align='center' sx={{ fontSize: 48, fontWeight: 700, color: 'white.main' }}>
          Meet the planning committee
        </Typography>
      </Stack>
    </Container>
  );
};
export default Committee;
