// mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

// stylesheet
import styles from './VenueDetails.styles';

// assets
import VenueImage from '../../../assets/pngs/Recon/VenueImage.png';

// constants
import { venueDetails } from '../constant';

const VenueDetails = () => {
  return (
    <Container maxWidth='desktop'>
      <Stack>
        <Grid spacing={1} mt={15} container sx={{ height: '70vh' }}>
          <Grid
            item
            laptop={8}
            desktop={8}
            tablet={8}
            mobile={12}
            sx={{ order: { mobile: 2, laptop: 1 } }}>
            <Stack spacing={2} justifyContent='center' sx={{ height: '100%' }}>
              {/* header text */}
              <Typography variant='subtitle2' sx={{ color: 'white.main' }}>
                Venue
              </Typography>
              <Typography
                textAlign='left'
                variant='h1'
                sx={{ letterSpacing: 1, fontWeight: '700', color: 'white.main' }}>
                {venueDetails.title}
              </Typography>
              <Typography
                textAlign='left'
                variant='subtitle2'
                sx={{ color: 'white.main', textTransform: 'capitalize' }}>
                {venueDetails.address}
              </Typography>
              {/* book btn */}
              <Stack>
                <Button
                  href={venueDetails.link}
                  target='_blank'
                  variant='contained'
                  color='primary'
                  sx={styles.bookBtn}>
                  More About the Venue
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            desktop={4}
            tablet={4}
            mobile={12}
            laptop={3}
            sx={{ order: { mobile: 1, laptop: 2 } }}>
            <Stack
              justifyContent='center'
              alignItems='center'
              sx={{ width: '100%', height: '100%' }}>
              <img src={VenueImage} style={{ width: '100%', height: '70%' }} alt='text' />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Container>
  );
};
export default VenueDetails;
