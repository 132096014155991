import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import CTData from '../Pages/CTData';
import PageNotFound from '../Pages/Error/PageNotFound';
import Home from '../Pages/Home/Home';
import Recon from '../Pages/Recon2025';
import Recon2023 from '../Pages/Recon2023';
import Recon2024 from '../Pages/Recon2024';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <PageNotFound />,
    children: [
      {
        path: '/',
        element: <Home />,
        children: [
          { path: '/value', element: <Home /> },
          { path: '/certification', element: <Home /> },
          { path: '/testimonial', element: <Home /> },
          { path: '/resources', element: <Home /> },
          { path: '/contact', element: <Home /> }
        ]
      },
      { path: '/resources/ct', element: <CTData /> },
      {
        path: '/recon',
        element: <Recon />,
        children: [
          { path: '/recon/about', element: <Recon /> },
          { path: '/recon/agenda', element: <Recon /> },
          { path: '/recon/testimonials', element: <Recon /> },
          { path: '/recon/committee', element: <Recon /> },
          { path: '/recon/speakers', element: <Recon /> },
          { path: '/recon/faq', element: <Recon /> },
          { path: '/recon/register', element: <Recon /> },
          { path: '/recon/contactus', element: <Recon /> }
        ]
      },
      {
        path: '/recon2024',
        element: <Recon2024 />,
        children: [
          { path: '/recon2024/schedule', element: <Recon2024 /> },
          { path: '/recon2024/venue', element: <Recon2024 /> },
          { path: '/recon2024/committee', element: <Recon2024 /> },
          { path: '/recon2024/faq', element: <Recon2024 /> },
          { path: '/recon2024/book', element: <Recon2024 /> }
        ]
      },
      {
        path: '/recon2023',
        element: <Recon2023 />,
        children: [
          { path: '/recon2023/schedule', element: <Recon2023 /> },
          { path: '/recon2023/venue', element: <Recon2023 /> },
          { path: '/recon2023/committee', element: <Recon2023 /> },
          { path: '/recon2023/faq', element: <Recon2023 /> },
          { path: '/recon2023/book', element: <Recon2023 /> }
        ]
      }
    ]
  }
]);

export default router;
