const styles: { [key: string]: any } = {
  textField: {
    '& .MuiInputBase-root': {
      '& input': {
        textAlign: 'left',
        bgcolor: 'black.main',
        color:'gray.lightText'
      }
    },
    '& .MuiInputBase-root.Mui-focused': {
      '& input': {
        p: 1,
        mt: 1,
        bgcolor: 'black.main',
        color: 'gray.lightText'
      }
    },

    '& .MuiFormLabel-root': {
      color: 'gray.lightText'
    },
    '& .MuiFormLabel-root.Mui-focused': {
      mt: -2,
      color: 'white.main',
      fontWeight: 'bold',
      fontSize: '18px'
    },
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 },
  }
};

export default styles;
