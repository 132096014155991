const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    p: 3,
    height: '100%',
    overflowY: 'auto',
    bgcolor: 'white.background'
  },
  clearText: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  closeIcon: {
    cursor: 'pointer'
  },
  title: {
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  btn: {
    marginTop: 1,
    padding: 2,
    width: 10,
    height: 4,
    fontSize: 14,
    fontFamily: 'Nunito Sans',
    textTransform: 'capitalize',
    color: 'gray.darkVariant',
    backgroundColor: 'gray.background',
    borderRadius: 2.5
  },
  selectedOptionsContainer: {
    marginTop: 1,
    p: 1,
    width: 160,
    height: 35,
    borderRadius: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: 'primary.backgroundDark'
  },
  selectedFilter: {
    marginTop: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pl: 1,
    pr: 1,
    width: 160,
    height: 35,
    borderRadius: 18,
    fontWeight: '600',
    color: 'gray.darkVariant',
    backgroundColor: 'primary.backgroundDark'
  },
  popoverBox: {
    height: '100%',
    maxHeight: 500,
    overflowY: 'auto'
  }
};

export default styles;
