const styles: { [key: string]: any } = {
  dateContainer: {
    width: '90%',
    flexDirection: { mobile: 'column', laptop: 'row' },
    alignItems: {
      laptop: 'center'
    }
  },
  bookBtn: {
    marginTop: 2,
    width: { mobile: '100%', laptop: '30%' },
    textTransform: 'capitalize',
    bgcolor: 'green.jungle',
    height: 48
  }
};

export default styles;
