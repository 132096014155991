import { Container, Stack, Typography } from '@mui/material';
import QuoteCarousel from '../../../Component/Carousel/QuoteCarousel';
import { quotes } from '../constant';

const Quotes = () => {
  return (
    <Container maxWidth='desktop'>
      <Stack mt={15}>
        <Typography align='center' sx={{ fontSize: 35, fontWeight: 700, color: 'white.main' }}>
          Testimonials
        </Typography>
        <QuoteCarousel quotesList={quotes} />
      </Stack>
    </Container>
  );
};
export default Quotes;
