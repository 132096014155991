const styles = {
    guideButtonWrapper: {
      position: 'fixed',
      top: '70vh',
      transform: 'rotateZ(270deg)',
      cursor: 'pointer',
      right: '-32px',
      borderRadius: '8px 8px 0px 0px',
      background: 'linear-gradient(180deg, #FF7300 0%, #DB5800 100%)',
      height: '24px',
      width: '91px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      zIndex: 10000000,
    },
    guideText: {
      color: 'white.main',
      fontSize: '14px',
      fontWeight: 500,
      cursor: 'pointer',
    },
    icon: {
      height: '12px',
      width: '12px',
      color: 'white.main',
      transform: 'rotateZ(90deg)',
      cursor: 'pointer',
    }
  };
  
  export default styles;