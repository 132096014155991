const styles: { [key: string]: any } = {
    containerBackground: {
      background: 'white.main',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: 'fit-content',
      pt: '76px',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      pb: '76px'
    },
    heading: {
        fontSize: '36px',
        fontWeight: 700,
        fontFamily: 'Plus Jakarta Sans',
        '@media (max-width: 768px)': {
            fontSize: '30px',
        }
    },
    title: {
      fontSize: '40px',
      fontWeight: 700,
      color: 'black.darkVariant2',
      mt: '10px',
      width: '50%'
    },
    subtitle: {
        fontSize: '16px',
        fontWeight: 700,
        fontFamily: "Plus Jakarta Sans",
        color: '#0076D6',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    agendaContainer: {
        display: 'flex',
        padding: '24px 24px 24px 24px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '52px',
        mt: '40px',
        alignSelf: 'stretch',
        borderRadius: '20px',
        border: '2px solid #D4D4D4',
        '@media (max-width: 768px)': {
            gap: '16px',
          }
    },
    dateBox: {
        display: "flex",
        padding: '10px',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '10px',
        width: '570px',
        cursor: 'pointer',
        '@media (max-width: 768px)': {
            width: '300px',
          }
    },
    selectedDayBox: {
        display: "flex",
        padding: '12px 24px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        width: '240px',
        borderRadius: '40px',
        backgroundColor: '#0076D6',
        color: 'white.main',
        '@media (max-width: 768px)': {
            fontSize: '12px',
            padding: '6px'
          }
    },
    unSelectedDayBox: {
        display: "flex",
        padding: '12px 24px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        width: '240px',
        borderRadius: '40px',
        border: '1px solid',
        borderColor: '#0076D6',
        '@media (max-width: 768px)': {
            fontSize: '12px',
            padding: '6px'
          }
    },
    spanStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
  };
  
  export default styles;
  