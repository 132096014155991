import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";

import { cloneDeep } from "lodash";
import FilterSearchField from "./FilterSearchField";
import StartEndFilter from "./StartEndFilter";
import AutoSearchOptions from "../../../../Component/AutoSearch";

// styles
import styles from "./styles/Filters.styles";

const Filters: React.FC<any> = ({
  filters: filterProps,
  onFilterApply,
  onClearFilters,
  loading= false,
  searchEnabled = false,
}: any) => {
  // states for showing filter options
  const [anchor, setAnchor] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [options, setOptions]: any = useState([]);
  const [openedFilter, setOpenedFilter]: any = useState([]);
  const [filters, setFilters]: any = useState([]);

  useEffect(() => {
    setFilters(cloneDeep(filterProps));
  }, [filterProps]);

  /**
   * function to generate the list of options for selected filter type
   * @param {object} e event handler
   * @param {object} filterType filter category for the options list
   */
  const openFilterOptions = useCallback((e: any, selectedFilter: any) => {
    setOpenedFilter(selectedFilter);
    setAnchor(e.currentTarget);
    setOpenPopup(true);
    // set state for options list
    setOptions(selectedFilter.options);
  }, []);

  /**
   * function to add the selected filters from each category to context state
   * @param {object} e event handler
   * @param {object} option contains id and value for the filter category
   */
  const handleChange = useCallback((e: any, option: any) => {
    const currentOpenFilter = { ...openedFilter };
    const optionIndex = options.findIndex(
      (opt: any) => opt.value === option.value
    );
    const filterIndex = filters.findIndex(
      (fltr: any) => fltr.id === currentOpenFilter.id
    );
    if (filterIndex > -1) {
      options[optionIndex].selected = !options[optionIndex].selected;
      currentOpenFilter.options = [...options];
      filters[filterIndex] = { ...currentOpenFilter };
      setFilters([...filters]);
    }
  }, [filters, openedFilter, options]);

  const handleStartDateChange = useCallback((date: any, filterId: string) => {
    const filterIndex = filters.findIndex((fltr: any) => fltr.id === filterId);
    if (filterIndex > -1) {
      filters[filterIndex].value = {
        ...filters[filterIndex].value,
        start: date,
      };
      setFilters([...filters]);
    }
  }, [filters]);

  const handleEndDateChange = useCallback((date: any, filterId: string) => {
    const filterIndex = filters.findIndex((fltr: any) => fltr.id === filterId);
    if (filterIndex > -1) {
      filters[filterIndex].value = {
        ...filters[filterIndex].value,
        end: date,
      };
      setFilters([...filters]);
    }
  }, [filters]);

  /**
   * fucntion to filter out the results with selected options from the user
   */
  const applyFilter = useCallback(async () => {
    onFilterApply?.(filters);
  }, [filters, onFilterApply]);

  /**
   * function to remove a selected option from filters
   * @param {object} e event handler
   * @param {string} type filter category type
   * @param {string} value option value that needs to be removed
   */
  const removeFromFilters = useCallback((
    e: any,
    filterId: string | number,
    option: any
  ) => {
    const filterIndex = filters.findIndex((fltr: any) => fltr.id === filterId);
    if (filterIndex > -1) {
      const optionIndex = filters[filterIndex].options.findIndex(
        (opt: any) => opt.value === option.value
      );
      if (optionIndex > -1) {
        const currentOpenFilter = { ...filters[filterIndex] };
        currentOpenFilter.options[optionIndex].selected = false;
        filters[filterIndex] = { ...currentOpenFilter };
        setFilters([...filters]);
      }
    }
  }, [filters]);

  /**
   * function to remove all filters and show the original results for the query
   */
  const clearFilters = useCallback(async () => {
    onClearFilters?.(filterProps);
  }, [filterProps, onClearFilters]);

  return (
    <Box sx={styles.root} overflow="hidden">
      <Typography
        align="right"
        variant="subtitle2"
        onClick={clearFilters}
        sx={styles.clearText}
      >
        Clear Filters
      </Typography>
      {/* title and action button */}
      <Stack direction="row" display="flex" justifyContent="space-between">
        <Typography color="primary" sx={styles.title} variant="subtitle2">
          Filters
        </Typography>
      </Stack>

      {/* filter categories */}
      <Stack mt={2} spacing={2} overflow="auto" maxHeight='100vh'>
        {filters.map((item: any) => {
          if (item.filterType === "range")
            // Not implemented yet
            return (
              <StartEndFilter
                key={item.id}
                title={item.label}
                startValue={item.value?.start}
                endValue={item.value?.end}
                minDate={new Date(2011, 1, 1)}
                maxDate={new Date(2100, 1, 1)}
                onEndDateChange={(val: any) =>
                  handleEndDateChange(val, item.id)
                }
                onStartDateChange={(val: any) =>
                  handleStartDateChange(val, item.id)
                }
              />
            );

          return (
            <Stack key={item.id}>
              <Typography variant="subtitle2" fontSize={{tablet: 14, desktop: 16}}>{item.label}</Typography>
              {/* show selected options */}
              {item.options
                .filter((opt: any) => opt.selected)
                .map((opt: any) => {
                  return (
                    <Stack
                      key={opt.value}
                      direction="row"
                      sx={styles.selectedOptionsContainer}
                    >
                      <CloseIcon
                        fontSize="small"
                        sx={{cursor: 'pointer'}}
                        onClick={(e) => removeFromFilters(e, item.id, opt)}
                      />
                      <Typography variant="subtitle2">
                        {opt.label.length > 10
                          ? `${opt.label.substring(0, 10)}...`
                          : opt.label}
                      </Typography>
                    </Stack>
                  );
                })}
              <Button
                id="popup"
                onClick={(e) => openFilterOptions(e, item)}
                sx={styles.btn}
              >
                +Add
              </Button>
            </Stack>
          );
        })}

        {/* search term not fully implemented */}
        {searchEnabled && <FilterSearchField />}
      </Stack>
      {/* <Box> */}
        <LoadingButton
          variant="contained"
          onClick={applyFilter}
          loading={loading}
          loadingPosition="end"
          sx={{
            mt: 2,
            p: 2,
            textTransform: "capitalize",
            fontSize: 16,
            borderRadius: 10,
            bgcolor: "primary.main",
            color: "white.main",
            cursor: "pointer",
          }}
        >
          Apply
        </LoadingButton>
      {/* </Box> */}
      {/* options popup */}
      <Popover
        id="popup"
        sx={{ width: 300, borderRadius: 2, padding: 16 }}
        open={openPopup}
        anchorEl={anchor}
        onClose={() => setOpenPopup(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={styles.popoverBox}>
          <AutoSearchOptions
            searchOptions={options}
            handleCheckboxChange={handleChange}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default React.memo(Filters);
