import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CheckBox = ({ item, onChangeFn }: any) => {
  return (
    <FormControlLabel
      key={item.value}
      sx={{ fontWeight: '600', fontSize: 16, color: '#000000' }}
      control={
        <Checkbox
          value={item.value}
          checked={
            !!item.selected
          }
          onChange={e => onChangeFn(e, item)}
          name={item.value}
        />
      }
      label={item.label}
    />
  );
};

export default React.memo(CheckBox);
