import React, { useCallback, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogTitle, DialogContent, Stack, TextField, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';

// for form
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { BASE_URL } from '../../../../api/ct-data';

interface FeedbackDialogProps {
  open: boolean;
  onClose: () => void;
  sendToast: () => void;
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({ open, onClose, sendToast }) => {
  const handleSubmit = useCallback((values: any, { setSubmitting, resetForm }: any) => {
    onClose();
    setSubmitting(true);
    axios
      .post(`${BASE_URL}/email-service/contact`, values)
      .then(res => {
        setMessage('Message sent successfully');
        setMessageOpen(true);
        resetForm();
        setSubmitting(false);
        sendToast();
      })
      .catch(err => {
        console.error(err);
        setMessage('Message failed to send');
        setMessageOpen(true);
        setSubmitting(false);
      });
  }, []);

  const [message, setMessage] = useState('');
  const [messageOpen, setMessageOpen] = useState(false);
  const initialValues = {
    name: '',
    email: '',
    message: '',
    company: ''
  };

  const [closeFromForm, setCloseFromForm] = useState(true);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'>
      <DialogTitle id='dialog-title'>
        Feedback for Clinical Trials Resources
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers sx={{ width: '750px' }}>
        <Formik
          initialValues={{ ...initialValues }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Name is required'),
            email: Yup.string().email('Email is invalid').required('Email is required'),
            message: Yup.string(),
            company: Yup.string().required('Company is required')
          })}
          onSubmit={handleSubmit}>
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            handleSubmit
          }) => (
            <form onSubmit={handleSubmit}>
              <Stack
                display='flex'
                flexDirection='column'
                justifyContent='space-evenly'
                sx={{ height: 500, width: { mobile: '100%', laptop: '100%' } }}>
                <TextField
                  fullWidth
                  required
                  id='name'
                  name='name'
                  label='Your name'
                  variant='standard'
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  required
                  id='email'
                  name='email'
                  label='Email'
                  variant='standard'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  fullWidth
                  required
                  id='company'
                  name='company'
                  label='Your company name'
                  variant='standard'
                  value={values.company}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.company && Boolean(errors.company)}
                  helperText={touched.company && errors.company}
                />
                <TextField
                  fullWidth
                  id='message'
                  name='message'
                  label='Comment/Question/Suggestions'
                  variant='standard'
                  multiline
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.message && Boolean(errors.message)}
                  helperText={touched.message && errors.message}
                  maxRows={4}
                  placeholder='Tell us how we can improve'
                />
                <Button variant={'contained'} type='submit' disabled={!(isValid && dirty)}>
                  Submit
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default FeedbackDialog;
