import { Typography, Box, Button } from '@mui/material';
import styles from './Registration.styles';
import { registerationDetails } from '../constant';

const Ticket = ({
  title,
  price,
  earlyPrice,
  paymentLink,
  index,
  isEarlyBird
}: {
  title: string;
  price: string;
  earlyPrice: string;
  paymentLink: string;
  index: number;
  isEarlyBird: boolean;
}) => {
  return (
    <Box sx={styles.ticket}>
      <Box sx={styles.ticketBackground}>
        <Box sx={styles.semicircle} />
        <Typography sx={styles.ticketHeader}>{title}</Typography>
        <Typography sx={styles.ticketPrice}>{isEarlyBird ? earlyPrice : price}</Typography>
        <Typography
          sx={{ ...styles.ticketEarlyBird, visibility: isEarlyBird ? 'visible' : 'hidden' }}>
          Early-bird price
        </Typography>
      </Box>
      <Box sx={styles.box}>
        {isEarlyBird && (
          <Typography sx={styles.ticketEarlyBirdText}>(Early-bird pricing ends Oct 31)</Typography>
        )}
        {isEarlyBird && (
          <Typography
            sx={styles.ticketEarlyPriceRegularPrice}>{`Regular Price: ${price}`}</Typography>
        )}
        <Button variant='contained' sx={styles.registerButton} href={paymentLink}>
          Register
        </Button>
      </Box>
    </Box>
  );
};

const Registration = () => {
  const isEarlyBird = () => {
    const now = new Date();

    // Get the current EST/EDT time
    const estTime = new Date(
      new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(now)
    );

    // Create a Date object for November 1, 2024 in local time (EST/EDT)
    const targetDate = new Date('2024-11-01T00:00:00');
    const targetTime = new Date(
      new Intl.DateTimeFormat('en-US', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).format(targetDate)
    );
    return estTime < targetTime;
  };

  return (
    <Box sx={styles.background}>
      <Typography sx={styles.heading}>Registration</Typography>
      <Box mt={4} sx={styles.ticketBox}>
        {registerationDetails.map((detail, index) => (
          <Ticket
            key={detail.title}
            title={detail.title}
            price={detail.price}
            earlyPrice={detail.earlyPrice}
            paymentLink={detail.paymentLink}
            index={index}
            isEarlyBird={isEarlyBird()}
          />
        ))}
      </Box>
    </Box>
  );
};
export default Registration;
