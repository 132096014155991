import uniqueId from 'lodash/uniqueId';

// mui
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// constants
import { eventInNumbers } from '../constant';

const NumericDetails = () => {
  return (
    <Container maxWidth='desktop'>
      <Grid mt={10} container>
        {Object.keys(eventInNumbers).map((item: string) => {
          return (
            <Grid
              key={uniqueId()}
              item
              display='flex'
              justifyContent='flex-start'
              laptop={3}
              desktop={3}
              tablet={3}
              mobile={12}>
              <Stack alignItems='center' sx={{ width: { mobile: '100%', laptop: '90%' } }}>
                <Typography sx={{ fontWeight: 700, fontSize: 72, color: 'white.main' }}>
                  {eventInNumbers[item]}
                </Typography>
                <Typography variant='h4' sx={{ color: 'white.contrastText' }}>
                  {item}
                </Typography>
              </Stack>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};
export default NumericDetails;
