// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

// import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Stack, Typography } from '@mui/material';

const QuoteCarousel = ({ quotesList }) => {
  return (
    <Swiper
      style={{
        background: 'transparent',
        '--swiper-pagination-color': '#289D78',
        '--swiper-pagination-bullet-inactive-color': '#333333',
        '--swiper-pagination-bullet-inactive-opacity': '1',
        '--swiper-pagination-bullet-size': '10px',
        '--swiper-pagination-bullet-horizontal-gap': '6px'
      }}
      modules={[Autoplay, Pagination]}
      autoplay={{ delay: 5000, pauseOnMouseEnter: true }}
      loop
      pagination={{ clickable: true, dynamicBullets: true }}>
      {quotesList.map(({ quoteText, quoteTitle }) => (
        <SwiperSlide>
          <Stack
            sx={{
              fontStyle: 'italic',
              fontSize: '1.1rem',
              color: 'grey.800'
            }}>
            <Typography variant='subtitle1' color='primary.main' mt={3}>
              {`❝ ${quoteText} ❞`}
            </Typography>
            <Typography>
              <br />
            </Typography>
            <Typography
              variant='subtitle1'
              color='primary.main'
              dangerouslySetInnerHTML={{ __html: quoteTitle }}></Typography>
          </Stack>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
export default QuoteCarousel;
