import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography
} from '@mui/material';
import { CommitteeMemberBioType } from '../../Pages/Recon2025/constant';
import { Fragment, useState } from 'react';
import { Cancel } from '@mui/icons-material';

// style
import './styles.css';
import { isEmpty } from 'lodash';

interface PeopleListProps {
  pageTitle: string;
  members: CommitteeMemberBioType[];
  gridSizeList: number[];
  isCompact?: false;
}

const PeopleList = ({ pageTitle, members, gridSizeList, isCompact = false }: PeopleListProps) => {
  const [bioInfo, setBioInfo] = useState<CommitteeMemberBioType>({
    open: false,
    name: '',
    bio: '',
    img: '',
    bioImg: '',
    title: '',
    bioName: '',
    bioQualifications: '',
    company: ''
  });

  const toggleDrawer =
    (data: CommitteeMemberBioType, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      const newData = { ...data };
      newData.title = data.title.replace('<br>', ' ');
      setBioInfo({ ...newData, open: open });
    };
  return (
    <Fragment key={'bioDrawer'}>
      <Container maxWidth='desktop'>
        <Stack alignItems='center' spacing={isCompact ? 0 : 10} mt={isCompact ? 0 : 20}>
          <Typography
            align='center'
            sx={{ fontSize: isCompact ? 40 : 48, fontWeight: 700, color: 'white.main' }}>
            {pageTitle}
          </Typography>
          <Box maxWidth='desktop'>
            <Grid container rowSpacing={3} justifyContent='center'>
              {members?.map((member, index) => (
                <Grid
                  item
                  alignItems={'center'}
                  mobile={12}
                  tablet={6}
                  laptop={gridSizeList[index] ?? 4}
                  desktop={gridSizeList[index] ?? 4}
                  key={member.name}>
                  <Box onClick={toggleDrawer(member, true)} className={'grow'}>
                    <Stack justifyContent={'center'} alignItems={'center'}>
                      <img
                        src={member.img}
                        alt={member.name}
                        style={{ width: '90%', height: '90%', objectFit: 'cover' }}
                        loading='lazy'
                      />
                      <Stack justifyContent={'center'} alignItems={'center'} spacing={1}>
                        <Typography
                          variant='h4'
                          align='center'
                          sx={{ fontWeight: 'bold', color: 'white.contrastText' }}>
                          {member.name}
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          align='center'
                          sx={{ color: 'white.contrastText' }}
                          dangerouslySetInnerHTML={{ __html: member.title }}></Typography>
                        <Typography
                          variant='subtitle2'
                          align='center'
                          sx={{ color: 'white.dark', fontSize: 14 }}>
                          {member.company}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Stack>
      </Container>
      <SwipeableDrawer
        anchor='right'
        open={bioInfo?.open ?? false}
        onOpen={toggleDrawer(bioInfo, true)}
        onClose={toggleDrawer(bioInfo, false)}
        PaperProps={{
          sx: {
            backgroundColor: 'black.dark',
            color: 'red'
          }
        }}>
        <Box sx={{ width: { laptop: '40vw', desktop: '40vw' } }} role='presentation'>
          <div
            style={{
              backgroundImage: `url('${bioInfo.bioImg || bioInfo.img}')`,
              backgroundPosition: 'center',
              width: '100%',
              height: '60vh',
              position: 'relative',
              backgroundPositionY: 'top',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}>
            <div className='overlay'></div>
            {(!('ontouchstart' in window) || navigator.maxTouchPoints === 0) && (
              <IconButton
                size='large'
                onClick={toggleDrawer(bioInfo, false)}
                sx={{ position: 'absolute', right: 0, top: 0, margin: 2 }}>
                <Cancel sx={{ width: 40, height: 40 }} />
              </IconButton>
            )}
          </div>
          <Stack
            alignItems='start'
            spacing={1}
            paddingX={5}
            sx={{ position: 'relative', top: -50 }}>
            <Typography
              variant='h1'
              align='left'
              sx={{ fontWeight: 'bold', color: 'white.contrastText' }}>
              {bioInfo.bioName}
            </Typography>
            {!isEmpty(bioInfo.bioQualifications) && (
              <Typography variant='h4' align='left' sx={{ color: 'white.contrastText' }}>
                {bioInfo.bioQualifications}
              </Typography>
            )}
            <Typography
              variant='h4'
              align='left'
              sx={{ color: 'white.contrastText' }}
              dangerouslySetInnerHTML={{ __html: bioInfo.title }}></Typography>
            <Typography variant='h4' align='left' sx={{ color: 'white.dark', paddingBottom: 2 }}>
              {bioInfo.company}
            </Typography>
            {bioInfo.bio && <Box sx={{ border: '0.5px white solid', width: '100%' }} />}
            {bioInfo.bio && (
              <Typography
                variant='body1'
                align='justify'
                sx={{
                  color: 'white.contrastText',
                  paddingTop: 2
                }}
                dangerouslySetInnerHTML={{ __html: bioInfo.bio }}></Typography>
            )}
          </Stack>
        </Box>
      </SwipeableDrawer>
    </Fragment>
  );
};
export default PeopleList;
