const styles = {
  background: {
    background: '#F5F5F5',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    pt: '76px',
    pb: '76px',
    '@media (max-width: 768px)': {
      height: '720px',
      pt: '0px',
      pb: '0px',
    }
  },
  heading: {
    fontSize: '36px',
    fontWeight: 700,
    fontFamily: 'Plus Jakarta Sans',
    '@media (max-width: 768px)': {
        fontSize: '27px',
      }
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 700,
    fontFamily: 'Plus Jakarta Sans',
    color: '#0076D6',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  memberContainer: {
    background: 'white',
    display: 'flex',
    maxWidth: '360px',
    maxHeight: '404px',
    minHeight: '355px',
    padding: '40px 36px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    borderRadius: '8px'
  },
  name: {
    overflow: 'hidden',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '28px' /* 175% */,
    letterSpacing: '0.16px'
  },
  title: {
    overflow: 'hidden',
    maxWidth: '280px',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3, // Limits the text to 2 lines
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.14px',
    whiteSpace: 'normal'
  },
  companyName: {
    mt: '4px',
    overflow: 'hidden',
    color: '#0076D6',
    textAlign: 'center',
    textOverflow: 'ellipsis',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '21px' /* 175% */,
    letterSpacing: '0.14px'
  }
};

export default styles;
