import { useNavigate } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material';
import { HomePageAsset1 } from '../../../assets/svg/HomePageLogo';
import * as React from 'react';
import { PlayCircle } from '@mui/icons-material';
//@ts-ignore
import Chinook from '../../../assets/companyLogo/Chinook.png';
//@ts-ignore
import Otsuka from '../../../assets/companyLogo/otsuka.png';
import { VarsityStyles } from './styles/VarsityStyles';

const Varsity = () => {
  const navigate = useNavigate();

  return (
    <Stack
      display='flex'
      justifyContent='space-between'
      minHeight='90vh'
      sx={{
        height: { mobile: '100%', laptop: '90vh' },
        flexDirection: { mobile: 'column', laptop: 'row' }
      }}>
      <Stack
        display='flex'
        direction='column'
        justifyContent='center'
        sx={{
          order: { mobile: 2, laptop: 1 },
          width: { mobile: '100%', laptop: '50%' },
          height: '100%'
        }}>
        <Typography
          variant='h1'
          color='black.main'
          sx={{ width: { mobile: '100%', laptop: '60%' } }}>
          A step closer to your dream
        </Typography>
        <Typography variant='h4' color='black.light' mt={5} mb={5}>
          Accelerate Your Career as an R&D Strategist.
        </Typography>
        <Stack display='flex' direction='row' mt={5} mb={5}>
          <Button
            variant='contained'
            color='primary'
            sx={{
              color: 'white !important',
              p: 2,
              textTransform: 'none',
              borderRadius: 0,
              fontWeight: 500,
              fontSize: 16,
              minWidth: 200,
              mr: 5,
              display: 'flex',
              flexDirection: 'column'
            }}>
            Virtual courses
            <Typography variant='caption'>* coming soon (1 Q 23)</Typography>
          </Button>

          <Button
            variant='text'
            onClick={() => navigate('/resources/ct')}
            fullWidth
            sx={{
              // maxWidth: 200,
              borderRadius: 0,
              border: 0.7,
              borderColor: 'primary.main',
              color: 'black !important',
              textTransform: 'none',
              display: 'flex',
              flexDirection: 'column'
            }}>
            <Stack alignItems='center' display='flex' flexDirection={'row'}>
              <PlayCircle color='primary' fontSize='large' sx={{ mr: 1 }} />
              <Stack>
                <Typography variant='subtitle2' sx={{fontWeight:700}}>Clinical trials enrollment report</Typography>
                <Typography sx={{ ml: 1 }} variant='caption'>
                  Released at WOCIP on Nov.12, 2023
                </Typography>
              </Stack>
            </Stack>
          </Button>
        </Stack>
        <Typography variant='subtitle1' sx={{ textTransform: 'uppercase' }} color='primary.main'>
          Trusted by
        </Typography>
        <Stack display='flex' direction='row' justifyContent='flex-start' mt={2} mb={2}>
          <Box sx={VarsityStyles.companyLogo} p={2} mr={5}>
            <img src={Chinook} alt='chinook logo' width='100%' />
          </Box>
          <Box sx={VarsityStyles.companyLogo} p={2} mr={5}>
            <img src={Otsuka} alt='Otsuka logo' width='100%' />
          </Box>
        </Stack>
      </Stack>
      <Stack
        display='flex'
        justifyContent='center'
        sx={{
          order: { mobile: 1, laptop: 2 },

          margin: { mobile: 'auto', laptop: 'initial' },
          width: { mobile: '60%', laptop: '40%' }
        }}>
        <HomePageAsset1 />
      </Stack>
    </Stack>
  );
};
export default Varsity;
