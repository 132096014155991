import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

const Methodology = () => {
  return (
    <Box>
      <Typography variant='h4'>
        Methodology: The following is a step-by-step by guide on how Vivpro generates meaningful
        insights on the state of race and ethnicity data, shown on this page, from 430K+ clinical
        trials.
      </Typography>
      <Typography variant='subtitle1' pt={2}>
        Step 1: Narrowing down the trials of interest.
      </Typography>
      <Typography pt={0.5}>
        As a first step, we identify the trials of interest by applying various filters to the
        overall clinical trials. In this case, we use only use trials which were industry sponsored,
        started on, or after, 01/01/2011, and have reported baseline characteristics data on
        clinicaltrials.gov.
      </Typography>
      <Typography variant='subtitle1' pt={2}>
        Step 2: Generating key metrics by race and ethnicity status.
      </Typography>
      <Typography pt={0.5}>
        In this step, all the data for trials of interest is processed by
      </Typography>
      <Typography pt={0.5} pl={3}>
        &#8226; Computing proportion of patients by race/ethnicity for each trial,
      </Typography>
      <Typography pt={0.5} pl={3}>
        &#8226; Grouping each of these trials by the start year,
      </Typography>
      <Typography pt={0.5} pl={3}>
        &#8226; Computing a mean ratio for each race/ethnicity by year.
      </Typography>
      <Typography variant='subtitle1' pt={2}>
        Step 3: On demand modifications by providing results based on user's query
      </Typography>
      <Typography pt={0.5}>
        We also take user input, through filters, to further provide customized graphs and insights.
        Users are free to apply any filters provided. This allows users to get more targeted
        results.
      </Typography>
      <Typography pt={4}>
        Please contact us or send feedback for any enhancement needs or additional graphs/insights.
      </Typography>
    </Box>
  );
};

export default React.memo(Methodology);
