import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useNavigate } from 'react-router-dom';
import { DialogActions, Stack, TextField } from '@mui/material';

import { GoogleFormProvider, useGoogleForm, useShortAnswerInput } from 'react-google-forms-hooks';

import form from '../../../scripts/form.json';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ShortAnswerInput = ({ id, defaultValue }: { id: string; defaultValue: string }) => {
  const { register, label } = useShortAnswerInput(id);
  return (
    <TextField
      label={label}
      fullWidth
      required
      variant='outlined'
      defaultValue={defaultValue}
      {...register()}
    />
  );
};

export interface PaymentPayloadInterface {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  paymentStatus: string;
  status: string;
}

interface PaymentDialogProps extends PaymentPayloadInterface {
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMessageOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const PaymentDialog = ({
  firstName,
  lastName,
  email,
  company,
  paymentStatus,
  status,
  dialogOpen,
  setDialogOpen,
  setMessageOpen,
  setMessage
}: PaymentDialogProps) => {
  const navigate = useNavigate();
  const paymentSuccess = paymentStatus === 'paid' && status === 'complete';

  const handleClose = () => {
    setDialogOpen(false);
    navigate('/recon', { replace: true });
  };

  // @ts-ignore
  const methods = useGoogleForm({ form });

  const onSubmit = async (data: any) => {
    try {
      await methods.submitToGoogleForms(data);
      setMessageOpen(true);
      setMessage('Details submitted successfully');
    } catch (e) {
      console.log(e);
      setMessageOpen(true);
      setMessage('There was an error with the form submission\n Please contact admin@vivpro.ai');
    }
    handleClose();
  };

  const getDefaultValue = (label: string) => {
    switch (label) {
      case 'First Name':
        return firstName;
      case 'Last Name':
        return lastName;
      case 'Email':
        return email;
      case 'Company':
        return company;
      default:
        return '';
    }
  };

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby='Registration info'>
      <DialogTitle>
        {paymentSuccess ? 'Thank you for registering for RE-CON 2025' : 'Oops!'}
      </DialogTitle>
      <DialogContent>
        {paymentStatus === 'paid' && status === 'complete' ? (
          <Stack spacing={2}>
            <DialogContentText id='alert-dialog-slide-description' maxWidth={'sm'}>
              {`You will shortly receive an email with the payment confirmation and the event details at ${email}` ||
                'the email address you provided during registration'}
            </DialogContentText>
            <DialogContentText id='alert-dialog-slide-description' maxWidth={'sm'}>
              Please confirm your details
            </DialogContentText>
            <GoogleFormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack
                  display='flex'
                  spacing={4}
                  flexDirection='column'
                  justifyContent='space-evenly'>
                  {form?.fields?.map((field: any) => {
                    if (field.type === 'SHORT_ANSWER') {
                      return (
                        <ShortAnswerInput
                          key={field.id}
                          id={field.id}
                          defaultValue={getDefaultValue(field.label)}
                        />
                      );
                    }
                    return null;
                  })}
                </Stack>
                <Button
                  variant='contained'
                  fullWidth
                  sx={{
                    my: 2,
                    '&:disabled': {
                      backgroundColor: 'white.dark'
                    }
                  }}
                  type='submit'>
                  Submit
                </Button>
              </form>
            </GoogleFormProvider>
          </Stack>
        ) : (
          <DialogContentText id='alert-dialog-slide-description' maxWidth={'sm'}>
            There was a problem with your registration. If you were charged for the payment, please
            contact us at <a href='mailto:admin@vivpro.ai'>admin@vivpro.ai</a>
          </DialogContentText>
        )}
      </DialogContent>
      {!paymentSuccess && (
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PaymentDialog;
