import { styled, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

interface StyledTabProps {
  label: string;
  isVarsity: boolean;
}

const StyledTab = styled(Tab)(({ isVarsity }: StyledTabProps) => {
  const theme = useTheme();
  return {
    textTransform: 'capitalize',
    color: isVarsity ? (theme.palette as any).white.dark : (theme.palette as any).black.main,
    fontWeight: 400,
    fontSize: 14,
    '&.Mui-selected': {
      color: isVarsity ? (theme.palette as any).white.dark : (theme.palette as any).black.dark,
      fontWeight: 700
    }
  };
});

export default StyledTab;
