import React, { useState } from 'react';
import uniqueId from 'lodash/uniqueId';

// mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

// components
import StyledTab from '../../../Component/styledComponents/StyledTab';

// constants
import { eventSchedule, eventScheduleImages, eventSchedulePDF } from '../constant';
import { Box } from '@mui/material';

const EventSchedule = () => {
  const [currentTab, setCurrentTab] = useState('Day 1');
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };
  const showEvent = false;

  return (
    <Container maxWidth='desktop'>
      <Stack alignItems='center' spacing={4} mt={15} sx={{ height: '100%' }}>
        <Typography align='center' sx={{ fontSize: 48, fontWeight: 700, color: 'white.main' }}>
          Agenda
        </Typography>
        <Typography align='left' sx={{ fontSize: 18, fontWeight: 700, color: 'white.main' }}>
          Click{' '}
          <Link href={eventSchedulePDF} target='_blank'>
            here{' '}
          </Link>
          to access the agenda
        </Typography>
        <Tabs value={currentTab} onChange={handleChange} aria-label='event tabs' centered>
          {Object.keys(eventSchedule).map((keyName: string) => {
            return (
              <StyledTab
                key={uniqueId()}
                selected={currentTab === keyName}
                value={keyName}
                label={keyName}
              />
            );
          })}
        </Tabs>
        <Box sx={{ width: { mobile: '100%', desktop: '80%' } }}>
          <img src={eventScheduleImages[currentTab]} alt={currentTab} loading='lazy' width='100%' />
        </Box>

        {showEvent &&
          eventSchedule[currentTab]?.map((eventRow: any) => {
            return (
              <Accordion
                key={uniqueId()}
                expanded={expanded === eventRow.id}
                onChange={handleAccordianChange(eventRow.id)}
                sx={{
                  width: { mobile: '100%', laptop: '100%' },
                  p: { mobile: 0, laptop: 2 },
                  backgroundColor: expanded === eventRow.id ? 'secondary.main' : 'black.dark',
                  borderColor: 'gray.contrastText',
                  borderWidth: 1,
                  borderStyle: 'solid'
                }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ mr: 4, color: 'white.main' }} />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'>
                  {/* accordian heading  */}
                  <Stack
                    direction='row'
                    sx={{ width: '100%', flexDirection: { mobile: 'column', laptop: 'row' } }}
                    justifyContent='space-between'>
                    {/* left side */}
                    <Stack
                      spacing={2}
                      direction='row'
                      sx={{ width: { mobile: '100%', laptop: '50%' } }}>
                      <Typography variant='h1' sx={{ color: 'white.main' }} fontWeight='bold'>
                        {eventRow.dateValue}
                      </Typography>
                      <Stack>
                        <Typography sx={{ color: 'white.main', fontSize: 22, fontWeight: '700' }}>
                          {eventRow.dateMonth}
                        </Typography>
                        <Typography sx={{ color: 'white.contrastText', fontSize: 16 }}>
                          {eventRow.timingsText}
                        </Typography>
                      </Stack>
                    </Stack>
                    {/* right side */}
                    <Stack
                      sx={{
                        mt: { mobile: 2, laptop: 0 },
                        width: { mobile: '100%', laptop: '50%' }
                      }}>
                      <Typography sx={{ color: 'white.main', fontSize: 22, fontWeight: '700' }}>
                        {eventRow.topic}
                      </Typography>
                      <Typography sx={{ color: 'white.contrastText', fontSize: 16 }}>
                        {eventRow.speaker}
                      </Typography>
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Divider variant='fullWidth' light sx={{ backgroundColor: 'white.main' }} />
                  {eventRow.description?.map((textDetails: any) => {
                    return (
                      <Typography key={uniqueId()} sx={{ mt: 2, color: 'white.main' }}>
                        {textDetails.text}
                      </Typography>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Stack>
    </Container>
  );
};
export default EventSchedule;
