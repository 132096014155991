import React, { useState } from 'react';

// mui
import Typography from '@mui/material/Typography';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

// constants
import { faqList } from '../constant';
import styles from './FAQs.styles';
import { Box } from '@mui/material';

const FAQs = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container maxWidth='desktop'>
      <Box sx={styles.box}>
        <Typography sx={styles.title}>Frequently Asked Questions</Typography>
        <Grid container columnSpacing='32px' sx={{ marginTop: '48px' }}>
          {Object.entries(faqList).map(([k, v], index) => (
            <Grid item key={`faqGrid${index}`} laptop={6} desktop={6} mobile={12} tablet={12}>
              {v.map((question: any) => (
                <Accordion
                  key={question.id}
                  expanded={expanded === question.id}
                  onChange={handleAccordianChange(question.id)}
                  sx={{
                    ...styles.accordion,
                    borderColor: expanded === question.id ? '#0076D6' : 'gray.300',
                    borderWidth: expanded === question.id ? '2px' : '1px',
                    '&:hover': {
                      borderColor: expanded === question.id ? '#0076D6' : 'gray.contrastText'
                    },
                  }}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{ color: expanded === question.id ? '#0076D6' : 'gray.800' }}
                      />
                    }
                    aria-controls='panel1bh-content'
                    id='panel1bh-header'>
                    <Typography
                      variant='subtitle2'
                      fontWeight='bold'
                      sx={{
                        color: expanded === question.id ? '#0076D6' : 'gray.800',
                        fontSize: '20px',
                        lineHeight: '30px'
                      }}>
                      {question.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography dangerouslySetInnerHTML={{ __html: question.answer }} />
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
export default FAQs;
