const styles = {
  button: {
    color: 'black.light',
    letterSpacing: '0.02em',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: 'transparent',
      textUnderlineOffset: '.5em',
      textDecoration: 'underline'
    },
    '&.active': {
      color: 'black.main',
      textUnderlineOffset: '.5em',
      textDecoration: 'underline'
    }
  },
  reconButton: {
    color: 'white.dark',
    letterSpacing: '0.02em',
    fontSize: '14px',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'transparent',
      textUnderlineOffset: '.5em',
      textDecoration: 'underline'
    },
    '&.active': {
      color: 'white.main',
      textUnderlineOffset: '.5em',
      textDecoration: 'underline'
    }
  },
  contantUsButton: {
    borderRadius: '50px',
    fontSize: '16px',
    fontWeight: 700,
    height: '40px',
    padding: '10px 16px',
    color: 'white.main',
    backgroundColor: '#0076D6',
    width: 'max-content',
    textTransform: 'none',
    '&:hover': {
      boxShadow: '0 0 20px rgba(0, 198, 168, 0.7)',
      backgroundColor: '#0076D6'
    }
  },
  logoButton : {
    mr: '232px',
    '@media (max-width: 768px)': {
      mr: 0
    }
  }
};

export default styles;
