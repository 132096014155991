import { Box, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import navbarStyles from './Navbar/styles/navbarStyles';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import * as React from 'react';
import { navItems, reconNavItems } from './Navbar/Navbar';
import { LinkedIn, YouTube } from '@mui/icons-material';
import VarsityLogo from '../assets/svg/VarsityLogo';
import ReconLogo from '../assets/svg/ReconLogo';

const Footer = () => {
  const location = useLocation();
  return (
    <Stack
      display={'flex'}
      mt={location.pathname.startsWith('/resources/ct') ? 0 : 5}
      mr={location.pathname.startsWith('/resources/ct') ? 5 : 0}
      justifyContent={'flex-end'}
      ml={location.pathname.startsWith('/resources/ct') ? 5 : 0}
      flexDirection='column'
      sx={
        location.pathname.startsWith('/resources/ct')
          ? { border: 'none' }
          : {
              borderTop: '.5px solid',
              borderColor: 'black.light'
            }
      }>
      <Stack
        mt={5}
        display={'flex'}
        sx={{ flexDirection: { mobile: 'column', laptop: 'row' } }}
        justifyContent='space-between'
        alignItems='center'>
        <Box width={200}>
          {location.pathname.startsWith('/recon') ? <ReconLogo /> : <VarsityLogo />}
          {/* <VarsityLogo /> */}
        </Box>
        <Box
          sx={{
            ml: 5,
            display: { mobile: 'none', laptop: 'flex', justifyContent: 'space-evenly' }
          }}>
          {!location.pathname.startsWith('/recon') &&
            navItems.map(({ name, link }) => (
              <Button
                key={name}
                sx={navbarStyles.button}
                component={RouterLink}
                end
                to={link}
                disableElevation
                disableRipple>
                {name}
              </Button>
            ))}
          {location.pathname.startsWith('/recon') &&
            reconNavItems.map(({ name, link }) => (
              <Button
                key={name}
                sx={navbarStyles.reconButton}
                component={RouterLink}
                end
                to={link}
                disableElevation
                disableRipple>
                {name}
              </Button>
            ))}
        </Box>
        <Stack display='flex' direction='row'>
          <Box>
            <Tooltip title='LinkedIn'>
              <IconButton
                onClick={() =>
                  window.open('https://www.linkedin.com/company/vivpro-corp', '_blank')
                }>
                <LinkedIn
                  sx={{
                    color: location.pathname.startsWith('/recon') ? 'white.main' : 'black.main'
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip title='Youtube'>
              <IconButton
                onClick={() =>
                  window.open('https://www.youtube.com/channel/UCcz6BAzkxeY1OlSGC5fg38A', '_blank')
                }>
                <YouTube
                  sx={{
                    color: location.pathname.startsWith('/recon') ? 'white.main' : 'black.main'
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </Stack>
      <Stack direction='row' justifyContent='space-between' my={2}>
        <Typography
          variant='body1'
          sx={{
            color: location.pathname.startsWith('/recon') ? 'white.main' : 'black.main'
          }}>
          © {new Date().getFullYear()} Vivpro.
        </Typography>
        {location.pathname.startsWith('/recon') && (
          <Stack direction='row' spacing={1}>
            {location.pathname === '/recon' ||
              (location.pathname.startsWith('/recon/') && (
                <Typography sx={{ color: 'white.main' }}>Access previous events:</Typography>
              ))}
            {location.pathname === '/recon' || location.pathname.startsWith('/recon/') ? (
              <Link href='/recon2023' sx={{ color: 'primary.main' }}>
                2023 RE-CON
              </Link>
            ) : (
              <Link href='/recon' sx={{ color: 'primary.main' }}>
                Back to RE-CON
              </Link>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
export default Footer;
