import React, { useCallback, useState } from 'react';
import { getTrialsData } from '../../../api/ct-data';
import { getFilters } from '../utils';

const useCTData = () => {
  const [isLoading, setLoading]: [boolean, Function] = useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState('');
  const [responseData, setResponseData]: [any, Function] = useState({});

  const getCTData = useCallback(async (cnf: string, dateRange: any = {}) => {
    setResponseData({});
    setLoading(true);
    try {
      setErrorMessage('Could not get the graph data');
      // If the trials data not present throw the error
      let payload: any = {};
      if (cnf.length > 0 || Object.keys(dateRange).length > 0) {
        payload = {
          use_synonyms: true,
          query: cnf,
          filters: dateRange
        };
      }

      // If cnf empty, dont send it in the paload
      if (cnf === '') {
        delete payload.query;
      }
      // If dateRange is empty, dont use it in filters
      if (Object.keys(dateRange).length === 0) {
        delete payload.filters;
      }
      
      const trialsData = await getTrialsData(payload);
      const start = Date.now();

      console.debug('started'); // Will be removed later after testing
      const filters = getFilters(trialsData.nct_info);
      console.debug(Date.now() - start); // Will be removed later after testing
      setResponseData({ insights: trialsData.insights, graphData: trialsData.graph_data, filters });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setErrorMessage('Could not get the graph data');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    isLoading,
    errorMessage,
    responseData,
    getCTData
  };
};

export default useCTData;
