import React, { useCallback, useState } from 'react';
import { getTrialsCountAPI } from '../../../api/ct-data';

const useWordCloud = () => {
  const [isLoading, setLoading]: [boolean, Function] = useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState('');
  const [count, setCount]: [any, Function] = useState(localStorage.getItem('total_trials') || '');

  const getTrialsCount = useCallback(async () => {
    setCount('');
    setLoading(true);
    try {
      setErrorMessage('Could not get the Word Cloud');

      const response = await getTrialsCountAPI();
      localStorage.setItem('total_trials', response.total_trials || '')
      setCount(response.total_trials);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setErrorMessage('Could not get the Word Cloud');
      setLoading(false);
    } finally {
      setLoading(true);
    }
  }, []);

  return {
    isLoading,
    errorMessage,
    count,
    getTrialsCount
  };
};

export default useWordCloud;
