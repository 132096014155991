import * as React from 'react';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import styles from './contact.styles';
import IconButton from '@mui/material/IconButton';
import {
  Alert,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  Snackbar,
  TextField
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { BASE_URL } from '../../../api/ct-data';

const ContactForm = ({ handleClose }: any) => {
  const [message, setMessage] = useState('');
  const [messageOpen, setMessageOpen] = useState(false);
  const initialValues = {
    name: '',
    email: '',
    message: '',
    company: ''
  };
  const handleCloseSnackbar = () => {
    setMessageOpen(false);
    setMessage('');
  };
  return (
    <Stack display='flex' alignItems='center' flexDirection='column' justifyContent='center'>
      <DialogTitle p='0 !important'>
        <Typography variant='h2' color='black.main' sx={styles.formHeading}>
          Let's connect!
        </Typography>
        <Divider sx={styles.divider} />
        <IconButton title='Close' onClick={handleClose} sx={styles.advanceSearchCloseIcon}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ ...initialValues }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Name is required'),
            email: Yup.string().email('Email is invalid').required('Email is required'),
            message: Yup.string(),
            company: Yup.string().required('Company is required')
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            axios
              .post(`${BASE_URL}/email-service/contact`, values)
              .then(res => {
                setMessage('Message sent successfully');
                setMessageOpen(true);
                resetForm();
                setSubmitting(false);
              })
              .catch(err => {
                setMessage('Message failed to send');
                setMessageOpen(true);
                setSubmitting(false);
              });
          }}>
          {({
            errors,
            touched,
            values,
            handleChange,
            handleBlur,
            isValid,
            dirty,
            handleSubmit
          }) => (
            <form onSubmit={handleSubmit}>
              <Stack
                display='flex'
                flexDirection='column'
                justifyContent='space-evenly'
                sx={{ height: 500, width: { mobile: '100%', laptop: '500px' } }}>
                <TextField
                  fullWidth
                  required
                  id='name'
                  name='name'
                  label='Name'
                  variant='outlined'
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  sx={styles.textField}
                />
                <TextField
                  fullWidth
                  required
                  id='email'
                  name='email'
                  label='Email'
                  variant='outlined'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={styles.textField}
                />
                <TextField
                  fullWidth
                  required
                  id='company'
                  name='company'
                  label='Company'
                  variant='outlined'
                  value={values.company}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.company && Boolean(errors.company)}
                  helperText={touched.company && errors.company}
                  sx={styles.textField}
                />
                <TextField
                  fullWidth
                  id='message'
                  name='message'
                  label='Comment/Question'
                  variant='outlined'
                  multiline
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.message && Boolean(errors.message)}
                  helperText={touched.message && errors.message}
                  minRows={4}
                  sx={styles.textField}
                />
                <Button
                  variant={'contained'}
                  type='submit'
                  disabled={!(isValid && dirty)}
                  sx={styles.submitButton}>
                  Submit
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
        <Snackbar
          open={messageOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          sx={{ width: { mobile: '100%', laptop: '30%' } }}>
          <Alert onClose={handleCloseSnackbar} severity='success' sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </DialogContent>
    </Stack>
  );
};

export default ContactForm;
