import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { Add } from '@mui/icons-material';
import { HomePageAsset2 } from '../../../assets/svg/HomePageLogo';
import { valueData } from '../constants';

const CustomAccordion = ({ number, title, value }: { number: string, title: string, value: string }) => {
  return (<Accordion elevation={0} sx={{ minHeight: 90 }}>
    <AccordionSummary
      expandIcon={<Add />}
      aria-controls='panel1a-content'
      id='panel1a-header'
    >
      <Typography variant='h3' color='black.light' sx={{ width: 80, flexShrink: 0 }}>
        {number}
      </Typography>
      <Typography variant='h3' color='black.main'>{title}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Typography variant='body1' color='black.light' ml={10}>
        {value}
      </Typography>
    </AccordionDetails>
  </Accordion>);
};
const Value = () => {

  return (<Stack display='flex' justifyContent='space-between' mt={10}
                 sx={{ flexDirection: { mobile: 'column', laptop: 'row' } }}>
      <Stack display='flex'
             sx={{ width: { mobile: '100%', laptop: '35%' }, flexDirection: 'column' }}>

        <Typography variant='subtitle1' color='primary.main' sx={{ textTransform: 'uppercase' }}>
          Value
        </Typography>
        <Stack display='flex' mt={2}>
          <Stack display='flex'
                 sx={{ order: { mobile: 2, laptop: 1 } }}
          >

            <Typography variant='h2' color='black.main'>
              Why Vivpro Varsity?
            </Typography>
            <Typography variant='body1' color='black.light' mt={5}>
              Vivpro Varsity provides cutting-edge virtual courses, live events, and free content to help you on your way to becoming a professional R&D strategist.
            </Typography>
          </Stack>
          <Stack display='flex'
                 sx={{
                   order: { mobile: 1, laptop: 2 },
                   mt: { mobile: 1, laptop: 5 },
                   width: { mobile: '50%', laptop: '80%' },
                   margin: { mobile: 'auto auto 2em auto', laptop: '3em 0 0 0' }
                 }}
          >
            <HomePageAsset2 />
          </Stack>


        </Stack>

      </Stack>
      <Stack display='flex'
             sx={{ width: { mobile: '100%', laptop: '55%' }, mt: { mobile: 5, laptop: 0 }, flexDirection: 'column' }}>

        {valueData.map((item, index) => (
          <CustomAccordion key={index} number={item.number} title={item.title} value={item.value} />))}

      </Stack>

    </Stack>

  );
};
export default Value;
