import * as React from 'react';
import { useState } from 'react';
import { Alert, Button, Snackbar, Stack, TextField, Typography, Box } from '@mui/material';
import Container from '@mui/material/Container';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { BASE_URL } from '../../../api/ct-data';
import styles from './Form.styles';

const Contact = () => {
  const [message, setMessage] = useState('');
  const [messageOpen, setMessageOpen] = useState(false);
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    purpose: '',
    message: ''
  };
  const handleCloseSnackbar = () => {
    setMessageOpen(false);
    setMessage('');
  };
  return (
    <Box pt={5} pb={5} sx={{ bgcolor: 'primary.main' }}>
      <Container maxWidth='desktop'>
        <Typography
          variant='h1'
          textAlign='center'
          color='white.main'
          sx={{ fontWeight: 700, letterSpacing: 1 }}>
          Book Your Seat Now
        </Typography>
        <Stack mt={4} p={{ mobile: 4, laptop: 8 }} sx={{ bgcolor: 'black.dark' }}>
          <Formik
            initialValues={{ ...initialValues }}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required('First Name is required'),
              lastName: Yup.string().required('Last Name is required'),
              email: Yup.string().email('Email is invalid').required('Email is required'),
              purpose: Yup.string(),
              message: Yup.string().required('Bio is required')
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              axios
                .post(`${BASE_URL}/email-service/recon-bookings`, values)
                .then(res => {
                  setMessage('Message sent successfully');
                  setMessageOpen(true);
                  resetForm();
                  setSubmitting(false);
                })
                .catch(err => {
                  console.log(err);
                  setMessage('Message failed to send');
                  setMessageOpen(true);
                  setSubmitting(false);
                });
            }}>
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              isValid,
              dirty,
              handleSubmit
            }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={4} direction={{ mobile: 'column', laptop: 'row' }}>
                  <Stack
                    display='flex'
                    spacing={4}
                    flexDirection='column'
                    justifyContent='space-evenly'
                    sx={{
                      width: { mobile: '100%', laptop: '50%' }
                    }}>
                    <TextField
                      fullWidth
                      required
                      id='firstName'
                      name='firstName'
                      sx={styles.textField}
                      label='First name'
                      variant='outlined'
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                    <TextField
                      fullWidth
                      required
                      id='lastName'
                      name='lastName'
                      sx={styles.textField}
                      label='Last Name'
                      variant='outlined'
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                    <TextField
                      fullWidth
                      required
                      id='email'
                      name='email'
                      sx={styles.textField}
                      label='Email Address'
                      variant='outlined'
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    <TextField
                      fullWidth
                      required
                      id='purpose'
                      name='purpose'
                      sx={styles.textField}
                      label='Company'
                      variant='outlined'
                      value={values.purpose}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.purpose && Boolean(errors.purpose)}
                      helperText={touched.purpose && errors.purpose}
                    />
                  </Stack>
                  <Stack spacing={2} sx={{ width: { mobile: '100%', laptop: '50%' } }}>
                    <TextField
                      fullWidth
                      required
                      multiline
                      rows={13}
                      id='message'
                      inputProps={{ style: { color: '#CCCCCC' } }}
                      name='message'
                      sx={{ ...styles.textField, bgcolor: 'black.main' }}
                      label='Bio (up to 500 characters)'
                      variant='outlined'
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.message && Boolean(errors.message)}
                      helperText={touched.message && errors.message}
                    />
                  </Stack>
                </Stack>
                <Stack
                  mt={{ mobile: 2, laptop: 2 }}
                  spacing={4}
                  alignItems={{ mobile: 'flex-start', laptop: 'center' }}
                  direction={{ mobile: 'column', laptop: 'row' }}>
                  <Button
                    variant='contained'
                    sx={{
                      mt: 2,
                      width: { mobile: '100%', laptop: '20%' },
                      '&:disabled': {
                        backgroundColor: 'white.dark'
                      }
                    }}
                    type='submit'
                    disabled>
                    Register Now
                  </Button>
                  <Stack spacing={1}>
                    <Typography
                      variant='body1'
                      sx={{ color: 'white.main', width: { mobile: '100%', laptop: '100%' } }}>
                      Regular Registration: $750 | Academic and Government Employees: $100
                    </Typography>
                    <Typography
                      variant='body1'
                      sx={{ color: 'white.main', width: { mobile: '100%', laptop: '100%' } }}>
                      An invoice will be sent to the email address used upon registration.
                    </Typography>
                  </Stack>
                </Stack>
              </form>
            )}
          </Formik>
          <Snackbar
            open={messageOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            sx={{ width: { mobile: '100%', laptop: '30%' } }}>
            <Alert onClose={handleCloseSnackbar} severity='success' sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
        </Stack>
      </Container>
    </Box>
  );
};
export default Contact;
