
const styles: { [key: string]: any } = {
    backgroundContainer: {
        background: 'black',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '400px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItem: 'center'
    },
    heading: {
        background: 'linear-gradient(to right, #5EC0FF 0%, #33D6E5 30%, #31DCC4 50%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
        fontSize: '24px',
        fontWeight: 600
    },
    content: {
        fontSize: '16px',
        fontWeight: 400,
        color: 'white.main'
    },
    contactusText: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#2DAD9B',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    link : { color: '#5EC0FF', textDecoration: 'none' },
    divider: {    
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#9E9E9E',
        borderRadius: '1px', margin: '10px', 
    }
};

export default styles;