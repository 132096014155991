import React, { useState, useEffect, useCallback } from 'react';

import FormGroup from '@mui/material/FormGroup';
import { Virtuoso } from "react-virtuoso";
import CheckBox from './CheckBox';
import FiltersTextField from './FiltersTextField';

const AutoSearchOptions = ({ searchOptions, handleCheckboxChange }: any) => {
  const [filterOptions, setFilterOptions] = useState<Array<any>>();
  
  useEffect(() => {
    setFilterOptions(searchOptions);
  }, [searchOptions]);

  const handleFilterOptionsChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const filteredOptions: React.SetStateAction<any[] | undefined> = [];
    const searchKey = event.target.value;

    if (searchKey !== '') {
      searchOptions?.forEach((option: any) => {
        if (option.value.toLowerCase().includes(searchKey)) {
          filteredOptions.push(option);
        }
      });
      setFilterOptions(filteredOptions);
    } else {
      setFilterOptions(searchOptions);
    }
  }, [searchOptions]);

  
  return (
    <FormGroup sx={{ padding: 2 }}>
      <FiltersTextField
        placeholder='Search here'
        variant='outlined'
        onChange={handleFilterOptionsChange}
      />
       <Virtuoso
        style={{ height: '300px', width: '250px' }}
        overscan={700}
        totalCount={filterOptions?.length}
        itemContent={(index: any) => 
          <CheckBox
            key={filterOptions?.[index].value}
            item={filterOptions?.[index]}
            onChangeFn={handleCheckboxChange}
        />
        }
      />
    </FormGroup>
  );
};

export default React.memo(AutoSearchOptions);
