import { cloudfrontRecon2025Url } from '../constant';

const backgroundImage = `${cloudfrontRecon2025Url}/reconBackground.jpg`;
const styles: { [key: string]: any } = {
  banner: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '650px',
    pt: '105px',
    display: 'flex',
    maxWidth: '100%',
    justifyContent: 'center',
    '@media (max-width: 768px)': {
      pt: '0px'
    },
    '@media (max-width: 1280px)': {
      height: '450px',
    }
  },
  heading: {
    fontSize: '40px',
    fontFamily: 'Plus Jakarta Sans',
    fontWeight: 700,
    background: 'linear-gradient(180deg, #FF7300 0%, #DB5800 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    '@media (max-width: 768px)': {
      fontSize: '30px',
    },
  },
  title: {
    fontSize: '40px',
    color: 'gray.900',
    fontWeight: 600,
    '@media (max-width: 768px)': {
      fontSize: '16px',
    },
  },
  description: {
    fontSize: '16px',
    color: 'gray.900',
    fontWeight: 400,
    lineHeight: '130%',
    letterSpacing: '0.16px',
    '@media (max-width: 768px)': {
      fontSize: '12px',
    },
  },
  dateText: {
    mt: '4px',
    fontSize: '18px',
    lineHeight: '28px',
    color: 'gray.900',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      fontSize: '12px'
    },
  },
  locationText: {
    fontSize: '18px',
    lineHeight: '28px',
    color: 'gray.900',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    mb: '4px',
    mt: '20px',
    '@media (max-width: 768px)': {
      fontSize: '12px',
      mt: '10px',
      mb: '0px'
    },
  },
  box: { 
    display: 'flex', 
    flexDirection: 'column', 
    width: '529px',
    padding: '32px 40px',
    alignItems: 'flex-start',
    borderRadius: '16px',
    background: 'linear-gradient(119deg, #FFF 38.87%, #E9E9E9 98.89%)',
    boxShadow: '0px 4px 34.7px 0px rgba(0, 0, 0, 0.25)',
    '@media (max-width: 768px)': {
      px: '20px',
    }
  },
  divider: { 
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'row',     
    height: '6px',
    background: '#FF7300', 
    width: '100%'
  },
  agendaContainer: {
    height: '120px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 768px)': {
      pt: '0px',
      height: '100%',
    },
  },
  agenda: {
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'row',    
    width: '1440px',
    justifyContent: 'space-between' ,
    '@media (max-width: 1280px)': {
      maxWidth: '1200px',
      flexDirection: 'column'
    }
  },
  agendaBox : {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    height: '120px',
    maxWidth: '303px',
  },
  agendaTitle: {
    color: '#E16500',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '150%'
  },
  agendaSubTitle: {
    color: '#1A1A1A',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '150%'
  },
  agendaDivider : {
    color: '#BDBDBD',
    marginTop: '12px',
    borderWidth: '1px',
    height: '96px',
    ml: '20px',
    '@media (max-width: 768px)': {
      height: '0px',
      borderWidth: '0px',
    }
  }
};

export default styles;