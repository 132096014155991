import React, { useState } from 'react';

// mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

// constants
import { faqList } from '../constant';

const FAQs = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleAccordianChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container maxWidth='desktop'>
      <Stack spacing={4} mt={20} mb={10} sx={{ height: '100%' }}>
        <Typography align='center' sx={{ fontSize: 48, fontWeight: 700, color: 'white.main' }}>
          FAQs
        </Typography>

        <Grid container spacing={{ mobile: 0, laptop: 2 }}>
          {Object.entries(faqList)?.map(([k, v]: any, index) => {
            return (
              <Grid item key={`faqGrid${index}`} laptop={6} desktop={6} mobile={12} tablet={12}>
                {v?.map((question: any) => {
                  return (
                    <Accordion
                      key={question.id}
                      expanded={expanded === question.id}
                      onChange={handleAccordianChange(question.id)}
                      sx={{
                        mt: 2,
                        width: { mobile: '100%', laptop: '100%' },
                        backgroundColor: expanded === question.id ? 'primary.dark' : 'black.dark',
                        borderColor: 'primary.dark',
                        borderWidth: 1,
                        //   borderRadius: 2,
                        borderStyle: 'solid',
                        '&:hover': {
                          backgroundColor: 'primary.dark'
                        },
                        '& a': { color: 'orange.main', '&:hover': { color: 'red.light' } }
                      }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: 'white.main' }} />}
                        aria-controls='panel1bh-content'
                        id='panel1bh-header'>
                        {/* accordian heading  */}
                        <Typography
                          variant='subtitle2'
                          sx={{ color: 'white.main' }}
                          fontWeight='bold'>
                          {question.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Divider variant='fullWidth' light sx={{ backgroundColor: 'white.main' }} />
                        <Typography
                          sx={{ mt: 2, color: 'white.main' }}
                          dangerouslySetInnerHTML={{ __html: question.answer }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Container>
  );
};
export default FAQs;
