import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// muiv5
import Stack from '@mui/material/Stack';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

// styled component
import HeadlinerTab from '../styledComponents/HeadlinerTab';

const Headliner = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (location.pathname.startsWith('/recon')) {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [location.pathname]);

  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      if (newValue === 0) {
        navigate('/');
      } else {
        navigate('/recon');
      }
    },
    [navigate]
  );

  const handleHyperlinkText = React.useCallback(() => {
    console.log('TERW');
    if (location.pathname.startsWith('/recon')) {
      navigate('/');
    } else {
      navigate('/recon');
    }
  }, [navigate, location.pathname]);

  return (
    <Stack sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Container maxWidth='desktop'>
        <Stack
          direction='row'
          alignItems='center'
          sx={{ justifyContent: { mobile: 'center', laptop: 'space-between' } }}>
          <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
            <HeadlinerTab
              label='VARSITY'
              value={0}
              isVarsity={location.pathname.startsWith('/recon2') === true}
            />
            <HeadlinerTab
              label='RE-CON'
              value={1}
              isVarsity={location.pathname.startsWith('/recon2') === true}
            />
          </Tabs>
          <Stack spacing={2} direction='row' sx={{ display: { mobile: 'none', laptop: 'flex' } }}>
            <Typography
              variant='body1'
              sx={{
                fontSize: 14,
                color: location.pathname.startsWith('/recon2') ? 'white.dark' : 'black.main'
              }}>
              {location.pathname.startsWith('/recon')
                ? 'Sign up for Certification courses on Vivpro Varsity and move one stop closer to your dream'
                : 'Register for annual conference and hands-on workshop on Jan 27-28, 2025 at Baltimore, MD'}
            </Typography>
            <Typography
              variant='body1'
              onClick={handleHyperlinkText}
              sx={{
                fontSize: 14,
                color: location.pathname.startsWith('/recon2') ? 'orange.main' : 'secondary.main',
                textDecoration: 'underline',
                cursor: 'pointer'
              }}>
              Learn more
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

export default Headliner;
