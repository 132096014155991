import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React from 'react';

const GraphTitle = ({ title }: { title: string }) => {
  return (
    <Box
      display='inline-block'
      p={1}
      margin='auto'
      alignItems='center'
      sx={{
        textAlign: 'center'
      }}>
      <Typography
        sx={{
          color: 'primary.main',
          fontWeight: 'bold',
          fontSize: { tablet: 14, desktop: 16 }
        }}>
        {title}
      </Typography>
    </Box>
  );
};

export default React.memo(GraphTitle);
