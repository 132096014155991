import { createSvgIcon } from '@mui/material';

const LocationIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_1647_92)">
        <path d="M17.5 8.3335C17.5 14.1668 10 19.1668 10 19.1668C10 19.1668 2.5 14.1668 2.5 8.3335C2.5 6.34437 3.29018 4.43672 4.6967 3.0302C6.10322 1.62367 8.01088 0.833496 10 0.833496C11.9891 0.833496 13.8968 1.62367 15.3033 3.0302C16.7098 4.43672 17.5 6.34437 17.5 8.3335Z" stroke="url(#paint0_linear_1647_92)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10 10.8335C11.3807 10.8335 12.5 9.71421 12.5 8.3335C12.5 6.95278 11.3807 5.8335 10 5.8335C8.61929 5.8335 7.5 6.95278 7.5 8.3335C7.5 9.71421 8.61929 10.8335 10 10.8335Z" stroke="url(#paint1_linear_1647_92)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
        <linearGradient id="paint0_linear_1647_92" x1="10" y1="0.833496" x2="10" y2="19.1668" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF7300"/>
        <stop offset="1" stop-color="#DB5800"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1647_92" x1="10" y1="5.8335" x2="10" y2="10.8335" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF7300"/>
        <stop offset="1" stop-color="#DB5800"/>
        </linearGradient>
        <clipPath id="clip0_1647_92">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
    </defs>
    </svg>,
  'LocationIcon'
);

export default LocationIcon;
