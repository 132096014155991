import { useCallback, useState } from 'react';
import { getWordCloud } from '../../../api/ct-data';
import { getRaceWordCloudData } from '../utils';

const useWordCloud = () => {
  const [isLoading, setLoading]: [boolean, Function] = useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState('');
  const [wordCloudUrl, setWordCloudUrl]: [any, Function] = useState(null);

  const getWordCloudImage = useCallback(async (graphData: any) => {
    setWordCloudUrl(null);
    setLoading(true);
    try {
      setErrorMessage('');
      const payload = getRaceWordCloudData(graphData);
      const response = await getWordCloud(payload);
      setWordCloudUrl(response);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setErrorMessage('Could not get the Word Cloud');
      setLoading(false);
    } finally {
      setLoading(true);
    }
  }, []);

  return {
    isLoading,
    errorMessage,
    wordCloudUrl,
    getWordCloudImage
  };
};

export default useWordCloud;
