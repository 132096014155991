import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

const InfoSection = ({
  title,
  text1,
  text2,
}: {
  title: string;
  text1: string;
  text2: string;
}) => {
  return (
    <section style={{ backgroundColor: "#5441df", backgroundImage: 'url(/assets/bg.svg)' }}>
      <Box
        margin='auto'
        sx={{
          textAlign: "center",
          color: "white.main",
          width: { mobile: "90%", desktop: '60%'}
        }}
      >
        <Box padding={2.5}>
          <Typography variant="h2" fontWeight='bold' color="white.main">{title}</Typography>
        </Box>
        <Box padding={2.5}>
          <Typography variant="subtitle2">{text1}</Typography>
        </Box>
        <Box p={1} pl={10} pr={10} pb={2.5}>
          <Typography variant="subtitle2">{text2}</Typography>
        </Box>
      </Box>
    </section>
  );
};

export default React.memo(InfoSection);
