// mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// constants
import styles from './styles';
import { Divider, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FooterSection = () => {
  const navigate = useNavigate();
  return (
    <Stack sx={styles.backgroundContainer}>
      <Stack mt='60px' alignItems='center'>
        <img src={'https://vivpro-site.s3.amazonaws.com/Recon/2025/reconLogo.png'} alt='logo'/>
      </Stack>
      <Stack mt='24px' mb='24px' alignItems='center' display='flex' justifyContent='center'>
        <Typography sx={styles.content}>Jan 27-28, 2025</Typography>
        <Typography mt='12px' sx={styles.content}>
          University of Maryland, Baltimore, MD
        </Typography>
        <Typography
          mt='12px'
          sx={styles.contactusText}
          onClick={() => {
            navigate('/recon/contactus');
          }}>
          Contact Us
        </Typography>
      </Stack>
      <Stack
        alignItems='center'
        display='flex'
        flexDirection='row'
        justifyContent='center'
        mb='25px'>
        <Link href='/recon2023' sx={styles.link}>
          Recon 2023
        </Link>
        <Divider orientation='vertical' variant='middle' sx={styles.divider} />
        <Link href='/recon2024' sx={styles.link}>
          Recon 2024
        </Link>
      </Stack>
      <Divider
        orientation='horizontal'
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#9E9E9E',
          width: '100%',
          mt: '25px',
          mb: '25px'
        }}
      />
      <Stack mb='25px' alignItems='center' display='flex' justifyContent='center'>
        <Typography sx={styles.content}>Copyright 2024 Vivpro. All rights reserved.</Typography>
      </Stack>
    </Stack>
  );
};

export default FooterSection;
