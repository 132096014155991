import { styled, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';

interface StyledTabProps {
  selected: boolean;
}

const StyledTab = styled(Tab)(({ selected }: StyledTabProps) => {
  const theme = useTheme();
  return {
    textTransform: 'capitalize',
    color: (theme.palette as any).gray.contrastText,
    fontWeight: 700,
    fontSize: 24,
    '&.Mui-selected': {
      color: (theme.palette as any).white.main
    }
  };
});

export default StyledTab;
