export const certificationStyles = {
  mainButton: {
    border: '1px solid #333333',
    width: { mobile: '100%', laptop: '45%' },
    height: 300,
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'primary.main',
      '&>h2': { color: 'primary.main' },
      '&>div>button': { color: 'primary.main' }
    }
  }


};
