export const BASE_URL = process.env.REACT_APP_API_URL;

export const getTrialsData = (payload: any) => {
  return fetch(`${BASE_URL}/ct-resources/enrollment-variation`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  }).then((response: Response) => response.json());
};

export const getWordCloud = (payload: any) => {
  return fetch(`${BASE_URL}/ct-resources/dynamic-wordcloud`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then((response: Response) => response.blob())
    .then(imageBlob => URL.createObjectURL(imageBlob));
};

export const getTrialsCountAPI = () => {
  return fetch(`${BASE_URL}/ct-resources/trials-of-interest-count`, {
    method: 'GET'
  }).then((response: Response) => response.json());
};

export const getRaceVariationAPIData = (payload: any) => {
  return fetch(`${BASE_URL}/ct-resources/race-variation`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  }).then((response: Response) => response.json());
};
