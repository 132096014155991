export const bannerDetails: { [key: string]: any } = {
  heading: 'Setting R&D Strategy:',
  subtitle: 'Role of Intelligence',
  date: '23-24 January 2023',
  location: 'University of Maryland, Baltimore, MD'
};

export const videoDetails: { [key: string]: any } = {
  videoLink: 'https://d1fziiyze1kdfo.cloudfront.net/Recon/2023/ReconFinal.mp4',
  videoDescription: [
    '<b>Learn</b> about innovative drug development approaches to build a winning strategy by using internal and external intelligence',
    '<b>Inspire</b> by showcasing case studies',
    '<b>Practice</b> with hands-on workshop'
  ]
};

export const eventInNumbers: { [key: string]: any } = {
  Days: 2,
  Speakers: 20,
  Tracks: 4,
  'Hands on sessions': 2
};

export const eventSchedule: { [key: string]: any } = {
  'Day 1': [
    {
      id: 'registrationStarts',
      dateValue: '23',
      dateMonth: 'January',
      timingsText: 'Monday 7AM - 7:30AM',
      topic: 'Registration Opens',
      speaker: ``,
      description: []
    },
    {
      id: 'breakfast',
      dateValue: '23',
      dateMonth: 'January',
      timingsText: 'Monday 7AM - 8AM',
      topic: 'Continental Breakfast will be provided',
      speaker: ``,
      description: []
    },
    {
      id: '1',
      dateValue: '23',
      dateMonth: 'January',
      timingsText: 'Monday 8AM - 9AM',
      topic: 'Opening Session',
      speaker: `Peter Stein - Director of CDER's Office of New Drugs (OND), FDA`,
      description: [
        {
          text: 'Format: Keynote and Panel:'
        },
        {
          text: 'Opportunities and Challenges in Creating a Winning Strategy from patient and drug developer viewpoint'
        }
      ]
    },
    {
      id: '2',
      dateValue: '23',
      dateMonth: 'January',
      timingsText: 'Monday 9AM - 10:30AM',
      topic: 'Rare Diseases Track',
      speaker: 'Kevin Carl - Global Head, Program Strategy & Planning at Novartis',
      description: [
        {
          text: 'Lean Drug Development Strategy for Rare Diseases'
        },
        {
          text: 'Speakers: '
        },
        {
          text: '- Oliver Jung, Global Program Head, Established Products and Rare Diseases Oncology, Novartis'
        },
        {
          text: 'FDA Accelerated Approval Based on Real World Evidence (RWE): Leveraging RWE to bring a new treatment to patients with an unmet need.'
        },
        {
          text: '- Valerie Fauvelle, Senior VP, Regulatory Affairs, Chinook Therapeutics'
        },
        {
          text: 'Challenges and Opportunities in Building Lean Drug Development Strategies for Rare Diseases.'
        }
      ]
    },
    {
      id: '3',
      dateValue: '23',
      dateMonth: 'January',
      timingsText: 'Monday 11AM - 12:30PM',
      topic: 'Oncology Track',
      speaker: 'Akitunde Bello - VP, BMS',
      description: [
        {
          text: 'Accelerating Drug Development for Oncology'
        },
        {
          text: 'Speakers:'
        },
        {
          text: '- Fan Wu, Director of Clinical Pharmacology and Pharmacometrics, Bristol Myers Squibb'
        },
        {
          text: ' Clinical Dose Findings for Ide-Cel (Abecma®) from a Clinical Pharmacology Perspective.'
        },
        {
          text: '- Peter Morcos, Senior Director of Clinical Pharmacology, Bayer Pharmaceuticals'
        },
        { text: 'TBD' }
      ]
    },
    {
      id: 'lunch',
      dateValue: '23',
      dateMonth: 'January',
      timingsText: 'Monday 12:30PM - 2PM',
      topic: 'Lunch buffet will be provided',
      speaker: ``,
      description: []
    },
    {
      id: '4',
      dateValue: '23',
      dateMonth: 'January',
      timingsText: 'Monday 2PM - 4PM',
      topic: 'Hands on strategy workshop',
      speaker: 'Joga Gobburu - Professor at University of Maryland',
      description: [
        {
          text: 'Use the skills learned from each track to solve case studies with colleagues.'
        }
      ]
    },
    {
      id: '5',
      dateValue: '23',
      dateMonth: 'January',
      timingsText: 'Monday 6:30PM-9:30PM',
      topic: 'Social Event',
      speaker: 'For all attendees',
      description: [
        {
          text: 'Please join us for Drinks and Dinner at Rusty Scupper Restaurant.'
        },
        {
          text: 'Re-Con 2023 Social Gathering will take place at 402 Key Hwy, Baltimore, MD 21230. At the topside lounge. '
        },
        { text: 'Please RSVP by January 16th, 2023 by emailing Holly Machain at holly@vivpro.ai' },
        { text: 'Upon RSVP, please specify any dietary requirements.' }
      ]
    }
  ],
  'Day 2': [
    {
      id: 'breakfastDay2',
      dateValue: '24',
      dateMonth: 'January',
      timingsText: 'Monday 7AM - 8AM',
      topic: 'Continental Breakfast will be provided',
      speaker: ``,
      description: []
    },
    {
      id: '1',
      dateValue: '24',
      dateMonth: 'January',
      timingsText: 'Tuesday 8AM - 9AM',
      topic: 'Opening Session',
      speaker: 'Wasim Malik - Managing Partner, IASO Ventures',
      description: [
        {
          text: 'Format: Keynote and Panel:'
        },
        {
          text: 'Opportunities and Challenges in Creating a Winning Strategy from investor and business viewpoint'
        }
      ]
    },
    {
      id: '2',
      dateValue: '24',
      dateMonth: 'January',
      timingsText: 'Tuesday 9AM - 10:30AM',
      topic: 'Model Informed Drug Development Track',
      speaker:
        'Rajanikanth Madabushi - Associate Director for Guidance and Scientific Policy, OCP, FDA',
      description: [
        {
          text: 'Leveraging modeling for innovative drug development'
        },
        {
          text: 'Speakers:'
        },
        {
          text: '- Hao Zhu, PhD, Division Director, Division of Pharmacometrics, Office of Clinical Pharmacology, FDA'
        },
        {
          text: 'Model Informed Drug Development to Assist New Drug Development and Regulatory Decision Making.'
        },
        {
          text: '- Piet Van Der Graff, Senior VP, Head of Quantitative Systems Pharmacology, Certara'
        },
        {
          text: 'Adding Virtual Patients to Drug Discovery and Development: Model-guided Translational Research and Precision Dosing.'
        },
        {
          text: '- Ravi Shankar Singh- Senior Director of Clinical Pharmacology, at Pfizer'
        },
        {
          text: 'Role of MIDD in Clinical Drug Development'
        }
      ]
    },
    {
      id: '3',
      dateValue: '24',
      dateMonth: 'January',
      timingsText: 'Tuesday 11AM - 12:30PM',
      topic: 'Biomarkers Track',
      speaker: 'Klaus Romero - Chief Science Officer at the Critical Path Institute',
      description: [
        {
          text: 'Chief Science Officer at the Critical Path Institute'
        },
        {
          text: 'Speakers: '
        },
        {
          text: '- William Fitzimmons, PharmD, MS, Founder, Tutela Pharmaceuticals'
        },
        { text: 'From Composite to Multi-component Biomarkers and Endpoints in Transplantation.' },
        {
          text: '- Frank Czerwiec, Chief Medical Officer, Sparrow Pharmaceuticals'
        },
        {
          text: ' Modeling Disease Biomarkers to Predict Efficacy and Safety Outcomes in Drug Development.'
        },

        {
          text: '- Samantha Budd Haeberlein, Senior VP, Head Neurodegeneration Development Unit, Biogen'
        },
        {
          text: 'Role of Biomarkers in Clinical Development: Focus on Neurodegenerative Disorders.'
        }
      ]
    },
    {
      id: 'lunch',
      dateValue: '24',
      dateMonth: 'January',
      timingsText: 'Monday 12:30PM - 2PM',
      topic: 'Lunch buffet will be provided',
      speaker: ``,
      description: []
    },

    {
      id: '5',
      dateValue: '24',
      dateMonth: 'January',
      timingsText: 'Tuesday 2PM - 3:30PM',
      topic: 'Hands on strategy workshop',
      speaker: 'Pravin Jadhav - CEO at Vivpro',
      description: [
        {
          text: 'Use the skills learned from each track to solve case studies with colleagues.'
        }
      ]
    }
  ]
};

export const venueDetails: { [key: string]: any } = {
  title: 'SMC Campus Center',
  address: '621 W. Lombard St. Room 208 A, Baltimore, MD 21201',
  link: 'https://www.umaryland.edu/eventservices/plan-your-event/room-information/elm-rooms-a--b/'
};

export const faqList: { [key: string]: any } = {
  group1: [
    {
      id: '1',
      question: 'What is Vivpro’s RE-CON?',
      answer:
        'Vivpro RE-CON is a platform for in-person events, workshops, and conferences hosted or sponsored by Vivpro in collaboration with key partners.'
    },
    {
      id: '2',
      question: 'How much does it cost to register for RE-CON events?',
      answer:
        'First, you need to "Apply to Attend" the RE-CON events. Upon approval, a payment invoice will be sent to the email address provided. For the January 2023 workshop, the early-bird (Before December 15, 2022) registration cost is $500 and regular registration cost is $750.'
    },
    {
      id: '3',
      question: 'What is included in the regisration cost?',
      answer:
        'The registration cost includes access to workshop tracks, hands-on sessions, breakfast, lunch and social event ticket for all attendees.'
    },
    {
      id: '4',
      question: 'What are the recommended hotels in the area?',
      answer:
        'Baltimore Marriot Inner Harbor at Camden Yards 110 S. Eutaw Street, Baltimore, Maryland 21201 <br></br> Please go ahead and access the following  <a target="_blank" href="https://book.passkey.com/go/VIVPRO"> link</a> to make an online reservation. <br></br>If you prefer to book via telephone, please call 1 (877) 212-5752.  Reference number M-OZTHHBU'
    },
    {
      id: '5',
      question: 'Where do I park? How do I get to the venue?',
      answer:
        'The event will be held at 621 W. Lombard St. Room 208A Baltimore, MD 21201. Please refer to the venue <a target="_blank" href="https://www.umaryland.edu/eventservices/plan-your-event/room-information/elm-rooms-a--b">website</a> for parking and directions.'
    }
  ],
  group2: [
    {
      id: '6',
      question:
        'Can I join a workshop or session virtually or share it with colleagues after - will it be recorded?',
      answer:
        'This is an in-person event. At this time, there is no plan to live stream or record sessions. If the plan changes, we will update it soon.'
    },
    {
      id: '7',
      question: 'How many attendees are expected?',
      answer:
        'Because of the interactive nature of the workshop, which will include a hands-on session, we will only be able to accommodate the first 80 attendees who apply to participate. '
    },
    {
      id: '8',
      question: 'How do I get a copy of the agenda?',
      answer:
        'Please access the agenda <a target="_blank" href="https://vivpro-site.s3.us-east-1.amazonaws.com/Recon/Re-Con2023%20Agenda.pdf">here</a>.'
    },
    {
      id: '9',
      question: 'Where will the social gathering take place?',
      answer:
        'Please join us for Drinks and Dinner at Rusty Scupper Restaurant. <br></br> Located at 402 Key Hwy, Baltimore, MD 21230. Topside Lounge <br></br>Please ensure to RSVP by Monday, January 16th, 2023, via email to holly@vivpro.ai.<br></br>Please specify any dietary requirements.<br></br>Access the invitation <a target="_blank" href="https://vivpro-site.s3.us-east-1.amazonaws.com/Recon/vv_social_invite.png">here</a>.  '
    }
  ]
};
