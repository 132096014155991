export type fileNomenclature = {
    prefix: string;
    module: string;
    postfix: string;
    additional: string;
    // extension: string;
};

export const getFileName = (filedata: fileNomenclature) => {
    if (!filedata.prefix || !filedata.module || !filedata.postfix) {
        throw Error('Invalid Filename format!');
    }
    return `${filedata.prefix}_${filedata.module}_${filedata.postfix}${filedata.additional}`;
};
