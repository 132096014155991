import { useNavigate } from 'react-router-dom';

// mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

// stylesheet
import styles from './Banner.styles';

// assets
import DotConnector from '../../../assets/svg/Recon/DotConnectors.svg';
import DotConnector1 from '../../../assets/svg/Recon/DotConnectors1.svg';
import UniversityLogo from '../../../assets/pngs/Recon/university_logo.png';
// constants
import { bannerDetails, committeeMembers, quotes, speakerMembers } from '../constant';
import MemberCarousel from '../../../Component/Carousel/MemberCarousel';

const Banner = () => {
  const navigate = useNavigate();

  return (
    <Stack>
      <Container maxWidth='desktop'>
        <Stack spacing={5} mt={3}>
          <Stack spacing={3} alignItems='start' justifyContent='top'>
            {/* header text */}
            <Stack direction='row' spacing={1} sx={{ width: '90%' }}>
              <Typography
                textAlign='left'
                variant='h1'
                sx={{ letterSpacing: 1, fontWeight: '700', color: 'white.main' }}>
                {bannerDetails.heading}
              </Typography>
              <Typography
                textAlign='left'
                variant='h1'
                sx={{ letterSpacing: 1, color: 'white.main' }}>
                {bannerDetails.subtitle}
              </Typography>
            </Stack>
            <Stack sx={styles.dateContainer}>
              {/* date and location */}
              <Stack spacing={1}>
                <Typography
                  textAlign='left'
                  variant='h4'
                  sx={{
                    color: 'white.main',
                    fontWeight: '700',
                    textTransform: 'capitalize'
                  }}>
                  {bannerDetails.date}
                </Typography>
                <Typography
                  textAlign='left'
                  variant='subtitle2'
                  sx={{ color: 'white.main', textTransform: 'capitalize' }}>
                  {bannerDetails.location}
                </Typography>
              </Stack>
              {/* book btn */}
              <Button
                onClick={() => navigate('/recon/register')}
                variant='contained'
                color='primary'
                sx={styles.bookBtn}>
                Register Now
              </Button>
            </Stack>
            {/* <Stack sx={{ width: { mobile: '90%', laptop: '90%' } }}>
              <Stack sx={{ width: { mobile: '90%', laptop: '50%' } }}>
                <img src={UniversityLogo} alt='logo' style={{ width: '60%' }} />
              </Stack>
            </Stack> */}
          </Stack>
          <Stack>
            <Typography align='center' sx={{ fontSize: 35, fontWeight: 700, color: 'white.main' }}>
              Speakers
            </Typography>
            <MemberCarousel memberList={[...speakerMembers, ...committeeMembers]} />
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
export default Banner;
