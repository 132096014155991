import React from 'react';
import PropTypes from 'prop-types';

// material ui
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StartEndFilter = ({
  title,
  maxDate,
  minDate,
  startValue = null,
  endValue = null,
  onStartDateChange,
  onEndDateChange
}) => {
  /**
   * function to update start date category
   * @param {string} newValue is the start value that the user clicks inside the calendar
   */
  const handleStartChange = async newValue => {
    if (newValue) {
      onStartDateChange(newValue);
    }
  };

  /**
   * function to update end date category
   * @param {string} newValue is the end value that the user clicks inside the calendar
   */
  const handleEndChange = async newValue => {
    if (newValue) {
      onEndDateChange(newValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Typography variant='subtitle2' fontSize={{tablet: 14, desktop: 16}}>{title}</Typography>
      <Box sx={{ justifyContent: 'space-between' }}>
        <DatePicker
          minDate={new Date(2011, 1, 3)}
          maxDate={endValue ? new Date(endValue) : maxDate}
          views={['year']}
          label='From'
          value={startValue}
          onChange={newValue => handleStartChange(newValue)}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              id='start_date'
              helperText={null}
              sx={{ width: '100px', marginRight: '1vw', marginBottom: '2vh' }}
            />
          )}
          components={{
            OpenPickerIcon: ArrowDropDownIcon
          }}
        />
        <DatePicker
          minDate={startValue ? new Date(startValue) : minDate}
          maxDate={maxDate}
          views={['year']}
          label='To'
          value={endValue}
          onChange={newValue => handleEndChange(newValue)}
          renderInput={params => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...params} id='end_date' helperText={null} sx={{ width: '100px' }} />
          )}
          components={{
            OpenPickerIcon: ArrowDropDownIcon
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

StartEndFilter.propTypes = {
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line
  maxDate: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  minDate: PropTypes.any.isRequired,
  onStartDateChange: PropTypes.func.isRequired,
  onEndDateChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  startValue: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  endValue: PropTypes.any.isRequired
};

export default StartEndFilter;
