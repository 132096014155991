const styles: { [key: string]: any } = {
  dateContainer: {
    width: '90%',
    flexDirection: { mobile: 'column', laptop: 'row' },
    alignItems: {
      laptop: 'center'
    }
  },
  bookBtn: {
    ml: {
      mobile: 0,
      laptop: 4
    },
    mt: {
      mobile: 2
    },
    textTransform: 'capitalize',
    bgcolor: 'green.jungle',
    height: 48,
    '&:disabled': {
      backgroundColor: 'white.dark'
    }
  }
};

export default styles;
