import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

// mui
import Stack from '@mui/material/Stack';

// components
import Banner from './Banner';
import VideoContent from './VideoPage';
import EventSchedule from './EventSchedule';
import VenueDetails from './VenueDetails';
import Committee from './Committee';
import FAQs from './FAQs';
import ReconRegistration from './Registeration';
import Speakers from './Speakers';
import axios from 'axios';
import PaymentDialog, { PaymentPayloadInterface } from './Registeration/PaymentDialog';
import { Alert, Snackbar } from '@mui/material';
import Quotes from './Quotes';
export const BASE_URL = process.env.REACT_APP_API_URL;

const Recon2024 = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [confirmationPayload, setConfirmationPayload] = useState<PaymentPayloadInterface>({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    paymentStatus: '',
    status: ''
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageOpen, setMessageOpen] = useState(false);

  const scheduleRef = useRef(null);
  const committeeRef = useRef(null);
  const speakersRef = useRef(null);
  const venueRef = useRef(null);
  const faqRef = useRef(null);
  const reconFormRef = useRef(null);

  const handleCloseSnackbar = () => {
    setMessageOpen(false);
    setMessage('');
  };

  useEffect(() => {
    const getStripeInfo = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}/email-service/recon-bookings-stripe/${searchParams.get('checkout')}`
        );
        console.log(res);
        if (res.data.Status === 200 && res.data.Success) {
          const firstName =
            res.data.Success?.customer_name?.split(' ')[0] ?? res.data.Success?.customer_name;
          const lastName = res.data.Success?.customer_name?.split(' ')[1] ?? '';
          setConfirmationPayload({
            firstName: firstName,
            lastName: lastName,
            email: res.data.Success?.customer_email,
            company: res.data.Success?.customer_company,
            paymentStatus: res.data?.Success.payment_status,
            status: res.data.Success?.status
          });
        } else {
          setConfirmationPayload({
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            paymentStatus: '',
            status: ''
          });
        }
      } catch (err) {
        setConfirmationPayload({
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          paymentStatus: '',
          status: ''
        });
      }
      setDialogOpen(true);
    };
    if (searchParams.get('checkout')) {
      getStripeInfo();
    }
  }, [searchParams]);

  const scrollToSection = (section: string) => {
    switch (section) {
      case '/recon':
        window.scrollTo(0, 0);
        break;
      case '/recon/agenda':
        if (scheduleRef && scheduleRef.current) {
          // @ts-ignore
          scheduleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
      case '/recon/venue':
        if (venueRef && venueRef.current) {
          // @ts-ignore
          venueRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
      case '/recon/committee':
        if (committeeRef && committeeRef.current) {
          // @ts-ignore
          committeeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
      case '/recon/speakers':
        if (speakersRef && speakersRef.current) {
          // @ts-ignore
          speakersRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
      case '/recon/faq':
        if (faqRef && faqRef.current) {
          // @ts-ignore
          faqRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
      case '/recon/register':
        if (reconFormRef && reconFormRef.current) {
          // @ts-ignore
          reconFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        break;
      default:
        window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const url = window.location.pathname;
    scrollToSection(url);
  }, []);

  useEffect(() => {
    scrollToSection(location.pathname);
  }, [location]);

  return (
    <>
      <Stack>
        <Banner />
        <Stack ref={scheduleRef} />
        <EventSchedule />
        <Quotes />
        {/* <KeySpeakers /> */}
        {/* <SpeakersCarousel /> */}
        <VideoContent />
        {/* <NumericDetails /> */}
        <Stack ref={venueRef} />
        <VenueDetails />
        <Stack ref={committeeRef} />
        <Committee />
        <Stack ref={speakersRef} />
        <Speakers />
        <Stack ref={faqRef} />
        <FAQs />
        <Stack ref={reconFormRef} />
        <ReconRegistration />
      </Stack>
      <PaymentDialog
        firstName={confirmationPayload.firstName}
        lastName={confirmationPayload.lastName}
        email={confirmationPayload.email}
        company={confirmationPayload.company}
        paymentStatus={confirmationPayload.paymentStatus}
        status={confirmationPayload.status}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        setMessage={setMessage}
        setMessageOpen={setMessageOpen}
      />
      <Snackbar
        open={messageOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{ width: { mobile: '100%', laptop: '30%' } }}>
        <Alert onClose={handleCloseSnackbar} severity='success' sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Recon2024;
