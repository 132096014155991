import React, { useCallback, useState } from 'react';
import { getRaceVariationAPIData } from '../../../api/ct-data';
import { RACE_VARIATIONS } from '../const';
import { getColoredGraphData } from '../utils';

const useRaceVariationData = () => {
  const [isLoading, setLoading]: [boolean, Function] = useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState('');
  const [responseData, setResponseData]: [any, Function] = useState({});

  const getRaceVariationData = useCallback(async (cnf: string, dateRange: any = {}) => {
    setResponseData({});
    setLoading(true);
    try {
      setErrorMessage('Could not get the graph data');
      // If the trials data not present throw the error
      let payload: any = {};
      if (cnf.length > 0 || Object.keys(dateRange).length > 0) {
        payload = {
          use_synonyms: true,
          query: cnf,
          filters: dateRange
        };
      }

      // If cnf empty, dont send it in the paload
      if(cnf === "") {
        delete payload.query;
      }
      // If dateRange is empty, dont use it in filters
      if(Object.keys(dateRange).length === 0) {
        delete payload.filters;
      }

      const raceVariationData = await getRaceVariationAPIData(payload);
      const onlyNeededGraphs = raceVariationData.race_variations.filter( (race: any) => RACE_VARIATIONS.includes(race.race_id) );
      const coloredGraphData = getColoredGraphData(onlyNeededGraphs);
      setResponseData({ insights: raceVariationData.insights, graphData: coloredGraphData});
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setErrorMessage('Could not get the graph data');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    isLoading,
    errorMessage,
    responseData,
    getRaceVariationData
  };
};

export default useRaceVariationData;
