import { cloneDeep, sortBy, uniqBy, map } from 'lodash';
import {
  COLORS,
  ELASTIC_SEARCH_ATTRIBUTES_LABELS,
  FILTERS,
  GRAPH_ENTITY_DATA_MAPPING
} from './const';

const getValue = (val: any) => {
  return { value: val, label: val, selected: false };
};
const getFieldValues = (data: { [key: string]: any }, field: string, fieldType: string) => {
  const keys = field;
  if (keys.length > 2) {
    // eslint-disable-next-line no-console
    return [];
  }

  const optionValue = data[keys[0]];
  if (!optionValue) {
    return [];
  }

  let values: any = [];
  if (fieldType === 'Number' || fieldType === 'String') {
    values = [{ value: optionValue, label: optionValue, selected: false }];
  } else if (fieldType === 'Array') {
    values = keys[1] ? map(optionValue, val => getValue(val[keys[1]])) : map(optionValue, getValue);
  }

  return values;
};

export const getFilters = (trialsData: any) => {
  const filters = cloneDeep(
    FILTERS.map((fltr: any) => ({ ...fltr, options: [...(fltr?.options || [])] }))
  );

  // Getting the options from not nested data in the API response
  const notNestedFilters = filters.filter(
    filter =>
      (filter.fieldType === 'String' || filter.fieldType === 'Number') &&
      filter.filterType !== 'range'
  );
  notNestedFilters.forEach(filter => {
    filter.options = uniqBy(
      [...(filter.options || []), ...map(trialsData, val => getValue(val[filter.field[0]]))],
      'value'
    );
  });

  // Getting the options from nested data in the API response.
  const nestedFilters = filters.filter(
    filter => filter.fieldType === 'Array' || filter.fieldType === 'Object'
  );
  trialsData.forEach((trial: any, index: number) => {
    nestedFilters.forEach((filter: { [key: string]: any }) => {
      // eslint-disable-next-line no-param-reassign
      filter.options = uniqBy(
        [...(filter.options || []), ...getFieldValues(trial, filter.field, filter.fieldType)],
        'value'
      );

      if (index === trialsData.length - 1) {
        filter.options = sortBy(filter.options, 'value');
      }
    });
  });

  return filters;
};

export const mergeOldFilterValues = (newFilters: any, oldFilters: any) => {
  newFilters.forEach((newFltr: any) => {
    const oldFltr = oldFilters.find((fltr: any) => fltr.id === newFltr.id);
    if (newFltr.filterType === 'checkbox') {
      // eslint-disable-next-line no-param-reassign
      newFltr.options = newFltr.options.map((newOpt: any) => {
        const selected =
          oldFltr.options.findIndex(
            (oldOpt: any) => oldOpt.value === newOpt.value && oldOpt.selected
          ) > -1;

        return {
          ...newOpt,
          selected
        };
      });
      // In some of the cases where we use synonyms, we might not the options which actually was selected by user.
      // Example: ASD matches "Autism Spectrum Disorder". ASD might not be present in the result only "Autism Spectrum Disorder" could be there.
      // So to show ASD which was selected by user we need to append previously selected option if not present in the new filter options.
      // Example query is: "(active_ingredients:cannabidiol) AND (indications_and_usage:ASD) AND (sponsor_name:Assaf-Harofeh Medical Center OR sponsor_name:Canndoc Ltd OR sponsor_name:Canopy Growth Corporation)"
      // Above query gives only the indications_and_usage "Autism Spectrum Disorder" even though we specified "ASD"
      // To make "ASD" appear in the suggestion (since it was selected by user), we are running below logic
      const prevSelectedOptions = oldFltr.options.filter((oldOpt: any) => oldOpt.selected);
      const prevOptionsMissingInNewOptions = prevSelectedOptions.filter(
        (oldOpt: any) =>
          newFltr.options.findIndex((newOpt: any) => newOpt.value === oldOpt.value) === -1
      );
      // eslint-disable-next-line no-param-reassign
      newFltr.options = [...newFltr.options, ...prevOptionsMissingInNewOptions];
    } else if (newFltr.filterType === 'range') {
      if (oldFltr.value) {
        // eslint-disable-next-line no-param-reassign
        newFltr.value = oldFltr.value;
      }
    }
  });

  return newFilters;
};

export const mapGraphEntityData = (data: { [key: string]: string | number } | null) => {
  const mappedData: { [key: string]: string | number } = {};
  if (data) {
    Object.keys(data).forEach((key: string) => {
      mappedData[GRAPH_ENTITY_DATA_MAPPING[key] || key] = data[key];
    });
  }

  return mappedData;
};

export const getMappedFilters = (filters: any) => {
  const mappedFilters: any = {};
  filters.forEach((filter: any) => {
    if (filter.filterType === 'checkbox') {
      const selectedOptions: Array<string> = filter.options
        .filter((opt: any) => opt.selected)
        .map((opt: any) => opt.value);
      if (selectedOptions.length > 0) {
        mappedFilters[filter.label] = selectedOptions;
      }
    } else if (filter.filterType === 'range') {
      if (filter.value?.start && filter.value?.end) {
        mappedFilters[filter.label] = [
          `${getYearStartDate(filter.value?.start)} to ${getYearEndDate(filter.value?.end)}`
        ];
      }
    }
  });

  return mappedFilters;
};

const getCnfQuery = (rawQuery: string, source: string | null = null) => {
  let result: any = [];
  let normalizedQuery: any = [];
  // normalize inputs to form raw query
  const dict: any = {};
  Object.values(rawQuery).forEach((item: any) => {
    // check if not operator is selected then add a new key for not operator
    if (item.isNot) {
      if (Object.keys(dict).includes('not')) {
        dict.not.push({ category: item.category, searchText: item.searchText });
      } else dict.not = [{ category: item.category, searchText: item.searchText }];
    } else if (Object.keys(dict).includes(item.category)) {
      dict[item.category].push(item.searchText);
    } else dict[item.category] = [item.searchText];
  });

  // eslint-disable-next-line no-restricted-syntax
  for (let [key, value] of Object.entries(dict)) {
    let temp: any = [];
    let normaliedTemp: any = [];
    if (key === 'not') {
      // eslint-disable-next-line no-loop-func
      (value as any).forEach((term: any) => {
        // If formatting required while converting to CNF Query
        const searchText = term.searchText;
        const literal = `~${term.category}:${searchText}`;
        temp.push(literal);

        if (source) {
          const normalizedLiteral = `NOT ${
            ELASTIC_SEARCH_ATTRIBUTES_LABELS[source][term.category]
          }:${term.searchText}`;
          normaliedTemp.push(normalizedLiteral);
        }
      });
    } else {
      // eslint-disable-next-line no-loop-func
      (value as any).forEach((term: any) => {
        const searchText = term;

        const literal = `${key}:${searchText}`;
        temp.push(literal);
        if (source) {
          const normalizedLiteral = `${ELASTIC_SEARCH_ATTRIBUTES_LABELS[source][key]}:${term}`;
          normaliedTemp.push(normalizedLiteral);
        }
      });
    }

    temp = `(${temp.join(' OR ')})`;
    result.push(temp);
    if (source !== null) {
      normaliedTemp = `(${normaliedTemp.join(' OR ')})`;
      normalizedQuery.push(normaliedTemp);
    }
  }

  result = result.join(' AND ');
  if (source) {
    normalizedQuery = normalizedQuery.join(' AND ');
  }

  if (source) {
    return { result, normalizedQuery };
  }

  return result;
};

export const getYearEndDate = (dateInstance: Date) => `${dateInstance.getFullYear()}-12-31`;
export const getYearStartDate = (dateInstance: Date) => `${dateInstance.getFullYear()}-01-01`;

export const getGraphOptions = (graphData: Array<any>) => {
  return graphData.map((data: any) => ({ label: data.name, value: data.race_id } as any));
};

export const getColoredGraphData = (graphData: Array<any>) => {
  return graphData.map((graphData: any, index: number) => {
    const selectedColors = COLORS[index % COLORS.length];
    const color = [];
    for (let index = 0; index < graphData.x.length; index++) {
      color.push(selectedColors[index % 2]);
    }
    return { ...graphData, marker: { color } };
  });
};

export const getRaceWordCloudData = (graphData: { [key: string]: any }) => {
  const wordCloudData: { [key: string]: any } = {
    race_id: graphData.race_id,
    word_frequency: {}
  };
  graphData.x.forEach((label: string, index: number) => {
    wordCloudData.word_frequency[label] = graphData.y[index];
  });

  return wordCloudData;
};

export default getCnfQuery;
