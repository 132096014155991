// mui
import { Box, Button, Container, Divider, Stack, Typography } from '@mui/material';

// stylesheet
import styles from './Banner.styles';

// constants
import { agendaDetails, bannerDetails } from '../constant';
import LocationIcon from '../../../assets/svg/Recon/LocationIcon';
import CalenderIcon from '../../../assets/svg/Recon/calenderIcon';

const Banner = () => {
  return (
    <>
      <Container sx={styles.banner}>
        <Container sx={{ display: 'flex', alignItems: 'center' }} maxWidth='desktop'>
          <Box sx={styles.box}>
            <Typography sx={styles.heading}>{bannerDetails.heading}</Typography>
            <Typography sx={styles.title}>{bannerDetails.title}</Typography>
            <Typography sx={styles.description}>{bannerDetails.description}</Typography>
            <Typography sx={styles.locationText}>
              <LocationIcon sx={{ mr: '12px' }} />
              {bannerDetails.location}
            </Typography>
            <Typography sx={styles.dateText}>
              <CalenderIcon sx={{ mr: '12px' }} />
              {bannerDetails.date}
            </Typography>
          </Box>
        </Container>
      </Container>
      <Box sx={styles.divider} />
      <Container sx={styles.agendaContainer}>
        <Container
          sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
          maxWidth='desktop'>
          <Box sx={styles.agenda}>
            {agendaDetails.map((item: any, index: number) => (
              <>
                <Box sx={styles.agendaBox}>
                  <Typography sx={styles.agendaTitle}>{item.title}</Typography>
                  <Typography sx={styles.agendaSubTitle}>{item.subtitle}</Typography>
                </Box>
                {
                  agendaDetails.length - 1 !== index && (
                    <Divider orientation='vertical' sx={styles.agendaDivider} />
                  )
                }

              </>
            ))}
          </Box>
        </Container>
      </Container>
    </>
  );
};
export default Banner;
