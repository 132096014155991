const styles = {
  actionButton: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: '1px solid transparent',
    p: 0.5,
    '&:hover': { border: '1px solid black', borderColor: 'primary.main' },
    color: 'primary',
    fontSize: { tablet: 14, desktop: 16 }
  }
};

export default styles;
