export const DATA_COLUMNS = [
    {
        field: 'year',
        headerName: 'Year',
        width: 170,
        headerClassName: 'table-header',
        cellClassName: 'ria-MuiDataGrid-cell',

    },
    {
        field: 'white',
        headerName: 'White',
        width: 230,
        headerClassName: 'table-header',
        cellClassName: 'ria-MuiDataGrid-cell',

    },
    {
        field: 'asian',
        headerName: 'Asian',
        width: 230,
        headerClassName: 'table-header',
        cellClassName: 'ria-MuiDataGrid-cell',
    },
    {
        field: 'black',
        headerName: 'Black/African American',
        width: 250,
        headerClassName: 'table-header',
        cellClassName: 'ria-MuiDataGrid-cell',
    },
    {
        field: 'other',
        headerName: 'Unknown',
        width: 230,
        headerClassName: 'table-header',
        cellClassName: 'ria-MuiDataGrid-cell',
    }
];