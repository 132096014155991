import * as React from 'react';
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import Quotes from '../../../assets/svg/Quotes';
// @ts-ignore
import Img from '../../../assets/resources/flight-path-on-transparent-d2.png';

const Blockquote = () => {
  return (
    <>
      <Divider sx={{ width: '30%', m: '2em auto ' }} />
      <Stack
        direction='row'

        sx={{
          fontStyle: 'italic',
          fontSize: '1.1rem',
          color: 'grey.800'
        }}>
        <Box mr={1} mt={-2} sx={{
          width: 90
        }}>
          <Quotes />
        </Box>
        <Stack direction={'row'} mt={3}>
          <Avatar
            sx={{
              width: 100,
              height: 100,
              mr: 2
            }}
            src={'https://vivpro.ai/static/media/Ac5.49b14e75.png'}
          />
          <Typography variant='subtitle1' color='primary.main'>
            At its core, Vivpro's purpose is to "democratize" and "simplify" knowledge by making it easier to gather,
            organize, and analyze complicated R&D data. Vivpro varsity with virtual and in-person courses and free
            resources is our way of going together on this journey.
            <br />
            <br />
            - Pravin Jadhav
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ width: '30%', m: '2em auto ' }} />

    </>

  );
};

export const Joga = () => {
  return (
    <Box sx={{position:'relative',mb:{mobile:0,laptop:10}}}>
      <Divider sx={{ width: '30%', m: '2em auto ' }} />
      <Stack
        direction='row'

        sx={{
          fontStyle: 'italic',
          fontSize: '1.1rem',
          color: 'grey.800'
        }}>
        <Box mr={1} mt={-2} sx={{
          width: 90
        }}>
          <Quotes />
        </Box>
        <Stack direction={'row'} mt={3}>
          <Avatar
            sx={{
              width: 100,
              height: 100,
              mr: 2
            }}
            src={'https://vivpro.ai/static/media/Bm1.ab216ff2.png'}
          />
          <Typography variant='subtitle1' color='primary.main'>
            R&D strategists are rare to find. This is mainly because of lack of training opportunities. Vivpro varsity
            aims to change the status quo with events, virtual courses and free resources. Now you can propel your R&D
            career to increase your sphere of influence in your company, take more responsibilities and affect more
            patients by accelerating drug development.
            <br />
            <br />
            - Joga Gobburu
          </Typography>
        </Stack>
        <Box sx={{
          position:'absolute',
          width: "100%",
          mt:18,
          overflow: 'hidden',
          display:{mobile:'none', laptop:'block'}
        }}>
          <img width='100%' src={Img} alt='bird' />
        </Box>
      </Stack>
      <Divider sx={{ width: '30%', m: '2em auto ' }} />

    </Box>
  );
};
export default Blockquote;
