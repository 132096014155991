const Quotes = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 57 57"
      xmlSpace="preserve"
      style={{
        fill:'#2ecc71'
      }}
    >
      <circle
        style={{
          fill: 'inherit',
        }}
        cx={18.5}
        cy={31.5}
        r={5.5}
      />
      <path
        style={{
          fill: 'inherit',
        }}
        d="M18.5 38c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5z"
      />
      <circle
        style={{
          fill: 'inherit',
        }}
        cx={35.5}
        cy={31.5}
        r={5.5}
      />
      <path
        style={{
          fill: 'inherit',
        }}
        d="M35.5 38c-3.584 0-6.5-2.916-6.5-6.5s2.916-6.5 6.5-6.5 6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5zm0-11c-2.481 0-4.5 2.019-4.5 4.5s2.019 4.5 4.5 4.5 4.5-2.019 4.5-4.5-2.019-4.5-4.5-4.5zM13 32a1 1 0 0 1-1-1c0-7.72 6.28-14 14-14a1 1 0 1 1 0 2c-6.617 0-12 5.383-12 12a1 1 0 0 1-1 1z"
      />
      <path
        style={{
          fill: 'inherit',
        }}
        d="M30 32a1 1 0 0 1-1-1c0-7.72 6.28-14 14-14a1 1 0 1 1 0 2c-6.617 0-12 5.383-12 12a1 1 0 0 1-1 1z"
      />
    </svg>

  );
};
export default Quotes;
