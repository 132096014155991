
const styles: { [key: string]: any } = {
    backgroundContainer: {
        background: 'white.main',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '302px',
        display: 'flex',
        justifyContent: 'center',
        '@media (max-width: 768px)': {
            mt: '75px',
        }
    },
    container: {
        mt:'100px',
        mb:'100px',
        '@media (max-width: 768px)': {
            mt:'80px',
            mb:'80px'
          }
    },
    heading: {
        fontSize: '36px',
        fontWeight: 700,
        fontFamily: "Plus Jakarta Sans",
        textAlign: 'center',
        '@media (max-width: 768px)': {
            fontSize: '36px',
          }
    },
    memberContainer: {
        background: 'white',
        display: 'flex',
        maxWidth: '360px',
        maxHeight: '404px',
        minHeight: '355px',
        padding: '40px 36px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px'
    },
    name: {
        overflow: 'hidden',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '28px', /* 175% */
        letterSpacing: '0.16px'
    },
    title: {
        overflow: 'hidden',
        maxWidth: '280px',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 4, // Limits the text to 2 lines
        lineHeight: '21px',
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '0.14px',
        whiteSpace: 'normal', 
    },
    companyName: {
        mt: '4px',
        overflow: 'hidden',
        color: '#0076D6',
        textAlign: 'center',
        textOverflow: 'ellipsis',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '21px', /* 175% */
        letterSpacing: '0.14px'
    },
    bioName: {
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '140%', /* 56px */
        letterSpacing: '-1px',
        mt: '24px'
    },
    bioQualifications: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '140%'
    },
    bioTitle: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '140%',
        mt: '24px'
    },
    bioCompany: {
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '140%',
        color: '#0076D6',
        mb:'36px'
    },
    bioInfo: {
        mt: '12px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px'
    },
    divider: {
        border: '1px solid #212121', 
        width: '100%'
    },
    boxContainer: {
        ml: '40px',
        '@media (max-width: 768px)': {
            ml: '0px',
          }
    }
};

export default styles;