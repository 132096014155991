import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import useCTData from './hooks/useCTData';

import Filters from './components/FiltersV2';
import InfoSection from './components/InfoSection';
import ReportModal from './components/Report';
import FilterText from './components/GraphTitle/FilterText';
import Enrollment from './components/Graph/Enrollment';
import Variations from './components/Graph/Variations';
import Loading from './components/Loading';
import MobilePopUp from './components/MobilePopUp';
import { Button, Stack } from '@mui/material';
import MethodologyDialog from './components/Methodology/Dialog';
import ScrollToTop from '../../hooks/ScrollToTop';

import getCnfQuery, {
  getMappedFilters,
  getYearEndDate,
  getYearStartDate,
  mergeOldFilterValues
} from './utils';
import { FILTERS } from './const';
import useRaceVariationData from './hooks/useRaceVariationData';
import { getGraphOptions } from './utils';
import FeedbackDialog from './components/FeedbackDialog/FeedbackDialog';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import useTrialsCount from './hooks/useTrialsCount';

import Alert from '@mui/material/Alert';

const CTDataPage: React.FC = () => {
  ScrollToTop();

  const [isDownloadDialogOpen, setDownloadDialog] = useState<boolean>(false);
  const [isFeedbackDialogOpen, setFeedbackDialogOpen] = useState<boolean>(false);
  const [isMethodologyOpen, setMethodology] = useState<boolean>(false);
  const [apiFilters, setFilters]: any = useState();
  const [selectedGraphIndex, setGraphIndex]: any = useState(0);
  const [reportData, setReportData] = useState<{
    [key: string]: Array<object>;
  }>({});
  const [filtersText, setFilterText] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Flag to control full screen loading
  const [pageDataLoading, setPageDataLoad] = useState(true);
  const [donePageDataLoadingAnimation, setDonePageDataLoadingAnimation] = useState(false);

  const { count: totalTrials, getTrialsCount } = useTrialsCount();
  const { isLoading: isEnrollmentLoading, responseData: enrollmentData, getCTData } = useCTData();
  const {
    isLoading: isVariationLoading,
    responseData: variationData,
    getRaceVariationData
  } = useRaceVariationData();

  useEffect(() => {
    getTrialsCount();
    const query: any = [];
    const { result: cnfOfSelectedQuery, normalizedQuery }: any = getCnfQuery(query, 'cdp' as any);
    setFilterText(normalizedQuery || '');
    getCTData(cnfOfSelectedQuery);
    getRaceVariationData(cnfOfSelectedQuery);
  }, []);

  useEffect(() => {
    if (enrollmentData.filters) {
      let filters = enrollmentData.filters;
      if (apiFilters) {
        filters = mergeOldFilterValues(filters, apiFilters);
      }
      setFilters(filters);
    }
  }, [enrollmentData.filters, apiFilters]);

  useEffect(() => {
    if (enrollmentData.graphData && variationData.graphData) {
      setPageDataLoad(false);
    }
  }, [enrollmentData.graphData, variationData.graphData]);

  useEffect(()=> {
    if(pageDataLoading) {
      setDonePageDataLoadingAnimation(false);
    }
  },[pageDataLoading])

  const handleAddToReport = useCallback(
    async (data: any) => {
      const prevData = reportData[filtersText] || [];
      prevData.push(data);
      const newReportData = { ...reportData, [filtersText]: prevData };
      setReportData(newReportData);
    },
    [reportData, filtersText]
  );

  const handleDownloadClick = useCallback(() => {
    setDownloadDialog(true);
  }, []);

  const handleResetReportData = useCallback(() => {
    setDownloadDialog(false);
    setReportData({});
  }, []);

  const handleCloseModal = useCallback(() => {
    setDownloadDialog(false);
  }, []);

  const handleSnackbarOpen = useCallback(() => {
    setSnackbarOpen(true);
  }, []);

  const handleSnackbarClose = useCallback(() => {
    setSnackbarOpen(false);
  }, []);
  const initialFilter: any = useMemo(() => FILTERS.map(fltr => ({ ...fltr, options: [] })), []);

  const mappedFilters: any = useMemo(
    () => getMappedFilters(apiFilters || initialFilter),
    [initialFilter, apiFilters]
  );

  const handleFilterApply = useCallback(
    (filters: any) => {
      let query: any = [];
      const dateRange: any = {};
      filters.forEach((filter: any) => {
        if (filter.filterType === 'range') {
          if (filter.value?.start && filter.value?.end && !dateRange[filter.id]) {
            dateRange[filter.id] = {};
          }

          if (filter.value?.start) {
            dateRange[filter.id].start = getYearStartDate(filter.value?.start as Date);
          }
          if (filter.value?.end) {
            dateRange[filter.id].end = getYearEndDate(filter.value?.end as Date);
          }
        } else {
          const selectedOptions = filter.options.filter((opt: any) => opt.selected);
          query = [
            ...query,
            ...selectedOptions.map((opt: any) => ({ category: filter.id, searchText: opt.value }))
          ];
        }
      });
      setPageDataLoad(true);
      const { result: cnfOfSelectedQuery, normalizedQuery }: any = getCnfQuery(query, 'cdp' as any);
      setFilterText(normalizedQuery);
      getCTData(cnfOfSelectedQuery, dateRange);
      getRaceVariationData(cnfOfSelectedQuery, dateRange);
      setFilters(filters);
    },
    [getCTData, getRaceVariationData]
  );

  const handleClearFilters = useCallback(() => {
    setPageDataLoad(true);
    const query: any = [];
    const { result: cnfOfSelectedQuery }: any = getCnfQuery(query, 'cdp' as any);
    getCTData(cnfOfSelectedQuery);
    getRaceVariationData(cnfOfSelectedQuery);
    setFilters(initialFilter);
  }, [getCTData, initialFilter, getRaceVariationData]);

  const handleFeedbackOpen = useCallback(() => {
    setFeedbackDialogOpen(true);
  }, []);

  const handleFeedbackClose = useCallback(() => {
    setFeedbackDialogOpen(false);
  }, []);

  const graphOptions = useMemo(() => {
    return getGraphOptions(variationData?.graphData || []);
  }, [variationData?.graphData]);

  const handleGraphOptionChange = useCallback(
    (selectedValue: string) => {
      setGraphIndex(graphOptions.findIndex((opt: any) => opt.value === selectedValue));
    },
    [graphOptions]
  );

  const handleDoneAnimation = useCallback(() => {
    setDonePageDataLoadingAnimation(true);
  }, []);

  return (
    <>
      <MobilePopUp />
      <InfoSection
        title='Democratizing data to drive equity in clinical trial enrollment'
        text1={`This FREE Vivpro resource allows you to download a personalized report to assess the state of enrollment by race and ethnicity across
        ${totalTrials} clinical trials that started in 2011 or later and for which results are available on clinicaltrials.gov.`}
        text2='The Vivpro team is delighted to give you more power data so you can focus on strategy!'
      />
      <Box
        width='100%'
        height='100%'
        display='flex'
        overflow='hidden'
        sx={{ backgroundColor: '#eee' }}>
        <Box
          width={{ laptop: 250, tablet: 250, desktop: 310 }}
          display={{ mobile: 'none', tablet: 'block' }}
          overflow='auto'
          height='100%'
          sx={{ backgroundColor: 'gray.backgroundDark' }}
          boxShadow='rgba(0, 0, 0, 0.1) 0px 5px 10px'>
          <Filters
            filters={apiFilters || initialFilter}
            onFilterApply={handleFilterApply}
            onClearFilters={handleClearFilters}
            loading={isEnrollmentLoading}
          />
        </Box>
        <Box
          width={{ tablet: 'calc(100% - 260px)', desktop: 'calc(100% - 320px)' }}
          height='100%'
          pt={2}>
          {Object.keys(mappedFilters).length > 0 && <FilterText selectedFilters={mappedFilters} />}
          <Box width='100%' pt={2} height='100%' pl={1}>
            {!donePageDataLoadingAnimation ? (
              <Box height='75vh' position='relative'>
                <Loading
                  onDoneAnimation={handleDoneAnimation}
                  loading={pageDataLoading}
                />
              </Box>
            ) : (
              <>
                { donePageDataLoadingAnimation && variationData?.graphData?.[selectedGraphIndex] && (
                  <Variations
                    graphData={variationData?.graphData?.[selectedGraphIndex]}
                    selectedGraph={graphOptions?.[selectedGraphIndex]?.value}
                    graphOptions={graphOptions}
                    title={variationData.insights}
                    onGraphOptionChange={handleGraphOptionChange}
                    onAddToReport={() =>
                      handleAddToReport({
                        type: 'variation',
                        title: variationData.insights,
                        data: variationData.graphData[selectedGraphIndex],
                        filter: mappedFilters
                      })
                    }
                  />
                )}{' '}
                {!isEnrollmentLoading && (
                  <Enrollment
                    title={enrollmentData.insights}
                    onAddToReport={() =>
                      handleAddToReport({
                        type: 'enrollment',
                        title: enrollmentData.insights,
                        data: enrollmentData.graphData,
                        filter: mappedFilters
                      })
                    }
                    data={enrollmentData.graphData}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ backgroundColor: '#eee' }}
        display='flex'
        justifyContent='center'
        alignItems='center'
        p={2}>
        <Box color='gray.main' fontSize={12}>
          The Vivpro team is delighted to give you more power data so you can focus on strategy!
        </Box>
        <Tooltip title='How Vivpro generates meaningful insights?' placement='right'>
          <Box display='flex' ml={1} onClick={() => setMethodology(true)}>
            <InfoOutlinedIcon sx={{ color: 'primary.main', cursor: 'pointer' }} />
          </Box>
        </Tooltip>
      </Box>
      <MethodologyDialog open={isMethodologyOpen} onClose={() => setMethodology(false)} />
      {Object.keys(reportData).length > 0 && (
        <IconButton
          sx={{
            position: 'fixed',
            right: 10,
            bottom: 0,
            color: 'primary.main',
            animation: 'pulse 1.5s infinite'
          }}
          onClick={handleDownloadClick}>
          <Badge variant='dot' color='primary'>
            <img src='/assets/download.svg' alt='Download' width='48px' />
          </Badge>
        </IconButton>
      )}
      <ReportModal
        open={isDownloadDialogOpen}
        data={reportData}
        onClose={handleCloseModal}
        onReset={handleResetReportData}
      />
      <Stack
        display='flex'
        flexDirection='column'
        alignItems='center'
        sx={{ backgroundColor: '#eee' }}>
        <Button variant={'contained'} sx={{ mb: 2 }} onClick={handleFeedbackOpen}>
          Send Feedback
        </Button>
      </Stack>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleSnackbarClose} severity='success'>
          <Box>Thank you for taking time to provide us a feedback</Box>
        </Alert>
      </Snackbar>
      {isFeedbackDialogOpen && (
        <FeedbackDialog
          open={isFeedbackDialogOpen}
          onClose={handleFeedbackClose}
          sendToast={handleSnackbarOpen}
        />
      )}
    </>
  );
};

export default React.memo(CTDataPage);
