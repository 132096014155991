// mui
import { Box, Container, Typography } from '@mui/material';

// stylesheet
import styles from './About.styles';

// constants
import { cloudfrontRecon2025Url, videoDetails } from '../constant';

const About = () => {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center'>
      <Container sx={styles.AboutContainer} maxWidth='desktop'>
        <Box display='flex' flexDirection='row' width='100%'>
          <Box
            component='img'
            sx={styles.aboutImage}
            alt='About 1'
            src={`${cloudfrontRecon2025Url}/About/about1-min.jpg`}
          />
          <Box
            component='img'
            sx={styles.aboutImage}
            alt='About 2'
            src={`${cloudfrontRecon2025Url}/About/about-3.png`}
          />
        </Box>
        <Box display='flex' flexDirection='column'>
          <Typography sx={styles.title}>
            Recon<span style={{ color: '#0076D6', marginLeft: '2px' }}>2025</span>
          </Typography>
          <Typography sx={styles.subtitle}>
            “Setting R&D Strategy" conference (RECON) is where collaboration meets innovation. This
            event is designed to unite professionals from across the pharmaceutical industry,
            academia, and regulatory bodies to discuss and develop strategic approaches to R&D.
            <br />
            <br />
            The conference offers a unique opportunity to engage with leading experts, share
            knowledge, and build connections that drive progress. Participants will gain insights
            into the latest methodologies, regulatory considerations, and technological advancements
            shaping the future of pharmaceutical research and development
          </Typography>
        </Box>
      </Container>
      <Container sx={styles.aboutContainer} maxWidth='desktop'>
        <Box display='flex' flexDirection='column'>
          <Typography sx={styles.title}>Why Attend Recon?</Typography>
          <ul style={styles.ulStyle}>
            <li>
              <Typography sx={styles.bullets}>
                <span style={styles.li}>Learn</span> innovative drug development approaches to build
                winning strategy, using internal and external intelligence
              </Typography>
            </li>
            <li>
              <Typography sx={styles.bullets}>
                <span style={styles.li}>Inspire</span> by showcasing case studies
              </Typography>
            </li>
            <li>
              <Typography sx={styles.bullets}>
                <span style={styles.li}>Grow</span> by interacting with peers
              </Typography>
            </li>
            <li>
              <Typography sx={styles.bullets}>
                <span style={styles.li}>Master</span> strategy via hands-on workshop
              </Typography>
            </li>
          </ul>
        </Box>
        <Box sx={styles.videoBox}>
          <video
            controls={true}
            poster={videoDetails.videoThumbnail}
            style={{
              width: '100%',
              height: '100%'
            }}
            src={videoDetails.videoLink}
          />
        </Box>
      </Container>
    </Box>
  );
};
export default About;
