import {
  Box, Button, Card, CardActionArea, CardContent, CardMedia, Stack, Typography, useMediaQuery, useTheme
} from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { ResourcesStyles } from './styles/ResourcesStyles';
import { researchPage } from '../constants';
import { NavLink as RouterLink } from 'react-router-dom';

const Resources = () => {
  const theme = useTheme();
  const myRef = useRef<HTMLDivElement>(null);
  const [hideLeftScroll, setHideLeftScroll] = useState(true);
  const [hideRightScroll, setHideRightScroll] = useState(false);
  const [disableAutoScroll, setDisableAutoScroll] = useState(false);

  // @ts-ignore
  const desktopMatch = useMediaQuery(theme.breakpoints.only('desktop'));
  // @ts-ignore
  const tabletMatch = useMediaQuery(theme.breakpoints.only('tablet'));
  // @ts-ignore
  const laptopMatch = useMediaQuery(theme.breakpoints.only('tablet'));

  const getScrollPosition = useCallback((scrollWidth: any, childCount: any) => {
    let val = (scrollWidth / childCount);
    if (desktopMatch) {
      return val * 3;
    }
    if (tabletMatch || laptopMatch) {
      return val * 2;
    }

    return val;

  }, [desktopMatch, tabletMatch, laptopMatch]);

  const scrollToRef = useCallback((ref: any, direction: number, autoScrollEnabled: boolean) => {
    if (ref && ref.current) {
      const childCount = ref.current.childElementCount;
      const scrollWidth = ref.current.scrollWidth;
      const offsetWidth = ref.current.offsetWidth;
      const scrollPosition = getScrollPosition(scrollWidth, childCount);
      const currentScrollPosition = ref.current.scrollLeft;
      const maxScroll = scrollWidth - offsetWidth;
      if (direction === 1) {
        ref.current.scrollLeft = (currentScrollPosition - scrollPosition);
      } else {
        let newScroll = currentScrollPosition + scrollPosition;
        if (autoScrollEnabled) {
          if (!disableAutoScroll) {
            if (currentScrollPosition >= maxScroll && currentScrollPosition !== 0) {
              ref.current.scrollLeft = 0;
            } else {
              ref.current.scrollLeft = newScroll;
            }
          }

        } else {
          ref.current.scrollLeft = newScroll;
        }

      }
    }

  }, [disableAutoScroll, getScrollPosition]);
  const executeScrollLeft = useCallback(() => scrollToRef(myRef, 1, false), [scrollToRef]);
  const executeScrollRight = useCallback(() => scrollToRef(myRef, 0, false), [scrollToRef]);
  const scrollHandler = useCallback((e: any) => {
    const currentPosition = e.target.scrollLeft;
    const maxScroll = e.target.scrollWidth - e.target.offsetWidth;
    if (currentPosition === 0) {
      setHideLeftScroll(true);
    }
    if (currentPosition > 0) {
      setHideLeftScroll(false);
    }
    if (currentPosition === maxScroll) {
      setHideRightScroll(true);
    }
    if (currentPosition < maxScroll) {
      setHideRightScroll(false);
    }
  }, []);
  useEffect(() => {
    if (myRef && myRef.current) {
      const scrollWidth = myRef.current.scrollWidth;
      const offsetWidth = myRef.current.offsetWidth;
      if (offsetWidth === scrollWidth) {
        setHideRightScroll(true);
      } else {
        setHideRightScroll(false);
      }
    }
  }, []);

  return (<Stack display='flex' justifyContent='space-between' mt={10} flexDirection='column'
                 sx={{ mb: 10 }}>
      <Typography variant='subtitle1' color='primary.main' sx={{ textTransform: 'uppercase', mb: 5 }}>
        Resources
      </Typography>
      <Stack display='flex' flexDirection='row' justifyContent='space-between'>
        <Typography variant='h2' color={'black.main'} width={400}>
          An extensive online hub for R&D strategists.
        </Typography>
        <Stack display='flex' flexDirection='row' alignItems='center'>
          <Button aria-label='previous' variant={hideLeftScroll ? 'outlined' : 'contained'} sx={ResourcesStyles.button}
                  disabled={hideLeftScroll}
                  onClick={executeScrollLeft}>
            <ArrowBackIos />
          </Button>
          <Button aria-label='next' variant={hideRightScroll ? 'outlined' : 'contained'} sx={ResourcesStyles.button}
                  disabled={hideRightScroll}
                  onClick={executeScrollRight}>
            <ArrowForwardIos />
          </Button>
        </Stack>
      </Stack>
      <Box display='flex'
           position='relative'
           onScroll={scrollHandler}
           onMouseEnter={() => {
             setDisableAutoScroll(true);
           }}
           onMouseLeave={() => {
             setDisableAutoScroll(false);
           }}
           ref={myRef}
           sx={ResourcesStyles.container}>

        {researchPage.map(({ image, title, title2 = '', description, link }: any, index) => (
          <Card sx={ResourcesStyles.cards} key={index} elevation={0}
                component={RouterLink}
                to={link}>
            <CardActionArea sx={{ height: '100%' }}>
              <CardMedia
                component='img'
                height='250'
                image={image}
                alt={title}
                sx={{
                  filter: 'grayscale(1) !important',
                }}
              />
              <CardContent
                sx={{ height: '55%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box>
                  <Typography variant='h4' color='black.main'>
                    {description}
                  </Typography>
                </Box>
                <Box>
                  <Typography gutterBottom variant='subtitle1' mt={5}
                              color='black.lightVariant'>
                    {title} <br />
                    {title2}
                  </Typography>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>))}
      </Box>
    </Stack>);
};
export default Resources;
