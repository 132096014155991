import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import React from 'react';
import { useCallback } from 'react';
import domToPdf from 'dom-to-pdf';
import FilterText from '../GraphTitle/FilterText';
import Enrollment from '../Graph/Enrollment';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';

import Logo from '../../../../assets/svg/Logo';
import Variations from '../Graph/Variations';
import Methodology from '../Methodology';
import VarsityLogo from '../../../../assets/svg/VarsityLogo';

const getDateFileName = (date: Date) => {
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day: any = date.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  return `${year}_${month}_${day}`;
};

const Report = React.memo(
  ({ data = {}, onReset }: { data: { [filterText: string]: Array<any> }; onReset: () => void }) => {
    const donwloadPDF = useCallback(async () => {
      const now = new Date();
      const element: any = document.getElementById('printable-report');

      const options = {
        filename: `race_ethn_enrollment_${getDateFileName(now)}`
      };
      return domToPdf(element, options, async () => {
        onReset();
      });
    }, [onReset]);
    console.log(data);
    return (
      <Box
        width='100%'
        height='100%'
        sx={{ backgroundColor: 'white.main' }}
        p={5}
        pt={0}
        overflow='auto'>
        <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant='contained' sx={{ color: 'white.main', m: 2 }} onClick={donwloadPDF}>
            <Typography variant='body1' mr={1} color='white.main'>
              Download
            </Typography>
            <LocalPrintshopOutlinedIcon fontSize='small' />
          </Button>
          <Button variant='contained' onClick={onReset} sx={{ color: 'white.main', m: 2 }}>
            <Typography variant='body1' mr={1} color='white.main'>
              Reset
            </Typography>
            <CancelOutlinedIcon fontSize='small' />
          </Button>
        </Toolbar>
        <Box border='1px solid black'>
          <Box
            id='printable-report'
            display='flex'
            justifyContent='center'
            flexDirection='column'
            paddingTop={5}
            padding={1}
            sx={{ backgroundColor: 'white.main' }}>
            <Box pl={5} pb={3}>
              <Box sx={{ width: '20%', minWidth: 140, display: 'flex', p:2, pl: 0 }}>
                <VarsityLogo />
              </Box>
              <Box display='flex' alignItems='center' margin='auto' justifyContent='center' pb={2}>
                <Typography variant='h2' textAlign='center'>
                  A Report on Clinical Trials Enrollment by Race/Ethnicity Classification
                </Typography>
              </Box>
              <Typography variant='body1'>
                <b>Source </b>: clinicaltrials.gov
              </Typography>
              <Typography variant='body1'>
                <b>Prepared by </b>: VivProAI
              </Typography>
            </Box>
            {Object.keys(data).map((key: string) => (
              <>
                {Object.keys(data[key]?.[0]?.filter).length > 0 && (
                  <FilterText selectedFilters={data[key][0].filter} key={key} />
                )}
                <Box height='100%' pt={2}>
                  {data[key].map((componentData: any, index: number) => (
                    <Box key={`${key}_${index}`} pt={2}>
                      {componentData.type === 'enrollment' ? (
                        <Enrollment data={componentData.data} title={componentData.title} />
                      ) : (
                        <Variations graphData={componentData.data} title={componentData.title} />
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            ))}
            <Box p={5}>
              <Methodology />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

const ReportModal = ({ open, data, onClose, onReset }: any) => {
  return (
    <Modal open={open} sx={{ margin: 1 }}>
      <>
        <Box display='flex' justifyContent='flex-end' sx={{ backgroundColor: 'white.main' }}>
          <IconButton onClick={onClose}>
            <CancelOutlinedIcon color='primary' fontSize='small' />
          </IconButton>
        </Box>
        <Report data={data} onReset={onReset} />
      </>
    </Modal>
  );
};

export default React.memo(ReportModal);
