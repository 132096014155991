import * as React from 'react';
import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { certificationStyles } from './styles/CertificationStyles';

const Certifications = () => {
  const certifications = [
    {
      name: 'R&D Broker',
      description: 'This certification level will train you on tools and process of becoming a creator of innovative product development ideas for the organization.',
      link: 'https://www.credential.net/8b8c3c3a-8f9b-4b8f-8c8c-8c8c8c8c8c8c'
    },
    {
      name: 'R&D connector',
      description: 'This certification level will train you on tools and empower with skills needed to be with a gift of influencing and communication',
      link: 'https://www.credential.net/8b8c3c3a-8f9b-4b8f-8c8c-8c8c8c8c8c8c'
    },
    {
      name: 'R&D Gatekeeper',
      description: 'This certification level will train you and empower you to be a well connected innovator',
      link: 'https://www.credential.net/8b8c3c3a-8f9b-4b8f-8c8c-8c8c8c8c8c8c'
    },
    {
      name: 'R&D Executive',
      description: 'This certification level will train you to be an effective R&D leader with a well rounded experience with tools, process and network to build a winning strategy faster with the ',
      link: 'https://www.credential.net/8b8c3c3a-8f9b-4b8f-8c8c-8c8c8c8c8c8c'
    }
  ];
  return (<Stack display='flex' justifyContent='space-between' mt={10} direction='column'>
      <Typography variant='subtitle1' sx={{ textTransform: 'uppercase' }} color='primary.main'>
        Certifications
      </Typography>
      <Typography variant='h3' mt={5} mb={3}>
        Create Your Own R&D Position
      </Typography>
      <Typography variant='body1' mb={3}>
        The Vivpro varsity certification programs are designed to enable innovation at the intersection of disciplines
        and empower you with tools, knowledge and network to drive outcomes. <Typography component={'span'} color='black.light'
      >According
        to a <Button variant='text'
                     sx={{textTransform:'none'}}
                     onClick={() => window.open('https://www.harvardmagazine.com/2010/05/innovation-at-the-intersection', '_blank')}>famous
          study by Lee Fleming</Button>, Weatherhead professor of business administration at Harvard Business
        School, there are four necessary roles to realize the value of knowledge creation.</Typography>
      </Typography>
      <Stack sx={{ flexDirection: { mobile: 'column', laptop: 'row' } }} justifyContent='space-between'
             alignItems='flex-start' flexWrap='wrap'>
        {
          certifications.map(({ name, description, link }, index) => (
            <Tooltip key={index} title={'Coming soon'}>
              <Stack display='flex' direction='column' justifyContent='space-between' p={5}
                     sx={certificationStyles.mainButton} mb={{ mobile: 5, laptop: 10 }}
                     mt={{ mobile: 2, laptop: index % 2 === 0 ? 0 : 5 }}
              >
                <Typography variant='h2' color='black.main'>
                  {name}
                </Typography>
                <Typography variant='body1' color='black.light' maxWidth={{ mobile: '80%', laptop: '60%' }}>
                  {description}
                </Typography>
                <Box>
                  <Button variant='text' sx={{
                    ml: -1,
                    textAlign: 'left',
                    textDecoration: 'underline',
                    color: 'black.main',
                    textTransform: 'none'
                  }}>
                    Learn more
                  </Button>
                </Box>

              </Stack>

            </Tooltip>

          ))
        }

      </Stack>
    </Stack>

  );
};
export default Certifications;
